import React, { lazy, useState } from "react";
import {
  Grid,
  Stack,
  Container,
  Center,
  Box,
  Flex,
  Overlay,
  Tabs,
  Button,
  Text,
} from "@mantine/core";
import PendulumControls from "./components/pendulumControl";
import Options from "./components/pendulumOptions";
import Timer from "./components/pendulumTimer";
import CenterPendulum from "./components/centerPendulum";
import { useMediaQuery } from "@mantine/hooks";
import Toolbar from "./components/footer";
import { getLanguageEnumByKeyForPendulumModel } from "../../assets/LanguageEnums/SimulationEnumFunction";
import { useNavigate } from "react-router-dom";

const EnergyGraph=lazy(()=> import("./components/pendulumGraph"));

const maincloseImg =
  "https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-07-19T08-08-49-765Z.png";
const protectorImg="https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-11-18T13-02-52-455Z.png"
const closeImg="https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-11-18T13-31-44-559Z.png"
export default function PendulumSimulation() {
  const isMediumScreen = useMediaQuery("(max-width: 1072px)");
  const [armLength1, setArmLength1] = useState<number>(400);
  const [armLength2, setArmLength2] = useState<number>(400);
  const [ballRadius1, setBallRadius1] = useState<number>(40);
  const [ballRadius2, setBallRadius2] = useState<number>(40);
  const [showFBD, setShowFBD] = useState<boolean>(false);
  const [damping, setDamping] = useState<number>(0); // Damping
  const [showValues, setShowValues] = useState<boolean>(false);
  const [angle1, setAngle1] = useState<number>(0);
  const [angle2, setAngle2] = useState<number>(0);
  const [maxAngle1, setMaxAngle1] = useState<number>(0);
  const [maxAngle2, setMaxAngle2] = useState<number>(0);
  const [KineticEnergy1, setKineticEnergy1] = useState<number>(0);
  const [KineticEnergy2, setKineticEnergy2] = useState<number>(0);
  const [potentialEnergy1, setPotentialEnergy1] = useState<number>(0);
  const [potentialEnergy2, setPotentialEnergy2] = useState<number>(0);
  const [reset, setReset] = useState<boolean>(false);
  const [isPlay, setIsPlay] = useState<boolean>(false);
  const [frameRate, setFrameRate] = useState<0.25 | 0.5 | 1>(1);
  const [showSecondBall, setShowSecondBall] = useState<boolean>(false);
  const [showEnergySecondBall, setEnergySecondBall] = useState<boolean>(false);
  const [option, setOption] = useState<string | null>(null);

  const [userLanguage, setUserLanguage] = useState<string>("en");
  const isSmallScreen = useMediaQuery("(max-width: 1075px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 768px)");
  const [showUserPrefrence, setShowUserPrefrence] = useState<boolean>(false);
  const handleclosebutton = () => {
    setOption(null);
  };

  const navigate = useNavigate();
  return (
    <Container
      fluid
      style={{ height: "100vh", width: "100vw", overflow: "hidden" }}
    >
      <Grid
        gutter="md"
        align="center"
        justify="space-between"
        style={{ height: "100%", overflow: "hidden" }}
      >
        {/* Left side with EnergyGraph and Timer */}
        <Grid.Col span={3}>
          <Stack align="center" spacing="md">
            {!isSmallScreen && (
              <React.Suspense fallback={<></>}>
                   <EnergyGraph
                userLanguage={userLanguage}
                potentialEnergyRed={potentialEnergy1}
                kineticEnergyRed={KineticEnergy1}
                kineticEnergyBlue={KineticEnergy2}
                potentialEnergyBlue={potentialEnergy2}
                secondBall={showEnergySecondBall}
                setSecondBall={setEnergySecondBall}
              />
              </React.Suspense>
             
            )}

            {!isMediumScreen && <Timer />}
          </Stack>
        </Grid.Col>

        {/* Center with CenterPendulum */}
        <Grid.Col span={6} style={{ overflow: "hidden" }}>
          <Center style={{ height: "100%", overflow: "hidden" }}>
            <Box
              style={{
                position: "absolute",
                left: "50%",
                top: 0,
                bottom: 0,
                height: "239px",
                width: "475px",
                backgroundImage: `url(${protectorImg})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                transform: isMediumScreen
                  ? "translateX(-234px) scale(0.7)"
                  : "translateX(-233px)",
                transformOrigin: "top",
                overflow: "hidden",
              }}
            >
              {damping === 0 && (
                <>
                  <Box
                    style={{
                      position: "relative",
                      left: "49%",
                      top: "85%",
                      width: "2px",
                      height: "20px",
                      borderLeft: "4px solid red",
                      transformOrigin: "0 -1020%",
                      transform: `translateX(-50%) rotate(${maxAngle1}rad)`,
                    }}
                  />
                  <Box
                    style={{
                      position: "relative",
                      left: "49%",
                      top: "77%",
                      width: "2px",
                      height: "20px",
                      borderLeft: "4px solid red",
                      transformOrigin: "0 -1020%",
                      transform: `translateX(-50%) rotate(${
                        -1 * maxAngle1
                      }rad)`,
                    }}
                  />
                </>
              )}
              {damping === 0 && showSecondBall && (
                <>
                  <Box
                    style={{
                      position: "relative",
                      left: "49%",
                      top: "68%",
                      width: "2px",
                      height: "20px",
                      borderLeft: "4px solid blue",
                      transformOrigin: "0 -1020%",
                      transform: `translateX(-50%) rotate(${maxAngle2}rad)`,
                    }}
                  />
                  <Box
                    style={{
                      position: "relative",
                      left: "49%",
                      top: "60%",
                      width: "2px",
                      height: "20px",
                      borderLeft: "4px solid blue",
                      transformOrigin: "0 -1020%",
                      transform: `translateX(-50%) rotate(${
                        -1 * maxAngle2
                      }rad)`,
                    }}
                  />
                </>
              )}
            </Box>
            {/* dashed line for refrence */}
            <Box
              style={{
                position: "absolute",
                left: "50%",
                top: 0,
                bottom: 0,
                height: "500px",
                borderLeft: "1px dashed black",
                overflow: "hidden",
              }}
            />
            <CenterPendulum
              color={"#FF0000"}
              armLength={armLength1}
              ballRadius={ballRadius1}
              damping={damping}
              angle={angle1}
              setAngle={setAngle1}
              maxAngle={maxAngle1}
              setMaxAngle={setMaxAngle1}
              setKineticEnergy={setKineticEnergy1}
              setPotentialEnergy={setPotentialEnergy1}
              reset={reset}
              setReset={setReset}
              isPlay={isPlay}
              setIsPlay={setIsPlay}
              frameRate={frameRate}
              setShowFBD={setShowFBD}
              setShowValues={setShowValues}
              showFBD={showFBD}
              showValues={showValues}
            />
            {showSecondBall && (
              <CenterPendulum
                color={"#2400FF"}
                armLength={armLength2}
                ballRadius={ballRadius2}
                damping={damping}
                angle={angle2}
                setAngle={setAngle2}
                maxAngle={maxAngle2}
                setMaxAngle={setMaxAngle2}
                setKineticEnergy={setKineticEnergy2}
                setPotentialEnergy={setPotentialEnergy2}
                reset={reset}
                setReset={setReset}
                isPlay={isPlay}
                setIsPlay={setIsPlay}
                frameRate={frameRate}
                setShowFBD={setShowFBD}
                setShowValues={setShowValues}
                showFBD={showFBD}
                showValues={showValues}
              />
            )}
          </Center>
        </Grid.Col>

        {/* Right side with PendulumControls and Options */}
        <Grid.Col span={3}>
          <Stack align="center" spacing="md">
            {!isMediumScreen && (
              <>
                <PendulumControls
                  lengthRed={armLength1}
                  lengthBlue={armLength2}
                  setLengthRed={setArmLength1}
                  setLengthBlue={setArmLength2}
                  massRed={ballRadius1}
                  massBlue={ballRadius2}
                  setMassRed={setBallRadius1}
                  setMassBlue={setBallRadius2}
                  isTwoPendulum={showSecondBall}
                  setIsTwoPendulum={setShowSecondBall}
                  userLanguage={userLanguage}
                />
                <Options
                  showFBD={showFBD}
                  setShowFBD={setShowFBD}
                  showValues={showValues}
                  setShowValues={setShowValues}
                  userLanguage={userLanguage}
                />
              </>
            )}
          </Stack>
        </Grid.Col>
      </Grid>

      <Toolbar
        armLength1={armLength1}
        armLength2={armLength2}
        ballRadius1={ballRadius1}
        ballRadius2={ballRadius2}
        damping={damping}
        isPlay={isPlay}
        angle1={angle1}
        angle2={angle2}
        maxAngle1={maxAngle1}
        maxAngle2={maxAngle2}
        reset={reset}
        showFBD={showFBD}
        showValues={showValues}
        showSecondBall={showSecondBall}
        showEnergySecondBall={showEnergySecondBall}
        showUserPrefrence={showUserPrefrence}
        setShowUserPrefrence={setShowUserPrefrence}
        setShowSecondBall={setShowSecondBall}
        setEnergySecondBall={setEnergySecondBall}
        setShowValues={setShowValues}
        setShowFBD={setShowFBD}
        setAngle1={setAngle1}
        setAngle2={setAngle2}
        setMaxAngle1={setMaxAngle1}
        setMaxAngle2={setMaxAngle2}
        setIsPlay={setIsPlay}
        setReset={setReset}
        setKineticEnergy1={setKineticEnergy1}
        setKineticEnergy2={setKineticEnergy2}
        setPotentialEnergy1={setPotentialEnergy1}
        setPotentialEnergy2={setPotentialEnergy2}
        setBallRadius1={setBallRadius1}
        setBallRadius2={setBallRadius2}
        setArmLength1={setArmLength1}
        setArmLength2={setArmLength2}
        setFrameRate={setFrameRate}
        frameRate={frameRate}
        setDamping={setDamping}
        option={option}
        setOption={setOption}
      />

      {isMediumScreen &&
        (option === "energy-graph" ||
          option === "options" ||
          option === "controls") && (
          <>
            {<Overlay opacity={0.8} color="#000" zIndex={1000} />}
            <Flex
              justify="center"
              align="center"
              style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                border: "2px solid white",
                borderRadius: "10px",
                backgroundColor: "white",
                padding: "10px",
                transform: "translateX(-50%) translateY(-50%)",
                zIndex: 1001,
              }}
            >
              <Box
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "20px",
                  right: "20px",
                }}
              >
                <img
                  style={{ cursor: "pointer" }}
                  onClick={handleclosebutton}
                  src={closeImg}
                  width={10}
                  height={10}
                  alt="close"
                />
              </Box>
              {option === "energy-graph" && (
                <React.Suspense fallback={<></>}>
                  <EnergyGraph
                  userLanguage={userLanguage}
                  potentialEnergyRed={potentialEnergy1}
                  kineticEnergyRed={KineticEnergy1}
                  kineticEnergyBlue={KineticEnergy2}
                  potentialEnergyBlue={potentialEnergy2}
                  secondBall={showEnergySecondBall}
                  setSecondBall={setEnergySecondBall}
                />
                </React.Suspense>
                
              )}
              {option === "controls" && (
                <PendulumControls
                  lengthRed={armLength1}
                  lengthBlue={armLength2}
                  setLengthRed={setArmLength1}
                  setLengthBlue={setArmLength2}
                  massRed={ballRadius1}
                  massBlue={ballRadius2}
                  setMassRed={setBallRadius1}
                  setMassBlue={setBallRadius2}
                  isTwoPendulum={showSecondBall}
                  setIsTwoPendulum={setShowSecondBall}
                  userLanguage={userLanguage}
                />
              )}
              {option === "options" && (
                <Options
                  showFBD={showFBD}
                  setShowFBD={setShowFBD}
                  showValues={showValues}
                  setShowValues={setShowValues}
                  userLanguage={userLanguage}
                />
              )}
            </Flex>
          </>
        )}

      {showUserPrefrence && (
        <>
          <Overlay
            opacity={0.8}
            color="#000"
            zIndex={1001}
            onClick={() => setShowUserPrefrence(false)}
          />
          <Flex
            justify="center"
            align="center"
            direction="column"
            style={{
              minWidth: "300px",
              maxWidth: "400px",
              position: "absolute",
              left: "50%",
              top: "50%",
              border: "2px solid white",
              borderRadius: "10px",
              backgroundColor: "white",
              padding: "20px",
              transform: "translateX(-50%) translateY(-50%)",
              zIndex: 1001,
            }}
          >
            <>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  height: "25%",
                }}
              >
                <Text sx={{ fontWeight: 700, fontSize: "25px" }}>
                  {getLanguageEnumByKeyForPendulumModel({
                    key: "Preferences",
                    LanguageId: userLanguage,
                  })}
                </Text>
                <Box style={{ cursor: "pointer" }}>
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowUserPrefrence(false)}
                    src={closeImg}
                    width={15}
                    height={15}
                    alt="close"
                  />
                </Box>
              </Box>
              <Box sx={{ height: "70%", width: "100%" }}>
                <Tabs defaultValue="overview">
                  <Tabs.List>
                    <Tabs.Tab value="overview">
                      {getLanguageEnumByKeyForPendulumModel({
                        key: "Overview",
                        LanguageId: userLanguage,
                      })}
                    </Tabs.Tab>
                    <Tabs.Tab value="languages">
                      {getLanguageEnumByKeyForPendulumModel({
                        key: "Languages",
                        LanguageId: userLanguage,
                      })}
                    </Tabs.Tab>
                  </Tabs.List>

                  <Tabs.Panel value="overview" pt="lg">
                    <Text>
                      {getLanguageEnumByKeyForPendulumModel({
                        key: "Preference_Content",
                        LanguageId: userLanguage,
                      })}
                    </Text>
                  </Tabs.Panel>
                  <Tabs.Panel value="languages" pt="lg">
                    <Box
                      sx={{
                        width: "100%",
                        height: "70px",
                        border: "2px solid black",
                        borderRadius: "10px",
                        padding: "7px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        style={{
                          borderRadius: "10px",
                          height: "100%",
                          width: "45%",
                          border: "1px solid transparent",
                          backgroundColor:
                            userLanguage === "en" ? "#BACEF4" : "transparent",
                        }}
                        variant="outline"
                        onClick={() => setUserLanguage("en")}
                      >
                        <Text sx={{ fontSize: "20px" }} fw={500} c="black">
                          English
                        </Text>
                      </Button>
                      <Button
                        style={{
                          borderRadius: "10px",
                          height: "100%",
                          width: "45%",
                          border: "1px solid transparent",
                          backgroundColor:
                            userLanguage === "hi" ? "#BACEF4" : "transparent",
                        }}
                        variant="outline"
                        onClick={() => setUserLanguage("hi")}
                      >
                        <Text sx={{ fontSize: "20px" }} fw={500} c="black">
                          हिंदी
                        </Text>
                      </Button>
                    </Box>
                  </Tabs.Panel>
                </Tabs>
              </Box>
            </>
          </Flex>
        </>
      )}

<Button
        // variant="outline"
        color="red"
        style={{
          position: "absolute",
          top: isVerySmallScreen ? "1%" : "2%",
          right: isVerySmallScreen ? "1%" : "2%",
          margin: 0,
          padding: 0,
        }}
        onClick={() => navigate(-1)}
      >
        <img
          src={maincloseImg}
          alt="close"
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </Button>




    </Container>
  );
}
