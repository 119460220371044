import React, { useState, useEffect } from "react";
import { Button, Text, Box, Flex } from "@mantine/core";
import { IconPlayerPlay, IconRefresh } from "@tabler/icons-react";

const Timer: React.FC = () => {
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  // Handle timer logic
  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (isRunning) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 0.01);
      }, 10);
    } else if (!isRunning && interval) {
      clearInterval(interval);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isRunning]);

  // Format time to 2 decimal places
  const formattedTime = time.toFixed(2);

  return (
    <Flex
      style={{
        backgroundColor: "#8661e8",
        padding: "15px",
        borderRadius: "12px",
        width: "180px",
      }}
      direction="column"
      align="center"
    >
      <Box
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          borderRadius: "8px",
          marginBottom: "10px",
          width: "100%",
          textAlign: "center",
        }}
      >
        <Text size="lg" weight={500}>
          {formattedTime} sec
        </Text>
      </Box>
      <Flex gap="10px">
        <Button
          onClick={() => {
            setTime(0);
            setIsRunning(false);
          }}
          variant="default"
          radius="md"
          size="md"
          style={{ padding: "10px", backgroundColor: "#ccc" }}
        >
          <IconRefresh size={20} />
        </Button>
        <Button
          onClick={() => setIsRunning(!isRunning)}
          variant="default"
          radius="md"
          size="md"
          style={{ padding: "10px", backgroundColor: "#ccc" }}
        >
          <IconPlayerPlay size={20} />
        </Button>
      </Flex>
    </Flex>
  );
};

export default Timer;
