import React, { useState } from "react";
import { Button, Box, Menu, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction } from "react";
import { getLanguageEnumByKeyForPendulumModel } from "../../../assets/LanguageEnums/SimulationEnumFunction";
const maincloseImg =
  "https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-07-19T08-08-49-765Z.png";
  const playImg="https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-11-18T13-24-38-419Z.png"

  const pauseImg="https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-11-18T13-25-50-330Z.png"
  const resetImg="https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-11-18T13-26-53-950Z.png"
  const userPrefrencesImg="https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-11-18T13-27-34-935Z.png"
  const optionsImg="https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-11-18T13-28-27-101Z.png"

interface ToolbarProps {
  armLength1?: number;
  armLength2?: number;
  ballRadius1?: number;
  ballRadius2?: number;
  damping?: number;
  isPlay?: boolean;
  angle1?: number;
  angle2?: number;
  maxAngle1?: number;
  maxAngle2?: number;
  reset?: boolean;
  showFBD: boolean;
  showValues: boolean;
  showSecondBall: boolean;
  showEnergySecondBall: boolean;
  option: string | null;
  showUserPrefrence: boolean;
  setShowUserPrefrence: Dispatch<SetStateAction<boolean>>;
  setOption: (option: string | null) => void;
  setDamping: (damping: number) => void;
  setShowSecondBall: (showSecondBall: boolean) => void;
  setEnergySecondBall: (showEnergySecondBall: boolean) => void;
  setShowValues: (showValues: boolean) => void;
  setShowFBD: (showFBD: boolean) => void;
  setAngle1: (angle1: number) => void;
  setAngle2: (angle2: number) => void;
  setMaxAngle1: (maxAngle1: number) => void;
  setMaxAngle2: (maxAngle2: number) => void;
  setIsPlay: (isPlay: boolean) => void;
  setReset: (reset: boolean) => void;
  setKineticEnergy1: (energy: number) => void;
  setPotentialEnergy1: (energy: number) => void;
  setKineticEnergy2: (energy: number) => void;
  setPotentialEnergy2: (energy: number) => void;
  setBallRadius1: (ballRadius1: number) => void;
  setBallRadius2: (ballRadius1: number) => void;
  setArmLength1: (armLength1: number) => void;
  setArmLength2: (armLength2: number) => void;
  frameRate: 0.25 | 0.5 | 1;
  setFrameRate: (frameRate: 0.25 | 0.5 | 1) => void;
}
const Toolbar: React.FC<ToolbarProps> = ({
  armLength1,
  isPlay,
  option,
  frameRate,
  showUserPrefrence,
  setArmLength1,
  setArmLength2,
  setAngle1,
  setAngle2,
  setBallRadius1,
  setBallRadius2,
  setEnergySecondBall,
  setFrameRate,
  setIsPlay,
  setKineticEnergy1,
  setKineticEnergy2,
  setMaxAngle1,
  setMaxAngle2,
  setPotentialEnergy1,
  setPotentialEnergy2,
  setReset,
  setShowFBD,
  setShowSecondBall,
  setShowValues,
  setDamping,
  setOption,
  setShowUserPrefrence,
}) => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width: 1075px)");
  const [showMenuInfo, setShowMenuInfo] = useState<boolean>(false);
  const [showtimer, setShowtimer] = useState<boolean>(false);
  const [userLanguage, setUserLanguage] = useState<string>("en");
  const isMediumScreen = useMediaQuery("(max-width: 1072px)");

  enum MenuItemsEnum {
    FRAMERATE = "FRAMERATE",
    PAUSE = "PAUSE",
    PLAY = "PLAY",
    OPTIONS = "OPTIONS",
    RESET = "RESET",
    USERPREFRENCES = "USERPREFRENCES",
  }
  const menuItems = [
    { id: MenuItemsEnum.RESET, name: "reset", img: resetImg },
    !isPlay
      ? { id: MenuItemsEnum.PLAY, name: "play", img: playImg }
      : { id: MenuItemsEnum.PAUSE, name: "pause", img: pauseImg },
    { id: MenuItemsEnum.FRAMERATE, name: "frame rate", img: null },
    {
      id: MenuItemsEnum.USERPREFRENCES,
      name: "userprefrences",
      img: userPrefrencesImg,
    },
  ];

  if (isMediumScreen) {
    menuItems.splice(3, 0, {
      id: MenuItemsEnum.OPTIONS,
      name: "options",
      img: optionsImg,
    });
  }

  const getNextFrameRate = (current: 0.25 | 0.5 | 1): 0.25 | 0.5 | 1 => {
    if (current === 0.5) return 1;
    if (current === 1) return 0.25;
    return 0.5;
  };

  const handleMenuItemClick = (id: string) => {
    if (id === MenuItemsEnum.RESET) {
      setReset(true);
      setAngle1(0);
      setAngle2(0);
      setMaxAngle1(0);
      setMaxAngle2(0);
      setArmLength1(400);
      setArmLength2(300);
      setBallRadius1(40);
      setBallRadius2(30);
      setPotentialEnergy1(0);
      setPotentialEnergy2(0);
      setKineticEnergy1(0);
      setKineticEnergy2(0);
      setDamping(0);
      setShowSecondBall(false);
      setEnergySecondBall(false);
      setShowFBD(false);
      setShowValues(false);
      setIsPlay(false);
      setFrameRate(1);
      setShowMenuInfo(false);
      setShowUserPrefrence(false);
      setShowtimer(false);
      setOption(null);
    } else if (id === MenuItemsEnum.PLAY) {
      setIsPlay(true);
    } else if (id === MenuItemsEnum.PAUSE) {
      setIsPlay(false);
    } else if (id === MenuItemsEnum.FRAMERATE) {
      setFrameRate(getNextFrameRate(frameRate));
    } else if (id === MenuItemsEnum.USERPREFRENCES) {
      setShowUserPrefrence((prev: boolean) => !prev);
    }
  };

  const handleBackButton = () => {
    const currentPath = window.location.pathname;
    const segments = currentPath.split("/");
    const newPathname = segments.slice(0, -2).join("/");
    navigate(`${newPathname}`);
  };

  return (
    <Box
      sx={{
        position: "absolute",
        left: "50%",
        bottom: "0",
        transform: "translateX(-50%)",
        zIndex: 14,
        padding: isMediumScreen ? "4px" : "10px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 10,
        marginBottom: "10px",
      }}
    >
      {menuItems.map((item, index) =>
        item.id === "OPTIONS" ? (
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Button
                key={index}
                variant="outline"
                onClick={() => handleMenuItemClick(item.id)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 35,
                  height: 35,
                  borderRadius: "50%",
                  border: "1px solid black",
                  padding: "5px",
                  transition: "opacity 0.3s",
                  "&:hover": {
                    opacity: 0.8,
                    backgroundColor: "white",
                  },
                  color: "black",
                }}
              >
                <img
                  src={item.img!}
                  alt={item.name}
                  style={{
                    width: isMediumScreen ? 22 : 25,
                    height: isMediumScreen ? 22 : 25,
                  }}
                />
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item onClick={() => setOption("energy-graph")}>
                {getLanguageEnumByKeyForPendulumModel({
                  key: "Energy_Graph",
                  LanguageId: userLanguage,
                })}
              </Menu.Item>
              <Menu.Item onClick={() => setOption("controls")}>
                {getLanguageEnumByKeyForPendulumModel({
                  key: "Controls",
                  LanguageId: userLanguage,
                })}
              </Menu.Item>
              <Menu.Item onClick={() => setOption("options")}>
                {getLanguageEnumByKeyForPendulumModel({
                  key: "Options",
                  LanguageId: userLanguage,
                })}
              </Menu.Item>

              {/* <Menu.Divider /> */}
            </Menu.Dropdown>
          </Menu>
        ) : (
          <Button
            key={index}
            variant="outline"
            onClick={() => handleMenuItemClick(item.id)}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: index === 1 ? 50 : 35,
              height: index === 1 ? 50 : 35,
              borderRadius: "50%",
              border: "1px solid black",
              padding: "5px",
              transition: "opacity 0.3s",
              "&:hover": {
                opacity: 0.8,
                backgroundColor: "white",
              },
              color: "black",
            }}
          >
            {item.img ? (
              <img
                src={item.img}
                alt={item.name}
                style={{
                  width: isMediumScreen ? 22 : 25,
                  height: isMediumScreen ? 22 : 25,
                }}
              />
            ) : (
              <Text>
                {" "}
                {item.id === MenuItemsEnum.FRAMERATE
                  ? `${frameRate}X`
                  : ""}{" "}
              </Text>
              // <></>
            )}
          </Button>
        )
      )}
      {isMediumScreen && (
        <Button
          onClick={handleBackButton}
          variant="outline"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "5px",
            transition: "opacity 0.3s",
            border: "0.1px solid white",
            "&:hover": {
              opacity: 0.8,
              backgroundColor: "white",
            },
            color: "black",
          }}
        >
          <img
            src={maincloseImg}
            alt="close"
            style={{
              width: 20,
              height: 20,
            }}
          />
        </Button>
      )}
    </Box>
  );
};

export default Toolbar;
