import { Box, Flex, Stack, Text } from "@mantine/core";
import {
  QuestionParentType,
  QuestionType,
  findQuestionType,
} from "../../../@types/QuestionTypes.d";
import { useEffect, useRef, useState } from "react";
import { DisplayHtmlText } from "../../../pages/_New/PersonalizedTestQuestions";

export function createNewSectionTypeHtmlFromTestData(
  test: FullTest,
  name: string,
  iconUrl: string,
  subHeading: string | null,
  banner: string | null,
  subscriptionModelType: string,
  showInstructions: boolean,
  comicSanFont: boolean,
  inlineContent: boolean,
  phoneNumber: string,
  secondPhoneNumber: string,
  address: string,
  isSolutions: boolean,
  showPartition: boolean
) {
  const lettering = ["a", "b", "c", "d", "e"];
  const capitalLettering = ["A", "B", "C", "D", "E"];
  const instructions = [];

  const myMap: Map<
    string,
    CaseBasedQuestion | McqQuestion | SubjectiveQuestion
  > = new Map();
  test.questions.map((x) => {
    myMap.set(x._id, x);
  });
  test.casebasedquestions.map((x) => {
    myMap.set(x._id, x);
  });
  test.subjectiveQuestions.map((x) => {
    myMap.set(x._id, x);
  });
  instructions.push(
    `The Test paper has ${test.maxQuestions} questions in total.`
  );
  instructions.push(`Marks are indicated against each question.`);
  let count = 0;
  for (let i = 0; i < test.sections.length; i++) {
    let section = test.sections[i];
    instructions.push(
      `Questions from ${i + 1 + count} to ${
        count + i + section.questions.length
      } are ${section.name} questions`
    );
    count += section.questions.length;
  }

  instructions.push(
    `Follow the specified procedure for submitting your answer sheets or online responses.`
  );
  return (
    <>
      <html lang="en">
        <head>
          <style>
            @import
            url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,600;1,800&display=swap');
            @import url(//fonts.googleapis.com/css?family=Times+New+Roman);
          </style>

          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin=""
          />
          {comicSanFont && (
            <>
              <link
                href="https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@0,400;1,700&display=swap"
                rel="stylesheet"
              />

              <link
                href="https://fonts.googleapis.com/css2?family=Courgette&display=swap"
                rel="stylesheet"
              ></link>
            </>
          )}

          <script
            id="MathJax-script"
            defer
            src="https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-chtml.js"
          ></script>
        </head>
        <body
          style={{
            fontFamily: comicSanFont
              ? "Comic Neue, sans-serif"
              : "Nunito, sans-serif",
            whiteSpace: "pre-line",
            margin: "20px",
            padding: "0",
          }}
        >
          <div style={{ fontFamily: "Arial, sans-serif", padding: "0" }}>
            <div style={{ width: "100%", margin: "auto" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px",
                  fontWeight: 700,
                  gap: 15,
                }}
              >
                <div
                  style={{
                    width: "10%",
                    marginRight: "20px",
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "end",
                  }}
                >
                  <img
                    src={iconUrl!}
                    alt="Logo"
                    style={{ width: "100px", marginLeft: "20px" }}
                  />
                </div>
                <div
                  style={{
                    width: "auto",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h1
                    style={{
                      margin: "0",
                      fontSize: "30px",
                      fontStyle: "italic",
                      fontWeight: 700,
                      textAlign: "center",
                      fontFamily: "Times New Roman",
                    }}
                  >
                    {name.toUpperCase()}
                  </h1>
                  <h1
                    style={{
                      margin: "0",
                      fontSize: "24px",
                      fontWeight: 500,
                    }}
                  >
                    {" "}
                    {address.toUpperCase() + address ? " ," : ""}{" "}
                    {phoneNumber.toUpperCase()} {secondPhoneNumber ? " , " : ""}{" "}
                    {secondPhoneNumber.toUpperCase()}
                  </h1>
                </div>
              </div>

              <h2
                style={{
                  textAlign: "center",
                  fontSize: "28px",
                  marginTop: "10px",
                  fontWeight: 400,
                  fontFamily: "Times New Roman",
                }}
              >
                {" "}
                {test.name.toUpperCase()}
              </h2>

              {showInstructions && (
                <div
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    marginTop: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "start",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        fontFamily: "Times New Roman",
                        marginTop: "0",
                      }}
                    >
                      General Instructions:
                    </p>
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        fontFamily: "Times New Roman",
                        marginTop: "0",
                      }}
                    >
                      Maximum Marks: {test.maxMarks}
                    </p>
                  </div>
                  <ol
                    style={{
                      paddingLeft: "20px",
                      marginTop: "1px",
                      fontFamily: "Times New Roman",
                    }}
                  >
                    {instructions.map((x, i) => {
                      return (
                        <li
                          style={{
                            marginBottom: "5px",
                            fontSize: "16px",
                            fontWeight: 400,
                            fontFamily: "Times New Roman",
                          }}
                        >
                          {x}
                        </li>
                      );
                    })}
                  </ol>
                </div>
              )}
              <div style={{ width: "100%", marginTop: "5px"}}>
                {test.sections.map((x, i) => {
                  return (
                    <div
                      style={{
                        width: showPartition ? "327px" : "100%",
                        borderRight: showPartition
                          ? "1px solid #000000"
                          : "none",
                        paddingRight: "17px",

                      }}
                    >
                      <Text
                        style={{
                          textAlign: "center",
                          marginTop: "20px",
                          marginBottom: "10px",
                          fontSize: "16px",
                          fontWeight: 700,
                        }}
                      >
                        {x.name}
                      </Text>
                      {x.questions.map((y, index) => {
                        const question: any = myMap.get(y);
                        switch (findQuestionType(question.type).parentType) {
                          case QuestionParentType.MCQQ:
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  margin: "10px 0px",
                                  fontFamily: "Times New Roman",
                                }}
                              >
                                <Box
                                  style={{
                                    marginBottom: 20,
                                    width: "95%",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "start",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flex: 1,
                                        flexWrap: "nowrap",
                                        whiteSpace: "normal",
                                      }}
                                    >
                                      <div
                                        style={{
                                          flex: 1,
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "normal",
                                          alignSelf: "self-start",
                                          paddingTop: 0,
                                          paddingRight: "4px",
                                          fontSize: "16px",
                                          fontFamily: "Times New Roman",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: 10,
                                            marginTop: 2,
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {index + 1}.
                                        </span>
                                        <DisplayHtmlText text={question.text} />
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "start",
                                        justifyContent: "start",
                                        minWidth: "20px",
                                        paddingTop: 0,
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          marginTop: 0,
                                        }}
                                      >
                                        {question.totalMarks
                                          ? "+" + question.totalMarks
                                          : ""}{" "}
                                        {question.totalNegativeMarks
                                          ? ", -" + question.totalNegativeMarks
                                          : ""}
                                      </p>
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    {question.answers.map(
                                      (answer: any, index: number) => (
                                        <div
                                          key={index}
                                          style={{
                                            margin: "7px 5px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            fontSize: "16px",
                                            fontFamily: "Times New Roman",
                                          }}
                                        >
                                          <span
                                            style={{
                                              marginLeft: 10,
                                              fontWeight: "bold",
                                              fontFamily: "Times New Roman",
                                            }}
                                          >
                                            {lettering[index]}
                                            {") "}
                                          </span>
                                          <DisplayHtmlText text={answer.text} />
                                        </div>
                                      )
                                    )}
                                  </div>
                                  <div>
                                    {isSolutions && (
                                      <div
                                        style={{
                                          margin: "5px 10px",
                                          display: "flex",
                                          alignItems: "center",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          paddingTop: "5px",
                                          paddingBottom: "5px",
                                          fontFamily: "Times New Roman",
                                        }}
                                      >
                                        <p
                                          style={{
                                            margin: 0,
                                            padding: 0,
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            fontFamily: "Times New Roman",
                                          }}
                                        >
                                          Correct Answer:
                                        </p>
                                        <DisplayHtmlText
                                          text={
                                            question.answers.find((x: any) => {
                                              return x.isCorrect === true;
                                            }).text
                                          }
                                        />
                                      </div>
                                    )}
                                  </div>
                                </Box>
                              </div>
                            );
                          case QuestionParentType.SUBQ:
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginBottom: 20,
                                  gap: 0,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  <Box
                                    style={{
                                      marginBottom: 20,
                                      width: "95%",
                                      display: "flex",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "start",
                                        flexWrap: "wrap",
                                        width: "100%",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          minWidth: "500px",
                                          flexWrap: "nowrap",
                                          whiteSpace: "normal",
                                        }}
                                      >
                                        <div
                                          style={{
                                            flex: 1,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "normal",
                                            paddingTop: 0,
                                            paddingRight: "4px",
                                            fontSize: "16px",
                                            fontFamily: "Times New Roman",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <span
                                            style={{
                                              marginRight: 10,
                                              marginTop: 2,
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {index + 1}.
                                          </span>
                                          <DisplayHtmlText
                                            text={question.text}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "start",
                                          justifyContent: "start",
                                          minWidth: "20px",
                                          paddingTop: 0,
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontWeight: "bold",
                                            textAlign: "right",
                                            marginTop: 0,
                                          }}
                                        >
                                          {question.totalMarks
                                            ? "+" + question.totalMarks
                                            : ""}{" "}
                                          {question.totalNegativeMarks
                                            ? ", -" +
                                              question.totalNegativeMarks
                                            : ""}
                                        </p>
                                      </div>
                                    </div>
                                  </Box>
                                </div>
                                <div
                                  style={{
                                    marginTop: -20,
                                  }}
                                >
                                  {isSolutions && (
                                    <div
                                      style={{
                                        margin: "5px 10px",
                                        display: "flex",
                                        alignItems: "center",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        fontSize: "16px",
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                        fontFamily: "Times New Roman",
                                      }}
                                    >
                                      <p
                                        style={{
                                          margin: 0,
                                          padding: 0,
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          fontFamily: "Times New Roman",
                                        }}
                                      >
                                        Correct Answer:
                                      </p>
                                      <DisplayHtmlText text={question.answer} />
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          case QuestionParentType.CASEQ:
                            return (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <Flex
                                    pt={10}
                                    style={{
                                      width: "95%",
                                      fontFamily: "Times New Roman",
                                    }}
                                  >
                                    <p
                                      style={{
                                        margin: 0,
                                        padding: 0,
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          marginLeft: 10,
                                          marginRight: 5,
                                          fontWeight: "bold",
                                          fontFamily: "Times New Roman",
                                        }}
                                      >
                                        {capitalLettering[index]}
                                        {") "}
                                      </span>
                                      Passage:
                                    </p>
                                    <DisplayHtmlText
                                      text={question.caseStudyText}
                                    />
                                  </Flex>
                                  <Box
                                    style={{
                                      marginBottom: 20,
                                      width: "95%",
                                    }}
                                  >
                                    {question.questions.map(
                                      (question1: any, i: any) => {
                                        return (
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              marginBottom: 20,
                                              gap: 0,
                                              fontFamily: "Times New Roman",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                margin: "10px 0px",
                                              }}
                                            >
                                              <Box
                                                style={{
                                                  marginBottom: 20,
                                                  width: "95%",
                                                }}
                                              >
                                                {question1.questionImageUrl && (
                                                  <img
                                                    src={
                                                      question1.questionImageUrl
                                                    }
                                                    width={"25%"}
                                                  ></img>
                                                )}
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    alignItems: "center",
                                                    flexWrap: "wrap",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                      flex: 1,
                                                      flexWrap: "nowrap",
                                                      whiteSpace: "normal",
                                                      fontFamily:
                                                        "Times New Roman",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        flex: 1,
                                                        overflow: "hidden",
                                                        textOverflow:
                                                          "ellipsis",
                                                        whiteSpace: "normal",
                                                        alignSelf: "self-start",
                                                        paddingTop: 0,
                                                        paddingRight: "4px",
                                                        fontSize: "16px",
                                                        fontFamily:
                                                          "Times New Roman",
                                                        display: "flex",
                                                        alignItems: "center",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          marginRight: 10,
                                                          marginTop: 2,
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        {index + 1}.
                                                      </span>
                                                      <DisplayHtmlText
                                                        text={question1.text}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "start",
                                                      justifyContent: "start",
                                                      minWidth: "20px",
                                                      paddingTop: 0,
                                                    }}
                                                  >
                                                    <p
                                                      style={{
                                                        fontWeight: "bold",
                                                        marginTop: 0,
                                                      }}
                                                    >
                                                      {question.totalMarks
                                                        ? "+" +
                                                          question.totalMarks
                                                        : ""}{" "}
                                                      {question.totalNegativeMarks
                                                        ? ", -" +
                                                          question.totalNegativeMarks
                                                        : ""}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  {question1.answers.map(
                                                    (
                                                      answer: any,
                                                      index: number
                                                    ) => {
                                                      return (
                                                        <div
                                                          key={index}
                                                          style={{
                                                            margin: "5px 10px",
                                                            display: "flex",
                                                            flexWrap: "wrap",
                                                            fontFamily:
                                                              "Times New Roman",
                                                          }}
                                                        >
                                                          <div
                                                            key={index}
                                                            style={{
                                                              margin:
                                                                "5px 10px",
                                                              display: "flex",
                                                              alignItems:
                                                                "center",
                                                              whiteSpace:
                                                                "nowrap",
                                                              overflow:
                                                                "hidden",
                                                              textOverflow:
                                                                "ellipsis",
                                                              fontSize: "16px",
                                                              fontFamily:
                                                                "Times New Roman",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                marginLeft: 10,
                                                                fontWeight:
                                                                  "bold",
                                                                fontFamily:
                                                                  "Times New Roman",
                                                              }}
                                                            >
                                                              {lettering[index]}
                                                              {") "}
                                                            </span>
                                                            <DisplayHtmlText
                                                              text={answer.text}
                                                            />
                                                          </div>
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              </Box>
                                            </div>
                                            {isSolutions && (
                                              <div
                                                style={{
                                                  margin: "5px 10px",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  whiteSpace: "nowrap",
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  fontFamily: "Times New Roman",
                                                  paddingTop: "5px",
                                                  paddingBottom: "5px",
                                                }}
                                              >
                                                <p
                                                  style={{
                                                    margin: 0,
                                                    padding: 0,
                                                    fontWeight: "bold",
                                                    fontSize: "16px",
                                                    fontFamily:
                                                      "Times New Roman",
                                                  }}
                                                >
                                                  Correct Answer:
                                                </p>
                                                <DisplayHtmlText
                                                  text={
                                                    question1.answers.find(
                                                      (x: any) => {
                                                        return (
                                                          x.isCorrect === true
                                                        );
                                                      }
                                                    ).text
                                                  }
                                                />
                                              </div>
                                            )}
                                            {question1.explaination && (
                                              <div
                                                style={{
                                                  margin: "5px 10px",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  whiteSpace: "nowrap",
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  fontFamily: "Times New Roman",
                                                  paddingTop: "5px",
                                                  paddingBottom: "5px",
                                                }}
                                              >
                                                <p
                                                  style={{
                                                    margin: 0,
                                                    padding: 0,
                                                    fontWeight: "bold",
                                                    fontSize: "16px",
                                                    fontFamily:
                                                      "Times New Roman",
                                                  }}
                                                >
                                                  Explaination:
                                                </p>
                                                <DisplayHtmlText
                                                  text={question1.explaination}
                                                />
                                              </div>
                                            )}
                                          </div>
                                        );
                                      }
                                    )}
                                  </Box>
                                </div>
                              </>
                            );
                        }
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </body>
      </html>
    </>
  );
}

export function createNewSectionTypeHtmlFromTestDataV2(
  test: FullTest,
  name: string,
  iconUrl: string,
  subHeading: string | null,
  banner: string | null,
  subscriptionModelType: string,
  showInstructions: boolean,
  comicSanFont: boolean,
  inlineContent: boolean,
  phoneNumber: string,
  secondPhoneNumber: string,
  address: string,
  isSolutions: boolean
) {
  const lettering = ["a", "b", "c", "d", "e"];
  const instructions = [];

  const myMap: Map<
    string,
    CaseBasedQuestion | McqQuestion | SubjectiveQuestion
  > = new Map();
  test.questions.map((x) => {
    myMap.set(x._id, x);
  });
  test.casebasedquestions.map((x) => {
    myMap.set(x._id, x);
  });
  test.subjectiveQuestions.map((x) => {
    myMap.set(x._id, x);
  });
  instructions.push(
    `The Test paper has ${test.maxQuestions} questions in total.`
  );
  instructions.push(`Marks are indicated against each question.`);
  let count = 0;
  for (let i = 0; i < test.sections.length; i++) {
    let section = test.sections[i];
    instructions.push(
      `Questions from ${i + 1 + count} to ${
        count + i + section.questions.length
      } are ${section.name} questions`
    );
    count += section.questions.length;
  }

  instructions.push(
    `Follow the specified procedure for submitting your answer sheets or online responses.`
  );
  return (
    <>
      <Stack>
        <>
          {test.superSections.map((singleSuperSection) => {
            return (
              <Stack>
                {test.superSections.length > 1 && (
                  <Text>Section - {singleSuperSection.name}</Text>
                )}
                {test.sections
                  .filter((singleSection) => {
                    return (
                      singleSuperSection.sections.indexOf(singleSection._id) !=
                      -1
                    );
                  })
                  ?.map((singleSection, index) => {
                    return (
                      <Stack>
                        <Text>
                          Part{index + 1}: {singleSection.name}
                        </Text>
                      </Stack>
                    );
                  })}
              </Stack>
            );
          })}
        </>
      </Stack>
    </>
  );
}
