import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Image,
  LoadingOverlay,
  Modal,
  Progress,
  SimpleGrid,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GetInstituteGifts } from "../../../_parentsApp/features/instituteSlice";
import { RootState } from "../../../store/ReduxStore";
import {
  GetStudentGifts,
  RedeemGift,
} from "../../../_parentsApp/features/instituteStudentSlice";
import { Carousel, Embla, useAnimationOffsetEffect } from "@mantine/carousel";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons";
import { showNotification } from "@mantine/notifications";
import { useMediaQuery } from "@mantine/hooks";
import { GiftRedeemCard } from "../../../_parentsApp/Components/GiftRedeemCard";
import { StudentPrizes } from "../../../_parentsApp/Components/StudentPrizes";

const RedeemedPrizes = (props: {
  totalPoints: number;
  redeemedPoints: number;
  studentId: string;
  onRedeemClick: () => void;
}) => {
  const [allInstituteGifts, setALlInstituteGifts] = useState<InstituteGift[]>(
    []
  );
  const [allStudentGifts, setAllStudentGifts] = useState<StudentGift[]>([]);
  const [redeemWarning, setRedeemWarning] = useState<InstituteGift | null>(
    null
  );
  const instituteDetails = useSelector<RootState, InstituteDetails | null>(
    (state) => state.instituteDetailsSlice.instituteDetails
  );
  const theme = useMantineTheme();
  const isMd = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);
  const [embla, setEmbla] = useState<Embla | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const TRANSITION_DURATION = 200;
  useAnimationOffsetEffect(embla, TRANSITION_DURATION);

  function fetchInstituteGifts() {
    setIsLoading(true);
    GetInstituteGifts({
      id: instituteDetails?._id!!,
    })
      .then((data: any) => {
        setIsLoading(false);
        setALlInstituteGifts(data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }
  function fetchStudentGifts() {
    setIsLoading(true);
    GetStudentGifts({
      id: props.studentId,
    })
      .then((data: any) => {
        setAllStudentGifts(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    fetchInstituteGifts();
    fetchStudentGifts();
  }, []);

  return (
    <>
      <LoadingOverlay visible={isLoading} />
      <Grid>
        {allStudentGifts.length > 0 &&
          allStudentGifts.map(() => (
            <Grid.Col span={isMd ? 12 : 4}>
              <StudentPrizes
                studentId={props.studentId}
                onFetchGift={fetchStudentGifts}
                allStudentGifts={allStudentGifts}
              />
            </Grid.Col>
          ))}
      </Grid>
    </>
  );
};

export default RedeemedPrizes;
