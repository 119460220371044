import {
  Box,
  Button,
  Center,
  Divider,
  FileInput,
  Flex,
  Group,
  Image,
  LoadingOverlay,
  Modal,
  ScrollArea,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconArrowLeft, IconArrowRight } from "@tabler/icons";
import { SetStateAction, useEffect, useState } from "react";
import {
  AddStudentProfilePic,
  GetStudentInfoById,
} from "../features/instituteStudentSlice";
import { format, parse } from "date-fns";
import { useFileInput } from "../../hooks/useFileInput";
import { RedeemPrizesSection } from "./RedeemPrizes";
import FeeRecordSection from "./FeeRecords";
import RedeemedPrizes from "../../components/AdminPage/ClassSection/RedeemedPrizes";
import Attendance from "./ParentTest/Attendance";
import EditStudentProfile from "./EditStudentProfile";
import { DatePicker } from "@mantine/dates";
import { userInfo } from "os";
import { EditStudentProfileSingleFieldFromStudent } from "../../features/StudentSlice";
import BookmarkQuestionsSection from "./BookmarkQuestionsSection";
import { showNotification } from "@mantine/notifications";
import { Dispatch } from "redux";
import { useSelector } from "react-redux";
import { RootState } from "../../store/ReduxStore";

enum ProfileTabs {
  OVERVIEW = "Overview",
  REDEEMPRIZES = "Redeem Prizes",
  FEERECORDS = "Fee Records",
  ATTENDANCE = "Attendance",
  BOOKMARK = "Bookmark",
}

function SingleField(props: { title: string; description: string }) {
  return (
    <Stack>
      <Flex py={10} fw={700} fz={15}>
        <Text color="#B3B3B3" w="30%">
          {props.title}
        </Text>
        <Text color="#383838" w="60%">
          {props.description}
        </Text>
      </Flex>
      <Divider color="#D3D3D3" />
    </Stack>
  );
}
export function StudentProfile(props: {
  studentId: string;
  onBack: () => void;
  userType?: string;
  studentActiveTab?: string;
  batch?: string;
  setBatch?: (val: InstituteClass) => void;
}) {
  const theme = useMantineTheme();
  const isMd = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);
  const [activeTab, setActiveTab] = useState<ProfileTabs>(
    props.studentActiveTab && props.studentActiveTab === "Overview"
      ? ProfileTabs.OVERVIEW
      : ProfileTabs.FEERECORDS
  );
  const [tabsToDisplay, setTabsToDisplay] = useState<ProfileTabs[]>([]);

  useEffect(() => {
    const tabsToDisplay: ProfileTabs[] =
      props.userType === "teacher"
        ? (Object.values(ProfileTabs).filter(
            (tab) =>
              tab !== ProfileTabs.REDEEMPRIZES && tab !== ProfileTabs.BOOKMARK
          ) as ProfileTabs[])
        : (Object.values(ProfileTabs) as ProfileTabs[]);

    setTabsToDisplay(tabsToDisplay);
  }, [props.userType]);

  const [studentInfo, setStudentInfo] = useState<InstituteStudentInfo | null>(
    null
  );
  const [openEditName, setOpenEditName] = useState<boolean>(false);
  const [openEditDOB, setOpenEditDOB] = useState<boolean>(false);
  const [openEditAddress, setOpenEditAddress] = useState<boolean>(false);
  const [studentName, setStudentName] = useState<string>(
    studentInfo?.name ? studentInfo?.name : ""
  );
  const [studentDateOfBirth, setStudentDateOfBirth] = useState<Date | undefined>(
    new Date()
  );
  const [studentAddress, setStudentAddress] = useState<string>(
    studentInfo?.address ? studentInfo?.address : ""
  );
  const instituteDetails = useSelector<RootState, InstituteDetails | null>(
    (state) => state.instituteDetailsSlice.instituteDetails
  );

  const {
    file,
    fileInputRef,
    isLoading,
    url,
    setFile,
    setFileType,
    error,
    setUrl,
    fileName,
  } = useFileInput(() => {});

  useEffect(() => {
    if (url) {
      AddStudentProfilePic({
        id: props.studentId,
        profilePic: url,
      })
        .then((x) => {
          getStudentnfo();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [url]);
  const getStudentnfo = async () => {
    GetStudentInfoById({
      id: props.studentId,
    })
      .then((x: any) => {
        setStudentInfo(x);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getStudentnfo();
    setFileType("image");
  }, [props.studentId]);

  useEffect(() => {
    if (studentInfo?.name) {
      setStudentName(studentInfo.name);
    }
    if (studentInfo?.address) {
      setStudentAddress(studentInfo.address);
    }
    if (studentInfo?.dateOfBirth) {
      
      const defaultDate =
        studentInfo.dateOfBirth
          ? new Date(studentInfo.dateOfBirth)
          : undefined;
      setStudentDateOfBirth(
        defaultDate
      );
    }
  }, [studentInfo]);

  const handleEditStudentName = () => {
    if (!studentName) {
      showNotification({
        message: "Name is required",
      });
      return;
    }
    EditStudentProfileSingleFieldFromStudent({
      id: studentInfo?._id || "",
      name: studentName,
    })
      .then((response: any) => {
        getStudentnfo();
        setOpenEditName(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleEditStudentDOB = () => {
    EditStudentProfileSingleFieldFromStudent({
      id: studentInfo?._id || "",
      dateOfBirth: studentDateOfBirth
        ? studentDateOfBirth.toISOString()
        : undefined,
    })
      .then((response: any) => {
        getStudentnfo();
        setOpenEditDOB(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleEditStudentAddress = () => {
    EditStudentProfileSingleFieldFromStudent({
      id: studentInfo?._id || "",
      address: studentAddress,
    })
      .then((response: any) => {
        getStudentnfo();
        setOpenEditAddress(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      {studentInfo && (
        <Stack
          p={props.userType === "teacher" ? 0 : isMd ? 10 : 70}
          py={props.userType === "teacher" ? 0 : isMd ? 10 : 30}
        >
          <Flex gap={15} align={"center"}>
            <IconArrowLeft
              onClick={() => {
                props.onBack();
              }}
              style={{
                cursor: "pointer",
              }}
            />
            <Text fz={24} fw={700}>
              {props.userType === "teacher" ? "Student Profile" : "My Profile"}
            </Text>
          </Flex>
          <ScrollArea type="hover" w={"100%"}>
            <Flex mt={20} gap={isMd ? 10 : 20}>
              {tabsToDisplay.map((item) => {
                return (
                  <>
                    <Text
                      onClick={() => setActiveTab(item)}
                      color={activeTab === item ? "#000000" : "#B3B3B3"}
                      fw={700}
                      style={{
                        cursor: "pointer",
                        borderBottom:
                          activeTab === item ? "3px solid #4B65F6" : "none",
                      }}
                      size={isMd ? 12 : 14}
                      align="center"
                      pb={10}
                      px={15}
                    >
                      {item}
                    </Text>
                  </>
                );
              })}
            </Flex>
          </ScrollArea>
          <Divider mt={-15}></Divider>
          {activeTab === ProfileTabs.OVERVIEW && (
            <Stack w={isMd ? "100%" : "60%"}>
              <Flex
                align="center"
                w="100%"
                justify="space-between"
                direction={isMd ? "column" : "row"}
              >
                <Flex gap={30} w={isMd ? "100%" : "auto"}>
                  {studentInfo.profilePic === "" && (
                    <img
                      src={require("../../assets/blankProfile.png")}
                      style={{
                        width: isMd ? "80px" : "100px",
                      }}
                    />
                  )}
                  {studentInfo.profilePic !== "" && (
                    <img
                      src={studentInfo.profilePic}
                      style={{
                        width: isMd ? "60px" : "100px",
                        height: isMd ? "60px" : "100px",
                        borderRadius: "50%",
                        imageRendering: "pixelated",
                      }}
                    />
                  )}

                  <Stack spacing={0}>
                    <Text color="#303030" fz={isMd ? 18 : 23}>
                      Welcome
                    </Text>
                    <Text fz={isMd ? 30 : 40} fw={700}>
                      {studentInfo.name}
                    </Text>
                  </Stack>
                </Flex>
                <Flex
                  style={{
                    border: "1px solid #E9ECEF",
                    borderRadius: "10px",
                  }}
                  p={15}
                  gap={20}
                  w={isMd ? "100%" : "auto"}
                >
                  <Divider orientation="vertical" color="#FAA300" size="lg" />
                  <Stack spacing={0}>
                    <Flex gap={10} align="center">
                      <img src={require("../../assets/coins.png")} />
                      <Text>Available Points</Text>
                    </Flex>
                    <Flex align="center" justify="space-between">
                      <Text fz={43}>
                        {studentInfo.totalRewardpoints -
                          studentInfo.redeemedRewardpoints}
                      </Text>
                    </Flex>
                  </Stack>
                </Flex>
              </Flex>
              <Stack
                style={{
                  border: "1px solid #D3D3D3",
                  borderRadius: "10px",
                }}
                p={30}
                px={35}
                w={"100%"}
              >
                <Text fz={20}>Basic Info</Text>
                <Stack w={"100%"}>
                  <Flex py={10} fw={700} fz={15} align="center">
                    <Text color="#B3B3B3" w="30%">
                      Profile Picture
                    </Text>
                    <Flex
                      w="60%"
                      justify="space-between"
                      align="center"
                      onClick={() => {
                        if(instituteDetails?.featureAccess.studentEditAccess){
                          fileInputRef.current?.click();
                        }
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <Text color="#383838">Add Profile Picture</Text>
                      <Image
                        src={require("../../assets/addprofilepic.png")}
                        style={{
                          marginLeft: "15px",
                        }}
                        width={40}
                      />
                    </Flex>
                  </Flex>
                  <Divider color="#D3D3D3" />
                  <Flex py={10} fw={700} fz={15} align="center">
                    <Text color="#B3B3B3" w="30%">
                      Name
                    </Text>
                    <Flex
                      w="60%"
                      justify="space-between"
                      align="center"
                      onClick={() => {
                        if(instituteDetails?.featureAccess.studentEditAccess){
                          setOpenEditName(true);
                        }
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <Text color="#383838">{studentInfo.name}</Text>
                      <Image
                        src={require("../../assets/editprofile.png")}
                        style={{
                          marginLeft: "15px",
                        }}
                        width={20}
                      />
                    </Flex>
                  </Flex>
                  <Modal
                    opened={openEditName}
                    onClose={() => setOpenEditName(false)}
                    title="Edit name"
                  >
                    <TextInput
                      label="Name"
                      placeholder="Enter your name"
                      value={studentName}
                      onChange={(event) =>
                        setStudentName(event.currentTarget.value)
                      }
                    />
                    <Group position="right" mt="md">
                      <Button onClick={handleEditStudentName}>
                        Save Details
                      </Button>
                    </Group>
                  </Modal>
                  <Divider color="#D3D3D3" />
                  <Flex py={10} fw={700} fz={15} align="center">
                    <Text color="#B3B3B3" w="30%">
                      Birthday
                    </Text>
                    <Flex
                      w="60%"
                      justify="space-between"
                      align="center"
                      onClick={() => {
                        if(instituteDetails?.featureAccess.studentEditAccess){
                        setOpenEditDOB(true);
                        }
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <Text color="#383838">
                        {studentInfo && studentInfo.dateOfBirth !== null && studentInfo.dateOfBirth !== ""
                          ? format(
                              new Date(studentInfo.dateOfBirth),
                              "do MMMM yyyy"
                            )
                          : "-"}
                      </Text>
                      <Image
                        src={require("../../assets/editprofile.png")}
                        style={{
                          marginLeft: "15px",
                        }}
                        width={20}
                      />
                    </Flex>
                  </Flex>
                  <Modal
                    opened={openEditDOB}
                    onClose={() => setOpenEditDOB(false)}
                    title="Edit Birthday"
                  >
                    <DatePicker
                      label="Date of Birth"
                      placeholder="Pick a date"
                      defaultValue={studentDateOfBirth}
                      onChange={(date) => date?setStudentDateOfBirth(date):setStudentDateOfBirth(undefined)}
                    />
                    <Group position="right" mt="md">
                      <Button onClick={handleEditStudentDOB}>
                        Save Details
                      </Button>
                    </Group>
                  </Modal>
                  <Divider color="#D3D3D3" />
                </Stack>
              </Stack>
              <Stack
                style={{
                  border: "1px solid #D3D3D3",
                  borderRadius: "10px",
                }}
                p={30}
                px={35}
              >
                <Text fz={20}>Contact Info</Text>
                <SingleField
                  title="Phone Number"
                  description={studentInfo.phoneNumber.join(", ")}
                />
                <Flex py={10} fw={700} fz={15} align="center">
                  <Text color="#B3B3B3" w="30%">
                    Address
                  </Text>
                  <Flex
                    w="60%"
                    justify="space-between"
                    align="center"
                    onClick={() => {
                      if(instituteDetails?.featureAccess.studentEditAccess){
                      setOpenEditAddress(true);
                      }
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <Text color="#383838">{studentInfo.address}</Text>
                    <Image
                      src={require("../../assets/editprofile.png")}
                      style={{
                        marginLeft: "15px",
                      }}
                      width={20}
                    />
                  </Flex>
                </Flex>
                <Modal
                  opened={openEditAddress}
                  onClose={() => setOpenEditAddress(false)}
                  title="Edit Address"
                >
                  <TextInput
                    label="Address"
                    placeholder="Enter your Address"
                    value={studentAddress}
                    defaultValue={studentAddress}
                    onChange={(event) =>
                      setStudentAddress(event.currentTarget.value)
                    }
                  />
                  <Group position="right" mt="md">
                    <Button onClick={handleEditStudentAddress}>
                      Save Details
                    </Button>
                  </Group>
                </Modal>
              </Stack>
              <Stack
                style={{
                  border: "1px solid #D3D3D3",
                  borderRadius: "10px",
                }}
                p={30}
                px={35}
              >
                <Text fz={20}>Other Info</Text>
                <EditStudentProfile
                  studentInfo={studentInfo}
                  onBack={() => getStudentnfo()}
                />
              </Stack>
            </Stack>
          )}
          {activeTab === ProfileTabs.REDEEMPRIZES &&
          props.userType !== "teacher" ? (
            <RedeemPrizesSection
              totalPoints={studentInfo.totalRewardpoints}
              redeemedPoints={studentInfo.redeemedRewardpoints}
              studentId={props.studentId}
              onRedeemClick={() => {
                getStudentnfo();
              }}
            />
          ) : (
            <RedeemedPrizes
              totalPoints={studentInfo.totalRewardpoints}
              redeemedPoints={studentInfo.redeemedRewardpoints}
              studentId={props.studentId}
              onRedeemClick={() => {
                getStudentnfo();
              }}
            />
          )}
          {activeTab === ProfileTabs.FEERECORDS && (
            <FeeRecordSection
              feeRecords={studentInfo?.feeRecords}
              userType={props.userType || ""}
              dateOfJoining={studentInfo?.dateOfJoining}
              batch={props?.batch}
              studentId={props.studentId}
              onPaymentClick={() => {
                console.log("refreshing page");
                
                getStudentnfo();
              }}
            />
          )}
          {activeTab === ProfileTabs.ATTENDANCE && (
            <Attendance
              dateOfJoining={studentInfo?.dateOfJoining}
              studentId={studentInfo?._id}
            />
          )}
          {activeTab === ProfileTabs.BOOKMARK && (
            <BookmarkQuestionsSection studentId={props.studentId} />
          )}
          {activeTab === ProfileTabs.BOOKMARK && (
            <BookmarkQuestionsSection studentId={props.studentId} />
          )}
        </Stack>
      )}
      <FileInput
        value={file}
        onChange={setFile}
        ref={fileInputRef}
        display="none"
      />
      <LoadingOverlay visible={isLoading} />
    </>
  );
}
