import {
  Box,
  Button,
  Center,
  Collapse,
  Divider,
  Flex,
  Image,
  LoadingOverlay,
  Menu,
  Modal,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { IconCheck, IconDotsVertical, IconPlus, IconX } from "@tabler/icons";
import React, { useEffect, useState } from "react";
import { AddVideoModal } from "./Modals/AddVideoModal";
import { AddNotesModal } from "./Modals/AddNotesModal";
import { AddTestModal } from "./Modals/AddTestModal";
import { IconBook } from "../_Icons/CustonIcons";
import { useMediaQuery } from "@mantine/hooks";
import {
  UpdateCourseVideoName,
  UpdateFileName,
  UpdateTestName,
  createCourseFile,
  createCourseFolder,
  createCoursevideo,
  getCourseFolderById,
  updateCourseFolderContent,
} from "../../features/course/courseSlice";
import { downloadPdf } from "../_New/Test/DownloadSamplePaper";
import { useSelector } from "react-redux";
import { User1 } from "../../@types/User";
import { RootState } from "../../store/ReduxStore";
import { Mixpanel } from "../../utilities/Mixpanel/MixpanelHelper";
import { WebAppEvents } from "../../utilities/Mixpanel/AnalyticeEventWebApp";
import useParentCommunication from "../../hooks/useParentCommunication";
import AddFolderModal from "./Modals/AddFolderModal";
import { showNotification } from "@mantine/notifications";
import {
  findFolderById,
  removeSubfileFromFolder,
  removeSubfolder,
  removeTestFromFolder,
  removeVideoFromFolder,
  updateFileNameInFolder,
  updateFolderInTree,
  updateTestNameInFolder,
  updateVideoNameInFolder,
} from "../../utilities/FolderHelperFunctions";

enum MenuItems {
  VIDEO = "Video",
  NOTES = "Notes",
  TEST = "Test",
  FOLDER = "Folder",
}
export function scrollToTopLeft() {
  const scrollArea = document.getElementById("your-scroll-area-id");

  if (scrollArea) {
    window.scrollTo(0, 0);
  }
}
function EditMenu(props: {
  fileOrFolderId?: string;
  onDeleteClick: () => void;
  onRenameClick: () => void;
  onAddContent: () => void;
  onDownloadClick?: () => void;
  setSelectedValue?: (val: string) => void;
}) {
  const isMd = useMediaQuery(`(max-width: 820px)`);
  return (
    <Menu>
      <Menu.Target>
        <Box>
          <IconDotsVertical />
        </Box>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item onClick={props.onRenameClick}>
          <Flex align={"center"} justify={"space-between"} p={2}>
            <Image
              src={require("../../assets/editFolder.png")}
              width={"24px"}
              height={"24px"}
            />
            <Text px={20}>
              Rename
              {(props.fileOrFolderId?.startsWith("CRSFLD") && " Folder") ||
                (props.fileOrFolderId?.startsWith("CF") && " File") ||
                (props.fileOrFolderId?.startsWith("CV") && " Video") ||
                (props.fileOrFolderId?.startsWith("TES") && " Test")}
            </Text>
          </Flex>
        </Menu.Item>
        <Stack display={isMd ? "block" : "none"}>
          {props.fileOrFolderId?.startsWith("CRSFLD") && (
            <>
              <Menu.Item
              
                icon={
                  <Image
                    src={require("../../assets/addVideo.png")}
                    width={"24px"}
                    height={"24px"}
                    sx={{ marginRight: '13px' }}
                  />
                }
                onClick={() => {
                  if (props.setSelectedValue) {
                    props.setSelectedValue(MenuItems.VIDEO);
                  }
                }}
              >
                {"Add "+MenuItems.VIDEO}
              </Menu.Item>
              <Menu.Item
                icon={
                  <Image
                    src={require("../../assets/addNotes.png")}
                    width={"24px"}
                    height={"24px"}
                    sx={{ marginRight: '13px' }}
                  />
                }
                onClick={() => {
                  if (props.setSelectedValue) {
                    props.setSelectedValue(MenuItems.NOTES);
                  }
                }}
              >
                {"Add "+MenuItems.NOTES}
              </Menu.Item>
              <Menu.Item
                icon={
                  <Image
                    src={require("../../assets/addFolder.png")}
                    width={"24px"}
                    height={"24px"}
                    sx={{ marginRight: '13px' }}
                  />
                }
                onClick={() => {
                  if (props.setSelectedValue) {
                    props.setSelectedValue(MenuItems.FOLDER);
                  }
                }}
              >
                {"Add "+MenuItems.FOLDER}
              </Menu.Item>
              <Menu.Item
                icon={
                  <Image
                    src={require("../../assets/addTests.png")}
                    width={"24px"}
                    height={"24px"}
                    sx={{ marginRight: '13px' }}
                  />
                }
                onClick={() => {
                  if (props.setSelectedValue) {
                    props.setSelectedValue(MenuItems.TEST);
                  }
                }}
              >
                {"Add "+MenuItems.TEST}
              </Menu.Item>
            </>
          )}
        </Stack>
        <Stack display={isMd ? "none" : "block"}>
        {props.fileOrFolderId?.startsWith("CRSFLD") && (
          <Menu.Item onClick={(e) => e.stopPropagation()}>
            <Flex>
              <Menu
                trigger="hover"
                width="250px"
                position={isMd ? "top" : "bottom"}
              >
                <Menu.Target>
                  {props.fileOrFolderId?.startsWith("CRSFLD") ? (
                    <Flex align={"center"} justify={"start"} p={2}>
                      <Image
                        src={require("../../assets/addFolder.png")}
                        width={"24px"}
                        height={"24px"}
                      />
                      <Text px={20}>Add Content</Text>
                    </Flex>
                  ) : (
                    <div />
                  )}
                </Menu.Target>

                <Menu.Dropdown ml={30}>
                  <MenuItem
                    val={MenuItems.VIDEO}
                    onClick={() => {
                      if (props.setSelectedValue) {
                        props.setSelectedValue(MenuItems.VIDEO);
                      }
                    }}
                  />
                  <MenuItem
                    val={MenuItems.NOTES}
                    onClick={() => {
                      if (props.setSelectedValue) {
                        props.setSelectedValue(MenuItems.NOTES);
                      }
                    }}
                  />
                  <MenuItem
                    val={MenuItems.FOLDER}
                    onClick={() => {
                      if (props.setSelectedValue) {
                        props.setSelectedValue(MenuItems.FOLDER);
                      }
                    }}
                  />
                  <MenuItem
                    val={MenuItems.TEST}
                    onClick={() => {
                      if (props.setSelectedValue) {
                        props.setSelectedValue(MenuItems.TEST);
                      }
                    }}
                  />
                </Menu.Dropdown>
              </Menu>
            </Flex>
          </Menu.Item>
        )}
        </Stack>
        <Menu.Item onClick={props.onDeleteClick}>
          <Flex align={"center"} justify={"space-between"} p={2}>
            <Image
              src={require("../../assets/deleteIcon.png")}
              width={"24px"}
              height={"24px"}
            />
            <Text px={20}>
              Remove
              {(props.fileOrFolderId?.startsWith("CRSFLD") && " Folder") ||
                (props.fileOrFolderId?.startsWith("CF") && " File") ||
                (props.fileOrFolderId?.startsWith("CV") && " Video") ||
                (props.fileOrFolderId?.startsWith("TES") && " Test")}
            </Text>
          </Flex>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
function MenuItem(props: { val: string; onClick: () => void }) {
  return (
    <Menu.Item
      onClick={props.onClick}
      style={{
        borderBottom: "1px solid #ECECEC",
        borderRadius: "0px",
      }}
    >
      <Text ta="center" fz={16} fw={700}>
        {props.val}
      </Text>
    </Menu.Item>
  );
}

export function NameEditor(props: {
  fileName: string;
  setOnRenameClicked: (val: boolean) => void;
  onRenameClick: (val: string) => void;
}) {
  const [value, setValue] = useState<string>(props.fileName);
  return (
    <Flex
      align="center"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <TextInput
        value={value}
        onChange={(e) => {
          setValue(e.currentTarget.value);
        }}
        styles={{
          input: {
            fontSize: "22px",
            background: "transparent",
            border: "none",
            borderBottom: "1px solid black",
            borderRadius: "0px",
            "&:focus-within": {
              borderBottom: "1px solid black",
            },
          },
        }}
      />
      <IconCheck
        onClick={() => {
          if (!value) {
            showNotification({
              message: "Name is required!!",
            });
            return;
          }
          props.setOnRenameClicked(false);
          props.onRenameClick(value);
        }}
        style={{
          cursor: "pointer",
          width: "10vh",
        }}
      />
      <IconX
        onClick={() => {
          props.setOnRenameClicked(false);
        }}
        style={{
          cursor: "pointer",
          width: "10vh",
        }}
      />
    </Flex>
  );
}

function SingleVideoCard(props: {
  fileName: string;
  folderId?: string;
  fileId?: string;
  nestedVideo: boolean;
  nextVideoPadding?: number;
  description: string;
  onRenameClick: (val: string, folderId?: string, fileId?: string) => void;
  onDeleteClick: (folderId?: string, fileId?: string) => void;
}) {
  const [onRenameClick, setOnRenameClicked] = useState<boolean>(false);

  return (
    <Flex
      style={{
        border: props.nestedVideo ? "none" : "1px solid #CDCEE3",
        background: "#F7F7FF",
        borderRadius: props.nestedVideo ? "0px" : "11px",
      }}
      px={15}
      py={20}
      justify="space-between"
      align="center"
    >
      <Flex
        align="center"
        pl={props.nestedVideo ? props.nextVideoPadding!! + 1 + "rem" : "0px"}
      >
        <Stack mr={30}>
          <img src={require("../../assets/videocourse.png")} />
        </Stack>
        <Stack spacing={3}>
          {!onRenameClick && (
            <Text fz={16} fw={400}>
              {props.fileName}
            </Text>
          )}
          {onRenameClick && (
            <NameEditor
              fileName={props.fileName}
              setOnRenameClicked={setOnRenameClicked}
              onRenameClick={(name: string) => {
                props.onRenameClick(name, props.folderId, props.fileId);
              }}
            />
          )}
          <Text color="#898989" fw={500} fz={14}>
            {props.description}
          </Text>
        </Stack>
      </Flex>
      <Stack
        justify="center"
        style={{
          cursor: "pointer",
        }}
      >
        <EditMenu
          fileOrFolderId={props.fileId}
          onDeleteClick={() => {
            props.onDeleteClick(props.folderId, props.fileId);
          }}
          onRenameClick={() => {
            setOnRenameClicked(true);
          }}
          onAddContent={() => {}}
        />
      </Stack>
    </Flex>
  );
}
function SingleFileCard(props: {
  fileName: string;
  folderId?: string;
  fileId?: string;
  nestedFile: boolean;
  nextFilePadding?: number;
  onRenameClick: (val: string, folderId: string, fileId: string) => void;
  onDeleteClick: (folderId?: string, fileId?: string) => void;
}) {
  const [onRenameClick, setOnRenameClicked] = useState<boolean>(false);
  return (
    <Flex
      style={{
        border: props.nestedFile ? "none" : "1px solid #CDCEE3",
        background: "#F7F7FF",
        borderRadius: props.nestedFile ? "0px" : "11px",
      }}
      px={15}
      py={20}
      justify="space-between"
      align="center"
    >
      <Flex
        align="center"
        pl={props.nestedFile ? props.nextFilePadding!! + 1 + "rem" : "0px"}
      >
        <Stack mr={30}>
          <img src={require("../../assets/filelogo.png")} />
        </Stack>
        {!onRenameClick && (
          <Text fz={16} fw={400}>
            {props.fileName}
          </Text>
        )}
        {onRenameClick && (
          <NameEditor
            fileName={props.fileName}
            setOnRenameClicked={setOnRenameClicked}
            onRenameClick={(name: string) => {
              props.onRenameClick(name, props.folderId!!, props.fileId!!);
            }}
          />
        )}
      </Flex>
      <Stack
        justify="center"
        style={{
          cursor: "pointer",
        }}
      >
        <EditMenu
          fileOrFolderId={props.fileId}
          onDeleteClick={() =>
            props.onDeleteClick(props.folderId!!, props.fileId!!)
          }
          onRenameClick={() => {
            setOnRenameClicked(true);
          }}
          onAddContent={() => {}}
        />
      </Stack>
    </Flex>
  );
}
function SingleFolderCard(props: {
  folderName: string;
  nestedFolder: boolean;
  folderId: string;
  index?: number;
  nextFolderPadding?: number;
  prevFolder: CourseFolder;
  localCourseFolders?: CourseFolder[];
  currentFolder?: CourseFolder;
  onRenameClick: (folder: CourseFolder, folderId: string) => void;
  onDeleteClick: (folderId: string) => void;
  addContentClick: (folderId: string) => void;
  setSelectedValue?: (val: string, folderId: string) => void;
  onFolderCardClick: (parentCourseFolder: CourseFolder) => void;

  onFlieDeleteClick?: (folderId: string, fileId: string) => void;
  onFlieRenameClick?: (name: string, folderId: string, fileId: string) => void;
  onTestDeleteClick?: (folderId?: string, fileId?: string) => void;
  onTestRenameClick?: (
    name: string,
    folderId?: string,
    fileId?: string
  ) => void;
  onVideoDeleteClick?: (folderId?: string, fileId?: string) => void;
  onVideoRenameClick?: (name: string, folderId: string, fileId: string) => void;
}) {
  const [onRenameClick, setOnRenameClicked] = useState<boolean>(false);
  const [isCollapse, setIsCollapse] = useState<boolean>(false);
  const [selectedFolder, setSelectedFolder] = useState<CourseFolder | null>(
    null
  );
  const [prevFolder, setPrevdFolder] = useState<CourseFolder | null>(null);

  const [selectedFolderId, setSelectedFolderId] = useState<string>();

  const [nextFolderPadding, setNextFolderPadding] = useState<number>(
    props.nextFolderPadding || 0
  );

  return (
    <>
      <Flex
        style={{
          border: props.nestedFolder ? "none" : "1px solid #CDCEE3",
          background: "#F7F7FF",
          borderRadius: props.nestedFolder ? "0px" : "11px",
        }}
        px={15}
        py={20}
        justify="space-between"
        align="center"
      >
        <Flex w="100%" align="center">
          <Stack mr={30}>
            <Flex align={"center"}>
              <Stack
                pl={
                  props.nestedFolder
                    ? props.nextFolderPadding!! + 1 + "rem"
                    : "0px"
                }
              >
                {(props.currentFolder?.folders?.length!! > 0 ||
                  props.currentFolder?.files.length!! > 0 ||
                  props.currentFolder?.videos.length!! > 0 ||
                  props.currentFolder?.tests.length!! > 0) && (
                  <Stack>
                    {isCollapse ? (
                      <Image
                        src={require("../../assets/arrow-right.png")}
                        sx={{
                          cursor: "pointer",
                        }}
                        width={15}
                        mr={10}
                        onClick={() => {
                          setNextFolderPadding((prev) => prev - 1);
                          setIsCollapse(!isCollapse);
                        }}
                      />
                    ) : (
                      <Image
                        src={require("../../assets/arrow-down.png")}
                        sx={{
                          cursor: "pointer",
                        }}
                        width={15}
                        onClick={() => {
                          setNextFolderPadding((prev) => prev + 1);
                          setIsCollapse(!isCollapse);
                        }}
                        mr={10}
                      />
                    )}
                  </Stack>
                )}
              </Stack>
              <img
                src={require("../../assets/folder.png")}
                onClick={() => {
                  if (props.localCourseFolders && props.folderId) {
                    const currentFolder = findFolderById(
                      props.localCourseFolders,
                      props.folderId
                    );
                    setSelectedFolder(currentFolder);
                    props.onFolderCardClick(currentFolder!!);
                  }
                }}
                style={{
                  cursor: "pointer",
                }}
              />
            </Flex>
          </Stack>

          {!onRenameClick && (
            <Text
              fz={16}
              fw={400}
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                if (props.localCourseFolders && props.folderId) {
                  const currentFolder = findFolderById(
                    props.localCourseFolders,
                    props.folderId
                  );
                  setSelectedFolder(currentFolder);
                  props.onFolderCardClick(currentFolder!!);
                }
              }}
            >
              {props.folderName}
            </Text>
          )}
          {onRenameClick && (
            <NameEditor
              fileName={props.folderName}
              setOnRenameClicked={setOnRenameClicked}
              onRenameClick={(name: string) => {
                getCourseFolderById(props.folderId!!)
                  .then((x: any) => {
                    x.name = name;
                    props.onRenameClick(x, props.folderId!!);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            />
          )}
        </Flex>
        <Stack
          justify="center"
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            setSelectedFolderId(props.folderId);
          }}
        >
          <EditMenu
            fileOrFolderId={props.folderId}
            onDeleteClick={() => props.onDeleteClick(props.folderId!!)}
            onRenameClick={() => {
              setOnRenameClicked(true);
            }}
            onAddContent={() => {
              props.addContentClick(props.folderId);
            }}
            setSelectedValue={(val: string) => {
              if (props.setSelectedValue) {
                props.setSelectedValue(val, props.folderId);
              }
            }}
          />
        </Stack>
      </Flex>
      <Stack sx={{ display: isCollapse ? "block" : "hidden" }}>
        {isCollapse &&
          props.currentFolder?.folders &&
          props.currentFolder?.folders.length > 0 &&
          props.currentFolder?.folders.map((item, i) => (
            <>
              <SingleFolderCard
                index={i}
                currentFolder={item}
                nextFolderPadding={nextFolderPadding}
                folderName={item.name}
                localCourseFolders={props.localCourseFolders}
                setSelectedValue={props.setSelectedValue}
                onFolderCardClick={(parentCourseFolder: CourseFolder) => {
                  props.onFolderCardClick(parentCourseFolder);
                }}
                nestedFolder={true}
                folderId={item._id}
                prevFolder={props.prevFolder}
                onRenameClick={props.onRenameClick}
                onDeleteClick={(folderId: string) => {
                  if (props.onDeleteClick) {
                    props.onDeleteClick(folderId);
                  }
                }}
                addContentClick={props.addContentClick}
                onFlieDeleteClick={props.onFlieDeleteClick}
                onFlieRenameClick={props.onFlieRenameClick}
                onTestDeleteClick={props.onTestDeleteClick}
                onTestRenameClick={props.onTestRenameClick}
                onVideoDeleteClick={props.onVideoDeleteClick}
                onVideoRenameClick={props.onVideoRenameClick}
              />
            </>
          ))}
      </Stack>
      <Stack sx={{ display: isCollapse ? "block" : "hidden" }}>
        {isCollapse &&
          props.currentFolder?.files &&
          props.currentFolder?.files.length > 0 &&
          props.currentFolder?.files.map((item: CourseFile, i) => (
            <>
              <SingleFileCard
                fileName={item?.name}
                folderId={props.currentFolder?._id!!}
                fileId={item._id}
                nextFilePadding={nextFolderPadding}
                nestedFile={true}
                onDeleteClick={(folderId?: string, fileId?: string) => {
                  if (props.onFlieDeleteClick) {
                    props.onFlieDeleteClick(folderId!!, fileId!!);
                  }
                }}
                onRenameClick={(
                  name: string,
                  folderId: string,
                  fileId: string
                ) => {
                  if (props.onFlieRenameClick) {
                    props.onFlieRenameClick(name, folderId, fileId);
                  }
                }}
              />
            </>
          ))}
      </Stack>
      <Stack sx={{ display: isCollapse ? "block" : "hidden" }}>
        {isCollapse &&
          props.currentFolder?.videos &&
          props.currentFolder?.videos.length > 0 &&
          props.currentFolder?.videos.map((item, i) => (
            <>
              <SingleVideoCard
                fileName={item.name}
                folderId={props.currentFolder?._id}
                fileId={item._id}
                nextVideoPadding={nextFolderPadding}
                nestedVideo={true}
                description={item.description}
                onRenameClick={(
                  name: string,
                  folderId?: string,
                  fileId?: string
                ) => {
                  if (props.onVideoRenameClick) {
                    props.onVideoRenameClick(name, folderId!!, fileId!!);
                  }
                }}
                onDeleteClick={(folderId?: string, fileId?: string) => {
                  if (props.onVideoDeleteClick) {
                    props.onVideoDeleteClick(folderId!!, fileId!!);
                  }
                }}
              />
            </>
          ))}
      </Stack>
      <Stack sx={{ display: isCollapse ? "block" : "hidden" }}>
        {isCollapse &&
          props.currentFolder?.tests &&
          props.currentFolder?.tests.length > 0 &&
          props.currentFolder?.tests.map((item, i) => (
            <>
              <SingleTestCard
                test={item}
                folderId={props.currentFolder?._id}
                fileId={item?._id}
                nextTestPadding={nextFolderPadding}
                nestedTest={true}
                onRenameClick={(
                  name: string,
                  folderId?: string,
                  fileId?: string
                ) => {
                  if (props.onTestRenameClick)
                    props.onTestRenameClick(name, folderId!!, fileId!!);
                }}
                onDeleteClick={(folderId?: string, fileId?: string) => {
                  if (props.onTestDeleteClick) {
                    props.onTestDeleteClick(folderId!!, fileId!!);
                  }
                }}
              />
            </>
          ))}
      </Stack>
    </>
  );
}

function SingleTestCard(props: {
  folderId?: string;
  fileId?: string;
  test: any;
  nestedTest: boolean;
  nextTestPadding?: number;
  onRenameClick: (val: string, folderId?: string, fileId?: string) => void;
  onDeleteClick: (folderId?: string, fileId?: string) => void;
}) {
  const isMd = useMediaQuery(`(max-width: 820px)`);
  const [onRenameClick, setOnRenameClicked] = useState<boolean>(false);
  const [isLoading, setLaoding] = useState<boolean>(false);
  const user = useSelector<RootState, User1 | null>((state) => {
    return state.currentUser.user;
  });
  const { sendDataToReactnative, isReactNativeActive } =
    useParentCommunication();
  return (
    <Flex
      style={{
        border: props.nestedTest ? "none" : "1px solid #CDCEE3",
        background: "#F7F7FF",
        borderRadius: props.nestedTest ? "none" : "11px",
      }}
      px={15}
      py={15}
      justify="space-between"
    >
      <Flex>
        <Stack
          justify="center"
          pl={props.nestedTest ? props.nextTestPadding!! + 1 + "rem" : "0px"}
        >
          <img src={require("../../assets/testcard.png")} />
        </Stack>
        <Stack spacing={2} ml={20}>
          {/* First Row */}
          <Flex style={{ alignItems: "center" }}>
            <IconBook col="#3174F3" />
          </Flex>

          {!onRenameClick && (
            <Text fz={isMd ? 18 : 20} fw={500}>
              {props.test?.name}
            </Text>
          )}
          {onRenameClick && (
            <NameEditor
              fileName={props.test.name}
              setOnRenameClicked={setOnRenameClicked}
              onRenameClick={(
                name: string,
                folderId?: string,
                fileId?: string
              ) => props.onRenameClick(name, props.folderId, props.fileId)}
            />
          )}

          <Flex align={"center"}>
            <Text w={"100%"} fz={isMd ? 11 : 12} fw={500} c={"#898989"}>
              Total Marks: {props.test?.maxMarks} | Total Questions:{" "}
              {props.test.maxQuestions}
            </Text>
            <Flex>
              <Flex
                style={{ width: "60px", justifyContent: "space-evenly" }}
              ></Flex>
            </Flex>
          </Flex>
        </Stack>
      </Flex>
      <Stack
        style={{
          cursor: "pointer",
        }}
      >
        <EditMenu
          fileOrFolderId={props.fileId}
          onDeleteClick={() => {
            props.onDeleteClick(props.folderId, props.fileId);
          }}
          onRenameClick={() => {
            setOnRenameClicked(true);
          }}
          onAddContent={() => {}}
          onDownloadClick={() => {
            downloadPdf(
              props.test._id,
              setLaoding,
              user,
              isReactNativeActive(),
              sendDataToReactnative,
              false,
              false,
              false,
              null
            );
          }}
        />
      </Stack>
    </Flex>
  );
}
export function AddContentForCourses(props: {
  setFileIds: (val: string[]) => void;
  setVideoIds: (val: string[]) => void;
  setTestids: (val: string[]) => void;
  setFolderIds: (val: string[]) => void;
  setNextBtnDisabled: (val: boolean) => void;
  setSelectedFolder: (courseFolder: CourseFolder | null) => void;
  onClickFolderBack?: (parentFolder: CourseFolder) => void;
  isTestSeries: boolean;
  videos: CourseVideo[];
  folders: CourseFolder[];
  prevFolder?: CourseFolder;
  files: CourseFile[];
  tests: TestData[];
  setVideos: (val: any) => void;
  setFiles: (val: any) => void;
  setFolders: (val: any) => void;
  setTest: (val: any) => void;
  isCourseEdit: boolean;
  parentFolder?: CourseFolder;
  courseFolders: CourseFolder[];
  isLoading?: boolean;
  setCourseFolders: (val: React.SetStateAction<CourseFolder[]>) => void;
  setParentFolder?: (val: CourseFolder | null) => void;
}) {
  const [selectedvalue, setSelectedValue] = useState<MenuItems | null>(null);
  const [state, setState] = useState<number>(0);
  const theme = useMantineTheme();
  const isMd = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [parentFolderId, setParentFolderId] = useState<string>("");
  const [addContentButton, setAddContentButton] = useState<boolean>(false);
  const [trackPath, setTrackPath] = useState<Map<string, string>>(
    new Map([["1", "Contents"]])
  );
  const [courseFolders, setCourseFolders] = useState<CourseFolder[]>([]);
  const [selectedFolder, setSelectedFolder] = useState<CourseFolder | null>(
    null
  );
  const [path, setPath] = useState<string[]>(["Contents"]);
  const [directPatchFetchedOnce, setDirectPatchFetchedOnce] =
    useState<boolean>(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState<boolean>(false);
  const [deleteFolder, setDeleteFolder] = useState<CourseFolder | null>(null);
  const [deleteFolderId, setDeleteFolderId] = useState<string>();

  useEffect(() => {
    setisLoading(false);
    setCourseFolders(props.folders);
    props.setNextBtnDisabled(false);
  }, [props.folders]);

  useEffect(() => {
    scrollToTopLeft();
  }, []);
  useEffect(() => {
    setisLoading(props.isLoading!!);
  }, [props.isLoading]);
  useEffect(() => {
    setParentFolderId(props.parentFolder?._id || "");
  }, [props.parentFolder]);
  useEffect(() => {
    if (
      props.parentFolder?.name !== undefined &&
      !path.includes(` > ${props.parentFolder?.name!}`)
    ) {
      setPath((prev) => [...prev, ` > ${props.parentFolder?.name}`]);
    }
    if (
      props.parentFolder?.name !== undefined &&
      props.parentFolder?._id !== undefined &&
      !trackPath.has(props.parentFolder?._id)
    ) {
      if (!directPatchFetchedOnce) {
        setDirectPatchFetchedOnce(true);
        handleRandomFolderSelection(
          props.parentFolder?._id!!,
          props.courseFolders!!
        );
      } else {
        setTrackPath((prev) => {
          const newMap = new Map(prev);
          newMap.set(
            props.parentFolder?._id!!,
            ` > ${props.parentFolder?.name!!}`
          );
          return newMap;
        });
      }
    }
  }, [props.parentFolder, props.courseFolders]);

  useEffect(() => {
    const testIds = props.tests.map((x) => x._id);
    props.setTestids(testIds);
  }, [props.tests]);

  useEffect(() => {
    const videoIds = props.videos.map((x) => x._id);
    props.setVideoIds(videoIds);
  }, [props.videos]);

  useEffect(() => {
    const fileIds = props.files.map((x) => x._id);
    props.setFileIds(fileIds);
  }, [props.files]);
  useEffect(() => {
    const folderIds = props.folders.map((x) => x._id);
    props.setFolderIds(folderIds);
  }, [props.folders]);

  function addNoteshandler(name: string, url: string) {
    setisLoading(true);
    setSelectedValue(null);
    createCourseFile({ name, url })
      .then((x: any) => {
        setisLoading(false);
        Mixpanel.track(WebAppEvents.COURSE_NOTES_ADDED, {
          type: props.isCourseEdit ? "edit" : "new",
        });
        if (props.parentFolder == null && !parentFolderId) {
          props.setFiles((prev: any) => [...prev, x]);
          setisLoading(false);
        } else {
          if (props.parentFolder && addContentButton) {
            const currentFolder = findFolderById(props.folders, parentFolderId);
            props.setNextBtnDisabled(false);
            const newselectedFolder = props.parentFolder;
            newselectedFolder?.files.push(x);
            props.setFolders((prevFolders: any) => {
              return updateFolderInTree(prevFolders, newselectedFolder!!);
            });
            setisLoading(false);
          } else {
            setAddContentButton(false);
            const currentFolder = findFolderById(props.folders, parentFolderId);

            if (currentFolder) {
              setTotalData(1);
              currentFolder?.files.push(x);
              props.setFolders((prevFolders: any) => {
                return updateFolderInTree(prevFolders, currentFolder);
              });
            } else {
              const newFolder = props.parentFolder;

              newFolder?.files.push(x);
              if (props.setSelectedFolder) {
                props.setSelectedFolder(newFolder!!);
              }
              props.setFolders((prevFolders: any) => {
                return updateFolderInTree(prevFolders, newFolder!!);
              });
              setTotalData(1);
            }
            updateCourseFolderContent({
              folderId: parentFolderId ? parentFolderId : currentFolder?._id!!,
              parentFolder: currentFolder
                ? currentFolder
                : props.parentFolder!!,
            })
              .then((x: any) => {
                props.setNextBtnDisabled(false);
              })
              .catch((e) => {
                console.log(e);
              });
          }
        }
        setParentFolderId("");
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function addFolderhandler(name: string) {
    setSelectedValue(null);
    const createdAt = new Date(Date.now());

    createCourseFolder({ name, createdAt, parentFolderId })
      .then((x: any) => {
        //mixpanel event need to be added
        showNotification({
          message: "Folder created successfully!!",
        });

        if (props.parentFolder == null && !parentFolderId) {
          props.setFolders((prev: any) => [...prev, x]);
          props.setCourseFolders((prev: any) => [...prev, x]);
        } else {
          if (props.parentFolder && addContentButton) {
            props.setNextBtnDisabled(false);
            const newselectedFolder = props.parentFolder;
            newselectedFolder?.folders.push(x);
            props.setFolders((prevFolders: any) => {
              return updateFolderInTree(prevFolders, newselectedFolder!!);
            });
          } else {
            setAddContentButton(false);
            const currentFolder = findFolderById(props.folders, parentFolderId);
            if (currentFolder) {
              setTotalData(1);
              currentFolder?.folders.push(x);
              props.setFolders((prevFolders: any) => {
                return updateFolderInTree(prevFolders, currentFolder);
              });
            } else {
              const newFolder = props.parentFolder;
              newFolder?.folders.push(x);
              if (props.setSelectedFolder) {
                props.setSelectedFolder(newFolder!!);
              }
              props.setFolders((prevFolders: any) => {
                return updateFolderInTree(prevFolders, newFolder!!);
              });
              setTotalData(1);
            }
            updateCourseFolderContent({
              folderId: parentFolderId
                ? parentFolderId
                : props.parentFolder?._id!!,
              parentFolder: currentFolder
                ? currentFolder
                : props.parentFolder!!,
            })
              .then((x: any) => {
                props.setNextBtnDisabled(false);
              })
              .catch((e) => {
                console.log(e);
              });
          }
        }
        setParentFolderId("");
      })
      .catch((e) => {
        console.log(e);
      });
  }
  function addTestHanlder(test: any) {
    setSelectedValue(null);
    if (props.isTestSeries) {
      // it is my implemtnato
      props.setTest((prev: any) => [...prev, ...test]);

      Mixpanel.track(WebAppEvents.TEST_SERIES_TEST_ADDED, {
        type: props.isCourseEdit ? "edit" : "new",
      });
    } else {
      Mixpanel.track(WebAppEvents.COURSE_TEST_ADDED, {
        type: props.isCourseEdit ? "edit" : "new",
      });
    }
    if (props.parentFolder == null && !parentFolderId) {
      props.setTest((prev: any) => [...prev, ...test]);
    } else {
      if (props.parentFolder && addContentButton) {
        props.setNextBtnDisabled(false);
        const newselectedFolder = props.parentFolder;
        newselectedFolder!!.tests = [...newselectedFolder?.tests, ...test!!];
        props.setFolders((prevFolders: any) => {
          return updateFolderInTree(prevFolders, newselectedFolder!!);
        });
      } else {
        setAddContentButton(false);
        const currentFolder = findFolderById(props.folders, parentFolderId);
        if (currentFolder) {
          setTotalData(1);
          currentFolder!!.tests = [...currentFolder!!.tests, ...test];
          props.setFolders((prevFolders: any) => {
            return updateFolderInTree(prevFolders, currentFolder);
          });
        } else {
          const newFolder = props.parentFolder;
          newFolder!!.tests = [...newFolder!!.tests, ...test];
          if (props.setSelectedFolder) {
            props.setSelectedFolder(newFolder!!);
          }
          props.setFolders((prevFolders: any) => {
            return updateFolderInTree(prevFolders, newFolder!!);
          });
          setTotalData(1);
        }
        const folderID = parentFolderId
          ? parentFolderId
          : props.parentFolder?._id!!;
        updateCourseFolderContent({
          folderId: folderID,
          parentFolder: currentFolder ? currentFolder : props.parentFolder!!,
        })
          .then((x: any) => {
            props.setNextBtnDisabled(false);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  }

  function addVideoshandler(name: string, description: string, url: string) {
    setSelectedValue(null);
    createCoursevideo({ name, url, description })
      .then((x: any) => {
        Mixpanel.track(WebAppEvents.COURSE_VIDEO_ADDED, {
          type: props.isCourseEdit ? "edit" : "new",
        });

        if (props.parentFolder == null && !parentFolderId) {
          props.setVideos((prev: any) => [...prev, x]);
        } else {
          if (props.parentFolder && addContentButton) {
            props.setNextBtnDisabled(false);
            const newselectedFolder = props.parentFolder;
            newselectedFolder?.videos.push(x);
            props.setFolders((prevFolders: any) => {
              return updateFolderInTree(prevFolders, newselectedFolder!!);
            });
          } else {
            setAddContentButton(false);
            const currentFolder = findFolderById(props.folders, parentFolderId);
            if (currentFolder) {
              setTotalData(1);
              currentFolder?.videos.push(x);
              props.setFolders((prevFolders: any) => {
                return updateFolderInTree(prevFolders, currentFolder);
              });
            } else {
              const newFolder = props.parentFolder;
              newFolder?.videos.push(x);
              if (props.setSelectedFolder) {
                props.setSelectedFolder(newFolder!!);
              }
              props.setFolders((prevFolders: any) => {
                return updateFolderInTree(prevFolders, newFolder!!);
              });
              setTotalData(1);
            }
            updateCourseFolderContent({
              folderId: parentFolderId ? parentFolderId : currentFolder?._id!!,
              parentFolder: currentFolder
                ? currentFolder
                : props.parentFolder!!,
            })
              .then((x: any) => {
                props.setNextBtnDisabled(false);
              })
              .catch((e) => {
                console.log(e);
              });
          }
          setParentFolderId("");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  const [totalData, setTotalData] = useState<number>(
    props.files.length +
      props.tests.length +
      props.videos.length +
      props.folders.length
  );

  useEffect(() => {
    if (props.parentFolder != null) {
      props.parentFolder.files = props.files;
      props.parentFolder.videos = props.videos;
      props.parentFolder.tests = props.tests;
      props.parentFolder.folders = props.folders;
    }
    setTotalData(
      props.files.length +
        props.tests.length +
        props.videos.length +
        props.folders.length
    );
  }, [props.files, props.folders, props.videos, props.tests]);

  const findFolderWithParent = (
    folders: CourseFolder[],
    targetId: string,
    parent: CourseFolder | null = null
  ): { folder: CourseFolder | null; parent: CourseFolder | null } => {
    for (let folder of folders) {
      if (folder._id === targetId) {
        return { folder, parent };
      }
      if (folder.folders.length > 0) {
        const result = findFolderWithParent(folder.folders, targetId, folder);
        if (result.folder) return result;
      }
    }
    return { folder: null, parent: null };
  };

  const handleBack = (currentFolderId: string) => {
    const { folder, parent } = findFolderWithParent(
      props.courseFolders,
      currentFolderId
    );
    if (parent) {
      return parent;
    } else {
      return null;
    }
  };

  const findFolderAndPath = (
    folders: CourseFolder[],
    targetId: string,
    pathMap: Map<string, string>,
    currentPath: string[] = []
  ): string[] | null => {
    for (let folder of folders) {
      pathMap.set(folder._id, ` > ${folder.name}`);
      const newPath = [...currentPath, folder.name];

      if (folder._id === targetId) {
        if (props.setParentFolder) {
          props.setParentFolder(folder);
        }
        return newPath;
      }

      if (folder.folders.length > 0) {
        const resultPath = findFolderAndPath(
          folder.folders,
          targetId,
          pathMap,
          newPath
        );
        if (resultPath) {
          return resultPath;
        }
      }

      pathMap.delete(folder._id);
    }
    return null;
  };

  const handleRandomFolderSelection = (
    folderId: string,
    courseFolder?: CourseFolder[]
  ) => {
    var folders: CourseFolder[];
    folders = [...courseFolder!!];
    const newPathMap = new Map<string, string>([["1", "Contents"]]);

    const resultPath = findFolderAndPath(folders, folderId, newPathMap);

    if (resultPath) {
      setTrackPath(newPathMap);
    } else {
      if (props.setParentFolder) {
        props.setParentFolder(folders[0]);
        props.setSelectedFolder(null);
      }
      setTrackPath(new Map([["1", "Contents"]]));
    }
  };

  const deleteFolderAfterConfirmation = (id?: string) => {
    if (!deleteFolder) {
      console.log("delete folder : ",deleteFolder);
      
      // when i am inside a folder and want to remove root of present folder
      if (props.parentFolder) {
        setTotalData(1);
        props.setNextBtnDisabled(false);
        const updatedFolder = removeSubfolder(props.parentFolder!!, id?id:deleteFolderId!!);
        if (props.setParentFolder) {
          props.setParentFolder(updatedFolder!!);
        }
        props.setFolders((prevFolders: CourseFolder[]) => {
          return updateFolderInTree(prevFolders, updatedFolder);
        });
        updateCourseFolderContent({
          folderId: updatedFolder._id!!,
          parentFolder: updatedFolder!!,
        })
          .then((x: any) => {
            props.setNextBtnDisabled(false);
          })
          .catch((e) => {
            console.log(e);
          });

          // change path by back 1
          // if (props.onClickFolderBack) {
          //   setPath((prev) => {
          //     const arr = [...prev];
          //     arr.pop();
          //     return arr;
          //   });
          //   setTrackPath((prevMap) => {
          //     const newMap = new Map(prevMap);
          //     const keys = Array.from(newMap.keys());
          //     if (keys.length > 0) {
          //       const lastKey = keys[keys.length - 1];
          //       newMap.delete(lastKey);
          //     }
          //     return newMap;
          //   });
          //   const parentFolder = handleBack(
          //     props.parentFolder?._id!!
          //   );
          //   props.onClickFolderBack(parentFolder!!);
          // }

        showNotification({
          message: "Folder removed!!",
        });
      }
      props.setFolders((prevFolders: CourseFolder[]) => {
        const filterId = id ? id : deleteFolderId;
        const filterData = prevFolders.filter((f) => f._id !== filterId);
        return filterData;
      });
    } else {
      const { parent } = findFolderWithParent(courseFolders, deleteFolderId!!);
      const updatedFolder = removeSubfolder(parent!!, deleteFolderId!!);
      props.setFolders((prevFolders: CourseFolder[]) => {
        return updateFolderInTree(prevFolders, updatedFolder);
      });
      setCourseFolders((prevFolders: CourseFolder[]) => {
        return updateFolderInTree(prevFolders, updatedFolder);
      });
      updateCourseFolderContent({
        folderId: updatedFolder._id!!,
        parentFolder: updatedFolder!!,
      })
        .then((x: any) => {
          props.setNextBtnDisabled(false);
        })
        .catch((e) => {
          console.log(e);
        });

      showNotification({
        message: "Folder removed!!",
      });
    }
    setDeleteFolder(null);
    setDeleteFolderId("");
  };

  return (
    <>
      <LoadingOverlay visible={isLoading} />
      {totalData <= 0 && (
        <>
          <Stack w={"100%"}>
            {trackPath.size > 1 && (
              <Text
                ml={isMd ? 20 : 100}
                fz={18}
                align="start"
                fw={700}
                color="#404040"
              >
                {props.isTestSeries
                  ? "Test"
                  : Array.from(trackPath.entries()).map(([key, value]) => (
                      <span
                        key={key}
                        onClick={() =>
                          handleRandomFolderSelection(
                            key,
                            props.courseFolders!!
                          )
                        }
                        style={{ cursor: "pointer", marginBottom: "5px" }}
                      >
                        {value}
                      </span>
                    ))}
              </Text>
            )}
            {props.parentFolder && (
              <Flex align={"start"} ml={isMd ? 20 : 100}>
                <Image
                  src={require("../../assets/backFolder.png")}
                  sx={{
                    cursor: "pointer",
                  }}
                  width={32}
                  height={32}
                  onClick={() => {
                    if (props.onClickFolderBack) {
                      setPath((prev) => {
                        const arr = [...prev];
                        arr.pop();
                        return arr;
                      });
                      setTrackPath((prevMap) => {
                        const newMap = new Map(prevMap);
                        const keys = Array.from(newMap.keys());
                        if (keys.length > 0) {
                          const lastKey = keys[keys.length - 1];
                          newMap.delete(lastKey);
                        }
                        return newMap;
                      });
                      const parentFolder = handleBack(
                        props.parentFolder?._id!!
                      );
                      props.onClickFolderBack(parentFolder!!);
                    }
                  }}
                  mr={10}
                />

                <Text fz={18} fw={700} color="#404040">
                  {props.parentFolder?.name}
                </Text>
              </Flex>
            )}
            <Stack h="60vh">
              <Center w="100%" h="100%">
                <Stack>
                  <img
                    src={require("./../../assets/emptyCourses.png")}
                    width="70%"
                    style={{
                      margin: "0 auto",
                    }}
                  />
                  <Menu width="250px" position={isMd ? "top" : "bottom"}>
                    <Menu.Target>
                      <Button
                        leftIcon={<IconPlus />}
                        bg="white"
                        style={{
                          boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
                          color: "black",
                          borderRadius: "10px",
                        }}
                        color="black"
                        size={isMd ? "md" : "lg"}
                        sx={{
                          "&:hover": {
                            background: "white",
                          },
                        }}
                        px={isMd ? 5 : 60}
                        onClick={() => {
                          if (props.isTestSeries) {
                            setSelectedValue(MenuItems.TEST);
                          }
                        }}
                      >
                        Add {props.isTestSeries ? "Test" : "Content"}
                      </Button>
                    </Menu.Target>
                    {!props.isTestSeries && (
                      <Menu.Dropdown>
                        <MenuItem
                          val={MenuItems.FOLDER}
                          onClick={() => {
                            setSelectedValue(MenuItems.FOLDER);
                          }}
                        />
                        <MenuItem
                          val={MenuItems.VIDEO}
                          onClick={() => {
                            setSelectedValue(MenuItems.VIDEO);
                          }}
                        />

                        <MenuItem
                          val={MenuItems.NOTES}
                          onClick={() => {
                            setSelectedValue(MenuItems.NOTES);
                          }}
                        />
                        <MenuItem
                          val={MenuItems.TEST}
                          onClick={() => {
                            setSelectedValue(MenuItems.TEST);
                          }}
                        />
                      </Menu.Dropdown>
                    )}
                  </Menu>
                </Stack>
              </Center>
            </Stack>
          </Stack>
        </>
      )}

      {totalData > 0 && (
        <Stack px={isMd ? 30 : 70} w={isMd ? "100%" : "60%"}>
          <Text fz={18} fw={700} color="#404040">
            {props.isTestSeries
              ? "Test"
              : Array.from(trackPath.entries()).map(([key, value]) => (
                  <span
                    key={key}
                    onClick={() =>
                      handleRandomFolderSelection(key, props.courseFolders!!)
                    }
                    style={{ cursor: "pointer", marginBottom: "5px" }}
                  >
                    {value}
                  </span>
                ))}
          </Text>
          {props.parentFolder && (
            <Flex>
              <Image
                src={require("../../assets/backFolder.png")}
                sx={{
                  cursor: "pointer",
                }}
                width={32}
                height={32}
                onClick={() => {
                  if (props.onClickFolderBack) {
                    setPath((prev) => {
                      const arr = [...prev];
                      arr.pop();
                      return arr;
                    });
                    setTrackPath((prevMap) => {
                      const newMap = new Map(prevMap);
                      const keys = Array.from(newMap.keys());
                      if (keys.length > 0) {
                        const lastKey = keys[keys.length - 1];
                        newMap.delete(lastKey);
                      }
                      return newMap;
                    });
                    const parentFolder = handleBack(props.parentFolder?._id!!);
                    props.onClickFolderBack(parentFolder!!);
                  }
                }}
                mr={10}
              />

              <Text fz={18} fw={700} color="#404040">
                {props.parentFolder?.name}
              </Text>
            </Flex>
          )}
          {courseFolders.map((x, i) => {
            return (
              <div
                onClick={() => {
                  setState((val) => val + 1);
                }}
              >
                <SingleFolderCard
                  folderName={x.name}
                  nestedFolder={false}
                  localCourseFolders={props.folders}
                  currentFolder={x}
                  folderId={x._id}
                  prevFolder={x}
                  onFolderCardClick={(courseFolder: CourseFolder) => {
                    props.setSelectedFolder(courseFolder);
                  }}
                  onDeleteClick={(folderId: string) => {
                    const currentFolder = findFolderById(
                      courseFolders,
                      folderId
                    );

                    const { parent } = findFolderWithParent(
                      props.folders,
                      folderId!!
                    );
                    if (
                      currentFolder?.folders.length!! > 0 ||
                      currentFolder?.files.length!! > 0 ||
                      currentFolder?.videos.length!! > 0 ||
                      currentFolder?.tests.length!! > 0
                    ) {
                      setShowDeleteWarning(true);
                      setDeleteFolder(parent!!);
                      setDeleteFolderId(folderId!!);
                    } else {
                      if (!parent) {
                        setDeleteFolderId(currentFolder?._id!!);
                        setDeleteFolder(props.parentFolder!!);
                        deleteFolderAfterConfirmation(currentFolder?._id!!);
                        return;
                      }
                      const updatedFolder = removeSubfolder(
                        parent!!,
                        folderId!!
                      );
                      props.setFolders((prevFolders: CourseFolder[]) => {
                        return updateFolderInTree(prevFolders, updatedFolder);
                      });
                      setCourseFolders((prevFolders: CourseFolder[]) => {
                        return updateFolderInTree(prevFolders, updatedFolder);
                      });
                      showNotification({
                        message: "Folder removed!!",
                      });

                      updateCourseFolderContent({
                        folderId: folderId!!,
                        parentFolder: updatedFolder!!,
                      })
                        .then((x: any) => {
                          props.setNextBtnDisabled(false);
                        })
                        .catch((e) => {
                          console.log(e);
                        });
                    }
                  }}
                  onRenameClick={(courseFolder, folderId) => {
                    updateCourseFolderContent({
                      folderId: folderId,
                      parentFolder: courseFolder,
                    })
                      .then((x: any) => {
                        props.setNextBtnDisabled(false);

                        setCourseFolders((prevFolders) => {
                          return updateFolderInTree(prevFolders, courseFolder);
                        });
                        props.setFolders((prevFolders: any) => {
                          return updateFolderInTree(prevFolders, courseFolder);
                        });
                      })
                      .catch((err) => {});
                  }}
                  addContentClick={(folderId: string) => {
                    setAddContentButton(true);
                    setParentFolderId(folderId);
                  }}
                  setSelectedValue={(val: string, folderId: string) => {
                    if (Object.values(MenuItems).includes(val as MenuItems)) {
                      setSelectedValue(val as MenuItems);
                      setParentFolderId(folderId);
                    }
                  }}
                  onFlieDeleteClick={(folderId: string, fileId: string) => {
                    const currentFolder = findFolderById(
                      props.folders,
                      folderId
                    );
                    const updatedFolder = removeSubfileFromFolder(
                      currentFolder!!,
                      fileId!!
                    );
                    updateCourseFolderContent({
                      folderId,
                      parentFolder: updatedFolder!!,
                    })
                      .then((x: any) => {
                        props.setNextBtnDisabled(false);
                      })
                      .catch((e) => {
                        console.log(e);
                      });

                    props.setFolders((prevFolders: CourseFolder[]) => {
                      return updateFolderInTree(prevFolders, updatedFolder);
                    });
                    setCourseFolders((prevFolders: CourseFolder[]) => {
                      return updateFolderInTree(prevFolders, updatedFolder);
                    });
                  }}
                  onFlieRenameClick={(name, folderId, fileId) => {
                    setisLoading(true);

                    UpdateFileName({ name, id: fileId ? fileId : x._id })
                      .then((x: any) => {
                        if (props.parentFolder != null && !fileId) {
                          props.parentFolder.files[i] = x;
                          props.setFiles([...props.parentFolder.files]);
                        } else {
                          const currentFolder = findFolderById(
                            props.folders,
                            folderId
                          );
                          const updatedFolder = updateFileNameInFolder(
                            currentFolder!!,
                            fileId,
                            name
                          );
                          props.setFolders((prevFolders: CourseFolder[]) => {
                            if (!currentFolder) return prevFolders;
                            return updateFolderInTree(
                              prevFolders,
                              updatedFolder
                            );
                          });

                          setCourseFolders((prevFolders: CourseFolder[]) => {
                            return updateFolderInTree(
                              prevFolders,
                              updatedFolder
                            );
                          });
                        }
                        setisLoading(false);
                        props.setNextBtnDisabled(false);
                      })
                      .catch((e) => {
                        setisLoading(false);
                        console.log(e);
                      });
                  }}
                  onTestDeleteClick={(folderId?: string, fileId?: string) => {
                    const currentFolder = findFolderById(
                      courseFolders,
                      folderId!!
                    );
                    const updatedFolder = removeTestFromFolder(
                      currentFolder!!,
                      fileId!!
                    );
                    props.setFolders((prevFolders: CourseFolder[]) => {
                      return updateFolderInTree(prevFolders, updatedFolder);
                    });
                    setCourseFolders((prevFolders: CourseFolder[]) => {
                      return updateFolderInTree(prevFolders, updatedFolder);
                    });
                    updateCourseFolderContent({
                      folderId: folderId!!,
                      parentFolder: updatedFolder!!,
                    })
                      .then((x: any) => {
                        props.setNextBtnDisabled(false);
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                  }}
                  onTestRenameClick={(name, folderId, fileId) => {
                    setisLoading(true);
                    UpdateTestName({ name, id: fileId ? fileId!! : x._id })
                      .then((x: any) => {
                        setisLoading(false);
                        props.setNextBtnDisabled(false);
                        if (props.parentFolder != null && !fileId) {
                          props.parentFolder.tests[i] = x;
                          props.setTest([...props.parentFolder.tests]);
                        } else {
                          const currentFolder = findFolderById(
                            props.folders,
                            folderId!!
                          );
                          const updatedFolder = updateTestNameInFolder(
                            currentFolder!!,
                            fileId!!,
                            name
                          );
                          props.setFolders((prevFolders: CourseFolder[]) => {
                            if (!currentFolder) return prevFolders;
                            return updateFolderInTree(
                              prevFolders,
                              updatedFolder
                            );
                          });

                          setCourseFolders((prevFolders: CourseFolder[]) => {
                            return updateFolderInTree(
                              prevFolders,
                              updatedFolder
                            );
                          });
                        }
                      })
                      .catch((e) => {
                        setisLoading(false);
                        console.log(e);
                      });
                  }}
                  onVideoDeleteClick={(folderId?: string, fileId?: string) => {
                    const currentFolder = findFolderById(
                      courseFolders,
                      folderId!!
                    );
                    const updatedFolder = removeVideoFromFolder(
                      currentFolder!!,
                      fileId!!
                    );
                    props.setFolders((prevFolders: CourseFolder[]) => {
                      return updateFolderInTree(prevFolders, updatedFolder);
                    });
                    setCourseFolders((prevFolders: CourseFolder[]) => {
                      return updateFolderInTree(prevFolders, updatedFolder);
                    });
                    updateCourseFolderContent({
                      folderId: folderId!!,
                      parentFolder: updatedFolder!!,
                    })
                      .then((x: any) => {
                        props.setNextBtnDisabled(false);
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                  }}
                  onVideoRenameClick={(name, folderId, fileId) => {
                    setisLoading(true);
                    UpdateCourseVideoName({
                      name,
                      id: fileId ? fileId!! : x._id,
                    })
                      .then((x: any) => {
                        setisLoading(false);
                        props.setNextBtnDisabled(false);
                        if (props.parentFolder != null && !fileId!!) {
                          props.parentFolder.videos[i] = x;
                          props.setVideos([...props.parentFolder.videos]);
                        } else {
                          const currentFolder = findFolderById(
                            props.folders,
                            folderId!!
                          );
                          const updatedFolder = updateVideoNameInFolder(
                            currentFolder!!,
                            fileId!!,
                            name
                          );
                          props.setFolders((prevFolders: CourseFolder[]) => {
                            if (!currentFolder) return prevFolders;
                            return updateFolderInTree(
                              prevFolders,
                              updatedFolder
                            );
                          });
                          setCourseFolders((prevFolders: CourseFolder[]) => {
                            return updateFolderInTree(
                              prevFolders,
                              updatedFolder
                            );
                          });
                        }
                      })
                      .catch((e) => {
                        setisLoading(false);
                        console.log(e);
                      });
                  }}
                />
              </div>
            );
          })}
          {props.files.map((x, i) => {
            return (
              <SingleFileCard
                fileName={x.name}
                nestedFile={false}
                onDeleteClick={() => {
                  if (props.parentFolder != null) {
                    const folderId = props.parentFolder._id;
                    const parentFolder = props.parentFolder;
                    props.parentFolder.files.splice(i, 1);
                    props.setFiles([...props.parentFolder.files]);
                    updateCourseFolderContent({ folderId, parentFolder })
                      .then((x: any) => {
                        props.setNextBtnDisabled(false);
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                  } else {
                    props.files.splice(i, 1);
                    props.setFiles([...props.files]);
                    props.setNextBtnDisabled(false);
                  }
                }}
                onRenameClick={(name) => {
                  setisLoading(true);
                  UpdateFileName({ name, id: x._id })
                    .then((x: any) => {
                      if (props.parentFolder != null) {
                        props.parentFolder.files[i] = x;
                        props.setFiles([...props.parentFolder.files]);
                      } else {
                        props.setFiles((prev: any) => {
                          const prev1 = [...prev];
                          prev1[i] = x;
                          return prev1;
                        });
                      }
                      setisLoading(false);
                      props.setNextBtnDisabled(false);
                    })
                    .catch((e) => {
                      setisLoading(false);
                      console.log(e);
                    });
                }}
              />
            );
          })}
          {props.tests.map((x, i) => {
            return (
              <SingleTestCard
                test={x}
                nestedTest={false}
                onDeleteClick={() => {
                  if (props.parentFolder != null) {
                    const folderId = props.parentFolder._id;
                    const parentFolder = props.parentFolder;
                    props.parentFolder.tests.splice(i, 1);
                    props.setTest([...props.parentFolder.tests]);
                    updateCourseFolderContent({ folderId, parentFolder })
                      .then((x: any) => {
                        props.setNextBtnDisabled(false);
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                  } else {
                    props.setTest((prev: any) => {
                      const prev1 = [...prev];
                      prev1.splice(i, 1);
                      return prev1;
                    });
                  }
                }}
                onRenameClick={(name) => {
                  setisLoading(true);
                  UpdateTestName({ name, id: x._id })
                    .then((x: any) => {
                      setisLoading(false);
                      props.setNextBtnDisabled(false);
                      if (props.parentFolder != null) {
                        props.parentFolder.tests[i] = x;
                        props.setTest([...props.parentFolder.tests]);
                      } else {
                        props.setTest((prev: any) => {
                          const prev1 = [...prev];
                          prev1[i] = x;
                          return prev1;
                        });
                      }
                    })
                    .catch((e) => {
                      setisLoading(false);
                      console.log(e);
                    });
                }}
              />
            );
          })}
          {props.videos.map((x, i) => {
            return (
              <SingleVideoCard
                nestedVideo={false}
                fileName={x.name}
                description={x.description}
                onDeleteClick={() => {
                  if (props.parentFolder != null) {
                    const folderId = props.parentFolder._id;
                    const parentFolder = props.parentFolder;
                    props.parentFolder.videos.splice(i, 1);
                    props.setVideos([...props.parentFolder.videos]);
                    updateCourseFolderContent({ folderId, parentFolder })
                      .then((x: any) => {
                        props.setNextBtnDisabled(false);
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                  } else {
                    props.setVideos((prev: any) => {
                      const prev1 = [...prev];
                      prev1.splice(i, 1);
                      return prev1;
                    });
                  }
                }}
                onRenameClick={(name) => {
                  setisLoading(true);
                  UpdateCourseVideoName({ name, id: x._id })
                    .then((x: any) => {
                      setisLoading(false);
                      props.setNextBtnDisabled(false);
                      if (props.parentFolder != null) {
                        props.parentFolder.videos[i] = x;
                        props.setVideos([...props.parentFolder.videos]);
                      } else {
                        const arr = props.videos;
                        arr[i] = x;
                        props.setVideos([...arr]);
                      }
                    })
                    .catch((e) => {
                      setisLoading(false);
                      console.log(e);
                    });
                }}
              />
            );
          })}

          <Flex>
            <Menu width="250px" position={isMd ? "top" : "bottom"}>
              <Menu.Target>
                <Flex
                  align="center"
                  ml={15}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (props.isTestSeries) {
                      setSelectedValue(MenuItems.TEST);
                    }
                  }}
                >
                  <IconPlus color="#4B65F6" size={15} />
                  <Text fz={15} color="#4B65F6" fw={700}>
                    Add {props.isTestSeries ? "Test" : "Content"}
                  </Text>
                </Flex>
              </Menu.Target>
              {!props.isTestSeries && (
                <Menu.Dropdown ml={30}>
                  <MenuItem
                    val={MenuItems.VIDEO}
                    onClick={() => {
                      setSelectedValue(MenuItems.VIDEO);
                    }}
                  />
                  <MenuItem
                    val={MenuItems.NOTES}
                    onClick={() => {
                      setSelectedValue(MenuItems.NOTES);
                    }}
                  />
                  <MenuItem
                    val={MenuItems.FOLDER}
                    onClick={() => {
                      setSelectedValue(MenuItems.FOLDER);
                    }}
                  />
                  <MenuItem
                    val={MenuItems.TEST}
                    onClick={() => {
                      setSelectedValue(MenuItems.TEST);
                    }}
                  />
                </Menu.Dropdown>
              )}
            </Menu>
          </Flex>
          <Divider color="#CDCEE3" w="100%" />
        </Stack>
      )}

      <Modal
        opened={selectedvalue !== null}
        onClose={() => {
          setSelectedValue(null);
        }}
        centered
        title={`Add ${selectedvalue}`}
        size="auto"
        styles={{
          title: {
            fontSize: 20,
            fontWeight: 700,
          },
        }}
        style={{
          zIndex: 9999,
        }}
      >
        {selectedvalue === MenuItems.VIDEO && (
          <AddVideoModal addVideo={addVideoshandler} />
        )}
        {selectedvalue === MenuItems.NOTES && (
          <AddNotesModal addNotes={addNoteshandler} />
        )}
        {selectedvalue === MenuItems.FOLDER && (
          <AddFolderModal
            addFolder={addFolderhandler}
            onCancelClick={() => {
              setSelectedValue(null);
            }}
          />
        )}
        {selectedvalue === MenuItems.TEST && (
          <AddTestModal
            addTestHandler={addTestHanlder}
            onCancelClick={() => {
              setSelectedValue(null);
            }}
          />
        )}
      </Modal>

      <Modal
        opened={showDeleteWarning}
        onClose={() => setShowDeleteWarning(false)}
        title={"Warning!!"}
      >
        <Text>
          Are you sure you want to delete this folder. All the contents inside
          this folder will be deleted
        </Text>
        <Flex align={"center"} mt={"2rem"} gap={20} justify={"end"} w={"100%"}>
          <Button onClick={() => setShowDeleteWarning(false)}>No</Button>
          <Button
            onClick={() => {
              setShowDeleteWarning(false);
              deleteFolderAfterConfirmation();
            }}
            color="red"
          >
            yes, delete it
          </Button>
        </Flex>
      </Modal>
    </>
  );
}
