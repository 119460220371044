import {
  Box,
  Center,
  Flex,
  Image,
  LoadingOverlay,
  Stack,
  Table,
  Text,
} from "@mantine/core";
import { useEffect, useState } from "react";

const AttendanceTable = (data: {
  index: number;
  dateOfJoining: Date;
  attendanceRecords: BatchAttendance;
}) => {
  const [isCollapse, setIsCollapse] = useState<boolean>(false);
  return (
<>
  <tr
    key={data.index}
    style={{
      borderBottom: "2px solid #e0e0e0",
      backgroundColor: "#ffffff",
      paddingTop: "15px",
      paddingBottom: "15px",
    }}
  >
    <td style={{ paddingTop: "20px", paddingBottom: "20px" }}>
      <Text weight={500}>{data.attendanceRecords.name}</Text>
      {data.dateOfJoining && (
        <Text size="xs" color="dimmed">
          Joined On: {new Date(data.dateOfJoining).toLocaleDateString()}
        </Text>
      )}
    </td>
    <td>{data.attendanceRecords.records.length}</td>
    <td style={{ color: "green", textAlign: "center" }}>
      {data.attendanceRecords.present}
    </td>
    <td style={{ color: "red", textAlign: "center" }}>
      {data.attendanceRecords.absent}
    </td>
    <td style={{ color: "orange", textAlign: "center" }}>
      {data.attendanceRecords.late}
    </td>
    <td style={{ color: "blue", textAlign: "center" }}>
      {data.attendanceRecords.online}
    </td>
    <td>
      <Image
        src={require("../../../assets/arrow-down.png")}
        alt="No Found"
        width={20}
        onClick={() => setIsCollapse(!isCollapse)}
        sx={{
          cursor: "pointer",
          transform: isCollapse ? "" : "rotate(-90deg)",
        }}
      />
    </td>
  </tr>
  <tr
    style={{
      display: isCollapse ? "table-row" : "none",
      transition: "height 0.5s ease",
    }}
  >
    <td colSpan={7} style={{ padding: 0 }}>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th>Date</th>
            <th style={{textAlign:"center"}} >Batch</th>
            <th style={{textAlign:"center"}}>Attendance</th>
          </tr>
        </thead>
        <tbody>
          {data.attendanceRecords.records.map((record, i) => (
            <tr key={i}>
              <td>{new Date(record.date).toLocaleDateString()}</td>
              <td  style={{textAlign:"center"}}>
                <Center>{record.batch?.name}</Center>
              </td>
              <td  style={{textAlign:"center"}}>{record.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </td>
  </tr>
</>

  );
};

export default AttendanceTable;
