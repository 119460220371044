import {
  Button,
  Container,
  Grid,
  Group,
  Image,
  Select,
  Space,
  Text,
  TextInput,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconX } from "@tabler/icons";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { IconPlus } from "@tabler/icons";
import { DatePicker } from "@mantine/dates";
import { EditStudentProfileFromStudent } from "../../features/StudentSlice";
import { showNotification } from "@mantine/notifications";

const EditStudentProfile = (props: {
  studentInfo: InstituteStudentInfo | null;
  onBack?:()=>void
}) => {
  const isMobile = useMediaQuery("(max-width: 800px)");
  const [formValues, setFormValues] = useState<StudentEditProfile>({
    gender: props.studentInfo?.gender || "",
    standard: props.studentInfo?.standard || "",
  });

  const handleInputChange = (field: string, value: any) => {
    if (field === "dateOfBirth") {
      setFormValues((p) => ({ ...p, dateOfBirth: value }));
    } else {
      setFormValues((prevValues) => ({ ...prevValues, [field]: value }));
    }
  };

  const handleEditStudentProfile =() =>{
    EditStudentProfileFromStudent(props.studentInfo?._id || "",{
        gender: formValues.gender,
        standard: formValues.standard,
      })
        .then((response: any) => {
          showNotification({
            message: "Student profile updated!!",
          });
          if(props.onBack){
            props.onBack()
          }
        })
        .catch((e) => {
          console.log("error : ", e);
        });
    
  }

  return (
    <>
      <Container w={isMobile ? "98%" : "100%"}>
        <Grid>
          <Grid.Col span={6}>
            <Select
              label="Gender"
              placeholder="Select"
              data={["Male", "Female", "Other"]}
              radius={"md"}
              value={formValues.gender}
              onChange={(value) => handleInputChange("gender", value)}
              rightSection={
                <Image
                  src={require("../../assets/arrow-down.png")}
                  alt="message"
                  style={{ width: 20, marginRight: "4px" }}
                />
              }
            />
          </Grid.Col>
          <Grid.Col span={isMobile ? 12 : 6}>
            <TextInput
              label="Standard"
              placeholder="Enter Standard"
              value={formValues.standard}
              radius={"md"}
              onChange={(event) =>
                handleInputChange("standard", event.currentTarget.value)
              }
              styles={{ input: { borderWidth: 2 } }}
            />
          </Grid.Col>
        </Grid>
        <Space h="md" />
        <Group position="right" mt="md">
          <Button onClick={handleEditStudentProfile}>Save Details</Button>
        </Group>
      </Container>
    </>
  );
};

export default EditStudentProfile;
