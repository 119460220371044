import {
  Box,
  Button,
  Center,
  Flex,
  Stack,
  Text,
  Modal,
  Grid,
} from "@mantine/core";
import { IconBackArrow } from "../../_Icons/CustonIcons";
import { lazy, useEffect, useState } from "react";
import { showNotification } from "@mantine/notifications";
import { useMediaQuery } from "@mantine/hooks";
import ProfileStudentFees from "./ProfileStudentFees";
import ProfileStudentAttendance from "./ProfileStudentAttendance";
import { Carousel } from "@mantine/carousel";
import { GiftRedeemCard } from "../../../_parentsApp/Components/GiftRedeemCard";
import { StudentPrizes } from "../../../_parentsApp/Components/StudentPrizes";
import {
  GetStudentGifts,
  GetStudentInfoById,
} from "../../../_parentsApp/features/instituteStudentSlice";
import ViewStudentProfile from "./RedeemedPrizes";
import { StudentProfile } from "../../../_parentsApp/Components/StudentProfile";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AddMoreDetails from "./../DashBoard/DashBoardStaff/AddMoreDetails";
import { getinstituteById } from "../../../features/institute/institute";
import PhoneInput from "react-phone-input-2";
import React from "react";

const ShowStudentResults = lazy(
  () => import("../../../_parentsApp/Components/ShowStudentResults")
);

interface UpdateStudentProfile {
  formData: StudentsDataWithBatch;
  batchList: InstituteClass[];
  editStudentFee: boolean;
  studentActiveTab: string;
  onBack: () => void;
  instituteId: string;
  instituteClassId: string;
  openedFromAdminPage: boolean;
  isStudentAdd: boolean;
  setBatch: (val: InstituteClass) => void;
  onSubmitclick: (
    name: string,
    phoneNumber: string[],
    parentName: string,
    dateBirth: Date,
    address: string
  ) => void;
  studentData: {
    studentId: string;
    studentName: string;
    profilePic: string;
  };
}

export function UpdateStudentProfile(props: UpdateStudentProfile) {
  const batchMap = new Map(
    props.batchList.map((item) => [item._id, item.name])
  );
  const navigate = useNavigate();
  const [nameValue, setNameValue] = useState<string>(props.formData.name);
  const [parentNameValue, setParentNameValue] = useState<string>(
    props.formData.parentName
  );

  const [dateBirthValue, setDateBirthValue] = useState<Date>(
    props.formData.dateOfBirth || new Date()
  );
  const [addressValue, setAddressValue] = useState<string>(
    props.formData.address || ""
  );
  const [phoneNumbers, setPhoneNumbers] = useState<string[]>(
    props.formData.phoneNumber
  );
  const [image, setImage] = useState<File | null>(null);
  const isMd = useMediaQuery(`(max-width: 968px)`);
  const [isEditing, setIsEditing] = useState(false);

  const [isModal, setIsModal] = useState(false);
  const [allStudentGifts, setAllStudentGifts] = useState<StudentGift[]>([]);
  const [editData, setEditData] = useState<StudentsDataWithBatch | undefined>();

  const isFormFilled = () => {
    for (let phoneNumber of phoneNumbers) {
      const isNumberValid = phoneNumber.length > 8;
      if (!isNumberValid) return false;
    }
    return nameValue.trim().length !== 0 && parentNameValue.trim().length !== 0
      ? true
      : false;
  };

  const handleModalSubmit = () => {
    setIsModal(false);
    setIsEditing(false);
    if (isFormFilled())
      props.onSubmitclick(
        nameValue,
        phoneNumbers,
        parentNameValue,
        dateBirthValue,
        addressValue
      );
    else {
      showNotification({
        message: "Enter Valid Form Details",
      });
    }
  };

  function fetchStudentGifts() {
    GetStudentGifts({
      id: props.studentData.studentId,
    })
      .then((data: any) => {
        setAllStudentGifts(data);
      })
      .catch((err) => {
        console.log(err);
      });

    if (props.editStudentFee) {
      GetStudentInfoById({
        id: props.studentData.studentId,
      })
        .then((data: any) => {
          const mappedData: StudentsDataWithBatch = {
            _id: data._id,
            name: data.name,
            phoneNumber: data.phoneNumber,
            parentName: data.parentName,
            instituteId: data.instituteId,
            profilePic: data.profilePic,
            attendance: data.attendance,
            paymentRecords: data.paymentRecords,
            dateOfBirth: data.dateOfBirth
              ? new Date(data.dateOfBirth)
              : new Date(),
            address: data.address,
            uniqueRoll: data.uniqueRoll,
            isInActive: data.isInActive,
            totalRewardpoints: data.totalRewardpoints,
            parentNumber: data.parentNumber,
            gender: data.gender,
            standard: data.standard,
            source: data.source,
            instituteBatches: data.instituteBatches,
          };
          setEditData(mappedData);
          setIsEditing(true);
          setAllStudentGifts(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  useEffect(() => {
    fetchStudentGifts();
  }, [props.studentData]);
  return (
    <Box w={"100%"} mih={"100vh"}>
      <Center>
        <Stack w={"100%"} spacing={24}>
          <form onSubmit={handleModalSubmit}>
            <Modal
              radius="sm"
              size="sm"
              opened={isModal}
              onClose={() => setIsModal(false)}
              title={
                <Text size="lg" fz={20} fw={700}>
                  Edit Profile
                </Text>
              }
              style={{ top: "12vh" }}
            >
              <Stack spacing={8}>
                <Text fz={14} fw={400}>
                  Student Name
                </Text>
                <input
                  placeholder="Enter Student Name"
                  value={nameValue}
                  onChange={(event) => setNameValue(event.currentTarget.value)}
                  autoFocus={isEditing}
                  style={{
                    borderRadius: "6px",
                    padding: "8px",
                    border: "1px solid #808080",
                    borderColor: nameValue ? "#808080" : "red",
                  }}
                />
                <Text fz={14} fw={400}>
                  Father Name
                </Text>
                <input
                  placeholder="Enter parent's name"
                  value={parentNameValue}
                  onChange={(event) =>
                    setParentNameValue(event.currentTarget.value)
                  }
                  autoFocus={isEditing}
                  style={{
                    borderRadius: "6px",
                    padding: "8px",
                    border: "1px solid #808080",
                    borderColor: parentNameValue ? "#808080" : "red",
                  }}
                />
                <Text fz={14} fw={400}>
                  Phone Number
                </Text>
                {phoneNumbers.map((phoneNumber, index) => (
                  <div key={index}>
                    <PhoneInput
                      country="in"
                      placeholder="Enter phone number"
                      value={phoneNumber}
                      onChange={(value?: string | undefined) => {
                        if (value) {
                          var finalPhoneNum = value.toString();
                          if (finalPhoneNum[0] == "0") {
                            finalPhoneNum = finalPhoneNum.substring(1);
                          }
                          const newPhoneNumbers = [...phoneNumbers];
                          newPhoneNumbers[index] = `+${finalPhoneNum}`;
                          setPhoneNumbers(newPhoneNumbers);
                        }
                      }}
                      containerStyle={{
                        height: "36px",
                      }}
                      inputStyle={{
                        width: "100%",
                        height: "100%",
                        border: "solid 1px #00000040",
                      }}
                    />
                  </div>
                ))}
                <Text fz={14} fw={400}>
                  Date Of Birth
                </Text>
                <input
                  placeholder="Enter BirthDate"
                  type="date"
                  value={dateBirthValue.toLocaleDateString()}
                  onChange={(event) =>
                    setDateBirthValue(new Date(event.currentTarget.value))
                  }
                  autoFocus={isEditing}
                  style={{
                    borderRadius: "6px",
                    padding: "8px",
                    border: "1px solid #808080",
                  }}
                />
                <Text fz={14} fw={400}>
                  Address
                </Text>
                <input
                  placeholder="Enter Address"
                  value={addressValue}
                  onChange={(event) =>
                    setAddressValue(event.currentTarget.value)
                  }
                  autoFocus={isEditing}
                  style={{
                    borderRadius: "6px",
                    padding: "8px",
                    border: "1px solid #808080",
                  }}
                />
              </Stack>
              <Flex justify="flex-end" mt={10} pr={4}>
                <Button
                  id="cancel-btn"
                  onClick={() => setIsModal(false)}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    border: "1px solid #808080",
                    padding: "11px, 13px, 11px, 13px",
                    borderRadius: "20px",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleModalSubmit}
                  disabled={!isFormFilled()}
                  style={{
                    backgroundColor: isFormFilled() ? "#3174F3" : "transparent",
                    color: isFormFilled() ? "#ffffff" : "#A7A7A7",
                    border: "1px solid #A7A7A7",
                    borderRadius: "20px",
                    marginLeft: "8px",
                    padding: "11px, 13px, 11px, 13px",
                    width: "20vh",
                    cursor: isFormFilled() ? "pointer" : "default",
                  }}
                >
                  Save Details
                </Button>
              </Flex>
            </Modal>
          </form>
          {isEditing ? (
            <AddMoreDetails
              isEditableData={true}
              formData={editData}
              batchList={batchMap}
              studentId={props.studentData.studentId}
              batchId={props.instituteClassId}
              setAddMoreDetail={(x) => {
                props.onBack();
                setIsEditing(false);
              }}
            />
          ) : (
            <>
              <StudentProfile
                studentId={props.studentData.studentId}
                onBack={() => {
                  props.onBack();
                }}
                setBatch={props.setBatch}
                studentActiveTab={props.studentActiveTab}
                userType={"teacher"}
              />
            </>
          )}
        </Stack>
      </Center>
    </Box>
  );
}
