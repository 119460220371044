import React from 'react';
import { Select, Text } from '@mantine/core';

interface MoleculeDropdownProps {
  options: string[] | undefined;
  selected: string;
  onChange: (value: string) => void;
}

const MoleculeDropdown: React.FC<MoleculeDropdownProps> = ({ options, selected, onChange }) => {
  return (
    <div style={{ width: 150 }}>
      <Text color="white" size="sm" mb="xs" align="center">
        Molecules
      </Text>
     {options && <Select
        value={selected}
        onChange={(value) => onChange(value || '')}
        data={options.map((option) => ({ value: option, label: option }))}
        placeholder="Select a molecule"
        styles={{
          input: {
            backgroundColor: '#fff',
            border: '1px solid #333',
            borderRadius: '4px',
            height: '40px',
            fontSize: '16px',
            color: '#000',
          },
          dropdown: {
            backgroundColor: '#fff',
            borderRadius: '4px',
            border: '1px solid #333',
            padding: '4px 0',
          },
          item: {
            fontSize: '16px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            color: '#000',
            '&[data-selected]': {
              backgroundColor: '#f0f0f0',
              color: '#000',
            },
          },
        }}
      />}
    </div>
  );
};

export default MoleculeDropdown;
