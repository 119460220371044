import React, { useEffect, useRef, useState } from 'react';
import { Canvas, useThree ,useLoader} from '@react-three/fiber';
import { OrthographicCamera, Text } from '@react-three/drei';
import * as THREE from 'three';
import { TextureLoader } from 'three';
import { OrbitControls } from '@react-three/drei';
import { useMediaQuery } from '@mantine/hooks';
import { Box } from '@mantine/core';

const BoatImg = "https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-07-19T08-33-34-297Z.png";

interface ModelProps {
  velocityStream: number;
  directionBoat: number;
  magnitudeBoat: number;
  showResultant: boolean;
  boatAnimation?: boolean;
  resetPosition: boolean;
  showValues: boolean;
  isPan?: boolean;
  zoomValue: number;
  
}

const BoatScene = ({
  velocityStream,
  showValues,
  directionBoat,
  magnitudeBoat,
  showResultant,
  boatAnimation,
  resetPosition,
  zoomValue,
 
}: ModelProps) => {
  const boatRef = useRef<THREE.Mesh | null>(null);
  const [boatVelocity, setBoatVelocity] = useState(new THREE.Vector3(0, 4, 0));
  const [riverVelocity, setRiverVelocity] = useState(new THREE.Vector3(2, 0, 0));
  const [resultantVelocity, setResultantVelocity] = useState(new THREE.Vector3());
  const [boatDirectionAngle, setBoatDirectionAngle] = useState<number>(90);
  const boatTexture = useLoader(TextureLoader, BoatImg);
  
  const { size } = useThree();
  const scaleX = size.width / 500;
  const scaleY = size.height / 500;
  const sY = size.height * (1 / zoomValue) / 2;
  const initialBoatPosition = new THREE.Vector3(0 * scaleX, -sY + 1, 0);
  const [boatPosition, setBoatPosition] = useState(initialBoatPosition);

  useEffect(() => {
    const newResultantVelocity = new THREE.Vector3();
    newResultantVelocity.addVectors(boatVelocity, riverVelocity);
    setResultantVelocity(newResultantVelocity);
  }, [boatVelocity, riverVelocity]);

  useEffect(() => {
    setRiverVelocity(new THREE.Vector3(velocityStream, 0, 0));
  }, [velocityStream]);

  useEffect(() => {
    const angles = [0, 30, 45, 60, 90, 120, 135, 150, 180];
    setBoatDirectionAngle(angles[directionBoat] || 90);
  }, [directionBoat]);

  useEffect(() => {
    const boatDirectionAngleRadians = boatDirectionAngle * Math.PI / 180;
    const boatX = magnitudeBoat * Math.cos(boatDirectionAngleRadians);
    const boatY = magnitudeBoat * Math.sin(boatDirectionAngleRadians);
    setBoatVelocity(new THREE.Vector3(boatX, boatY, 0));
  }, [magnitudeBoat, boatDirectionAngle]);

  useEffect(() => {
    if (resetPosition && boatRef.current) {
      setBoatPosition(initialBoatPosition.clone());
      boatRef.current.position.copy(initialBoatPosition);
      boatRef.current.rotation.set(0, 0, 0);
    }
  }, [resetPosition, initialBoatPosition]);

  return (
    <>
      <ambientLight intensity={0.5} />
      <mesh ref={boatRef} position={boatPosition}>
        <planeGeometry args={[1, 2]} />
        <meshBasicMaterial map={boatTexture} transparent />
      </mesh>
      <arrowHelper
        args={[
          boatVelocity.clone().normalize(),
          new THREE.Vector3(boatPosition.x, boatPosition.y, 0),
          boatVelocity.length(),
          0xFF08E6,
          0.5,
          0.6,
         
        ]}
      />
      <arrowHelper
        args={[
          riverVelocity.clone().normalize(),
          new THREE.Vector3(boatPosition.x + boatVelocity.x, boatPosition.y + boatVelocity.y, 0),
          riverVelocity.length(),
          0xFAFF08,
          0.5,
          0.6,
          
        ]}
      />
      {showResultant && (
        <arrowHelper
          args={[
            resultantVelocity.clone().normalize(),
            new THREE.Vector3(boatPosition.x, boatPosition.y, 0),
            resultantVelocity.length(),
            0xFF0808,
            0.5,
            0.6,
            
          ]}
        />
      )}
      <Text
        position={[boatPosition.x - 1 + boatVelocity.x, boatPosition.y - 1 + boatVelocity.y, 0]}
        color="#FF08E6"
        fontSize={0.85}
      >
        V
      </Text>
      <Text
        position={[boatPosition.x - 0.2 + boatVelocity.x, boatPosition.y - 1.3 + boatVelocity.y, 0]}
        color="#FF08E6"
        fontSize={0.6}
      >
        boat
      </Text>
      {showValues && (
        <Text
          position={[boatPosition.x + 1.4 + boatVelocity.x, boatPosition.y - 1.1 + boatVelocity.y, 0]}
          color="#FF08E6"
          fontSize={0.7}
        >
          = {boatVelocity.length().toFixed(1)}
        </Text>
      )}
      <Text
        position={[boatPosition.x-2.5 + boatVelocity.x + riverVelocity.x, boatPosition.y + 1 + boatVelocity.y + riverVelocity.y, 0]}
        color="#FAFF08"
        fontSize={0.85}
      >
        V
      </Text>
      <Text
        position={[boatPosition.x - 1.4 + boatVelocity.x + riverVelocity.x, boatPosition.y + 0.8 + boatVelocity.y + riverVelocity.y, 0]}
        color="#FAFF08"
        fontSize={0.6}
      >
        stream
      </Text>
      {showValues && (
        <Text
          position={[boatPosition.x + 0.5 + boatVelocity.x + riverVelocity.x, boatPosition.y + 0.9 + boatVelocity.y + riverVelocity.y, 0]}
          color="#FAFF08"
          fontSize={0.7}
        >
          = {riverVelocity.length().toFixed(1)}
        </Text>
      )}
      {showResultant && (
        <>
          <Text
            position={[boatPosition.x -1.5 + resultantVelocity.x, boatPosition.y - 2.1 + resultantVelocity.y, 0]}
            color="#FF0808"
            fontSize={0.8}
          >
            V
          </Text>
          <Text
            position={[boatPosition.x - 0.1 + resultantVelocity.x, boatPosition.y - 2.2 + resultantVelocity.y, 0]}
            color="#FF0808"
            fontSize={0.6}
          >
            resultant
          </Text>
          {showValues && (
            <Text
              position={[boatPosition.x +2.2 + resultantVelocity.x, boatPosition.y - 2.1 + resultantVelocity.y, 0]}
              color="#FF0808"
              fontSize={0.7}
            >
              = {resultantVelocity.length().toFixed(2)}
            </Text>
          )}
        </>
      )}
    </>
  );
};

const BoatValueShow = ({
  zoomValue,
  isPan,
  showValues,
  velocityStream,
  magnitudeBoat,
  directionBoat,
  showResultant,
  boatAnimation,
  resetPosition,
  
}: ModelProps) => {
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const ismediumScreen = useMediaQuery("(max-width: 1024px)");


  return (
    <>
      <OrthographicCamera makeDefault position={[0, 0, 10]} zoom={zoomValue} />
      <BoatScene
        showValues={showValues}
        velocityStream={velocityStream}
        magnitudeBoat={magnitudeBoat}
        directionBoat={directionBoat}
        showResultant={showResultant}
        boatAnimation={boatAnimation}
        resetPosition={resetPosition}
        zoomValue={zoomValue}
        
      />
      <OrbitControls enableRotate={false} enableZoom={false} enablePan={isPan} />
    </>
  );
};

export default BoatValueShow;
