import {
  Box,
  Center,
  Flex,
  Image,
  LoadingOverlay,
  Stack,
  Table,
  Text,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { GetStudentAttendance } from "../../features/instituteStudentSlice";
import AttendanceTable from "./AttendanceTable";




const Attendance = (data: { dateOfJoining: Date; studentId: string }) => {
  const [attendanceRecords, setAttendanceRecords] = useState<
  BatchAttendance[]
  >([]);
  const [attendanceData,setAttendanceData] = useState<StudentAttendanceRecord[]>([])
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [isCollapse, setIsCollapse] = useState<boolean>(false);

  useEffect(() => {
    setIsloading(true);
    GetStudentAttendance({
      id: data.studentId,
    })
      .then((x: any) => {
        setAttendanceData(x)
        setIsloading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsloading(false);
      });
  }, []);

  const groupByBatch = (records:StudentAttendanceRecord[]) => {
    
    const grouped: { [key: string]: BatchAttendance } = {};
    records.forEach((record:StudentAttendanceRecord) => {
      const { batch, status } = record;
  
      if (!grouped[batch?._id]) {
        grouped[batch?._id] = {
          name:batch?.name,
          totalClasses: 0,
          present: 0,
          absent: 0,
          late: 0,
          online: 0,
          records: [],
        };
      }
  
      grouped[batch?._id].totalClasses += 1;
      grouped[batch?._id].records.push(record);
      if (status === "PRESENT") grouped[batch?._id].present += 1;
      if (status === "ABSENT") grouped[batch?._id].absent += 1;
      if (status === "LATE") grouped[batch?._id].late += 1;
      if (status === "ONLINE") grouped[batch?._id].online += 1;
    });
    return Object.values(grouped);
  };

  useEffect(() => {
    // Simulate fetching data
    const groupedData = groupByBatch(attendanceData);
    setAttendanceRecords(groupedData);
  }, [attendanceData]);
  
  return (
    <>
      <LoadingOverlay visible={isLoading} />
      <Table striped highlightOnHover>
        <thead style={{ backgroundColor: "#E4EDFD" }}>
          <tr style={{ paddingTop: "3px", paddingBottom: "3px" }}>
            <th>Batches</th>
            <th>Total Classes</th>
            <th>Present</th>
            <th>Absent</th>
            <th>Late</th>
            <th>Online</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {attendanceRecords.map((batch, index) => (
            <>
            <AttendanceTable index={index} dateOfJoining={data.dateOfJoining} attendanceRecords={batch} />
          </>
        ))}
        </tbody>
      </Table>
    </>
  );
};

export default Attendance;
