import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Stepper,
  Button,
  Group,
  Stack,
  Text,
  Divider,
  TextInput,
  Container,
  Grid,
  Select,
  Space,
  Image,
  MultiSelect,
  Modal,
  ActionIcon,
  NumberInput,
  Table,
  Flex,
  Box,
  LoadingOverlay,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useMediaQuery } from "@mantine/hooks";
import {
  AddBatchesIdsAndCourseIdsIntoStudent,
  AddStudentFeeRecords,
  CreateStudent,
  UpdateStudent,
} from "../../../../features/StudentSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/ReduxStore";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { IconX, IconPlus } from "@tabler/icons";
import { IconTrash } from "../../../_Icons/CustonIcons";
import { ThemeConsumer } from "styled-components";
import { GetStudentInfoById } from "../../../../_parentsApp/features/instituteStudentSlice";
import { showNotification } from "@mantine/notifications";
import { GetAllMonthsDataByClassId } from "../../../../_parentsApp/features/instituteClassSlice";

interface BatchListItem {
  label: string;
  value: string;
}

const AddMoreDetails = (props: {
  formData?: StudentsDataWithBatch;
  batchId?: string;
  CourseList?: Map<string, string>;
  batchList?: Map<string, string>;
  isEditableData: boolean;
  studentId?: string;
  setAddMoreDetail?: (val: boolean) => void;
  setInstituteStudentData?: Dispatch<SetStateAction<StudentDetails[]>>;
  onCallBack?: () => void;
}) => {
  props?.CourseList?.delete("1");
  props?.batchList?.delete("1");
  const instituteDetails = useSelector<RootState, InstituteDetails | null>(
    (state) => state.instituteDetailsSlice.instituteDetails
  );
  const [showError, setShowError] = useState<boolean>(false);
  const isMobile = useMediaQuery("(max-width: 800px)");
  const [phoneNumber, setPhoneNumber] = useState<string>(
    props.formData?.phoneNumber[0] || ""
  );
  const [parentPhoneNumber, setParentPhoneNumber] = useState<string>("");
  const [studentId, setStudentId] = useState<string>(props.studentId || "");
  const [openNextModel, setOpenNextModel] = useState<boolean>(false);
  const [goBackAfterCreatingStudent, setGoBackAfterCreatingStudent] =
    useState<boolean>(false);
  const [additionalPhoneNumbers, setAdditionalPhoneNumbers] = useState<
    string[]
  >(props.formData?.additionalPhoneNumbers || []);

  const [formValues, setFormValues] = useState<StudentFormValues>({
    name: props.formData?.name || "",
    parentName: props.formData?.parentName || "",
    phoneNumber: props.formData?.phoneNumber || [],
    dateOfBirth: props.formData?.dateOfBirth
      ? new Date(props.formData.dateOfBirth)
      : props.isEditableData
      ? undefined
      : new Date(),
    address: props.formData?.address || "",
    parentNumber: props.formData?.parentNumber || "",
    gender: props.formData?.gender || "",
    standard: props.formData?.standard || "",
    source: props.formData?.source || "",
    dateOfJoining: props.formData?.dateOfJoining || new Date(),
  });

  const [collectCourseIds, setCollectCourseIds] = useState<string[] | null>(
    null
  );
  const [collectBatchIds, setCollectBatchIds] = useState<string[]>([]);
  const [active, setActive] = useState(0);
  const [installments, setInstallments] = useState<Installment[]>([
    {
      _id: "",
      name: "Installment 1",
      dueDate: new Date().toISOString().split("T")[0],
      amount: 0,
      isDeleted: false,
    },
  ]);

  const [assignedBatch, setAssignedBatch] = useState<Map<string, string>>(
    new Map(props.batchList)
  );
  const [assignBatchInstallment, setAssignBatchInstallment] = useState<
    Map<string, Installment[]>
  >(new Map());
  const [assignedOldBatchInstallment, setAssignedOldBatchInstallment] =
    useState<Map<string, Installment[]>>(new Map());
  const [trackingOldBatchInstallment, setTrackingOldBatchInstallment] =
    useState<Map<string, Installment[]>>(new Map());

  const [selectedBatchId, setSelectedBatchId] = useState<string>("");
  const [presentBatchId, setPresentBatchId] = useState<string>("");
  const [isLoading, setisLoading] = useState<boolean>(false);

  const onChangeAssigningBatch = (selectedValues: string[]) => {
    setCollectBatchIds(selectedValues);
  };
  const [assignedBatchIds, setAssignedBatchIds] = useState<string[]>();

  useEffect(() => {
    onChangeAssigningBatch(assignedBatchIds || []);
  }, [assignedBatchIds, presentBatchId]);

  useEffect(() => {
    if (props.batchId) {
      onChangeAssigningBatch([props.batchId]);
    }
  }, [props.batchId]);

  useEffect(() => {
    const newAssignBatchInstallment = new Map(assignBatchInstallment);
    assignedBatch.forEach((_, key) => {
      if (!newAssignBatchInstallment.has(key)) {
        newAssignBatchInstallment.set(key, installments);
      }
    });

    setAssignBatchInstallment(newAssignBatchInstallment);
  }, [assignedBatch]);

  useEffect(() => {
    if (props.isEditableData && props.batchList) {
      const newAssignBatchInstallment = new Map(assignBatchInstallment);

      const filteredBatches = new Map(
        Array.from(props.batchList).filter(([key]) =>
          collectBatchIds?.includes(key)
        )
      );
      setAssignedBatch(filteredBatches);
    } else {
      if (props.batchList) {
        const filteredBatches = new Map(
          Array.from(props.batchList).filter(([key]) =>
            collectBatchIds?.includes(key)
          )
        );

        setAssignedBatch(filteredBatches);
        const initialAssignBatchInstallment = new Map(
          Array.from(filteredBatches.keys()).map((key) => [key, installments])
        );
        setAssignBatchInstallment(initialAssignBatchInstallment);
      }
    }
  }, [collectBatchIds, props.batchList]);

  const [customOrBatch, setCustomOrBatch] = useState<Map<string, string>>(
    new Map()
  );
  const [oldCustomOrBatch, setOldCustomOrBatch] = useState<Map<string, string>>(
    new Map()
  );

  const [batchInstallment, setBatchInstallment] = useState<
    Map<string, Installment[]>
  >(new Map());

  useEffect(() => {
    if (!props.isEditableData) return;
    GetStudentInfoById({ id: props.studentId || "" })
      .then((res: any) => {
        const numbers = res.phoneNumber;
        const phonNumber = numbers.shift();
        setAdditionalPhoneNumbers(numbers);

        const batches = res.instituteBatches.map((x: any) => x._id);
        setAssignedBatchIds(batches);

        const installmentMap = new Map<string, Installment[]>();
        const customOrBatchMap = new Map<string, string>();

        res.feeRecords.map((record: any) => {
          const installment: Installment = {
            _id: record._id,
            name: record.name,
            dueDate: new Date(record.dueDate).toISOString().split("T")[0],
            amount: record.totalAmount,
          };
          if (installmentMap.has(record.batch._id)) {
            installmentMap.get(record.batch._id)!.push(installment);
          } else {
            installmentMap.set(record.batch._id, [installment]);
          }

          if (!customOrBatchMap.has(record.batch._id)) {
            customOrBatch.set(record.batch._id, record.type);
          }
        });
        setAssignedOldBatchInstallment(installmentMap);
        setOldCustomOrBatch(customOrBatch);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    const currentInstallment =
      assignedOldBatchInstallment.get(selectedBatchId) || [];
    const oldCustomOrBatchWithId = oldCustomOrBatch.get(selectedBatchId) || "";

    if (
      customOrBatch.get(selectedBatchId) !==
      oldCustomOrBatch.get(selectedBatchId)
    ) {
      const updatedMap = new Map(assignedOldBatchInstallment);

      if (updatedMap.has(selectedBatchId)) {
        const installments = updatedMap.get(selectedBatchId);

        if (installments) {
          const updatedInstallments = installments.map((installment) => ({
            ...installment,
            isDeleted: true,
          }));

          updatedMap.set(selectedBatchId, updatedInstallments);
        }
      }
      setTrackingOldBatchInstallment(updatedMap);
    }

    if (customOrBatch.get(selectedBatchId) !== "Batch") {
      const currentCustomInstallments =
        assignedOldBatchInstallment.get(selectedBatchId) || [];
      if (
        currentCustomInstallments?.length > 0 &&
        oldCustomOrBatchWithId !== "Batch"
      ) {
        setInstallments(
          currentCustomInstallments || [
            {
              name: "Installment 1",
              dueDate: new Date(),
              amount: 0,
              isDeleted: false,
            },
          ]
        );
      } else {
        setInstallments([
          {
            name: "Installment 1",
            dueDate: new Date().toISOString().split("T")[0],
            amount: 0,
          },
        ]);
        if (selectedBatchId) {
          setAssignBatchInstallment((prevMap) => {
            const newMap = new Map(prevMap);
            if (!newMap.has(selectedBatchId)) {
              newMap.set(selectedBatchId, installments);
            }
            return newMap;
          });
        }
      }
      return;
    }

    GetAllMonthsDataByClassId({ id: selectedBatchId })
      .then((x: any) => {
        if (!x.batchFee) {
          setInstallments([
            {
              name: "Installment 1",
              dueDate: new Date().toISOString().split("T")[0],
              amount: 0,
            },
          ]);
          return;
        }
        const formattedInstallments = x.batchFee.feeInstallments.map(
          ({ _id, ...installment }: Installment) => ({
            ...installment,
            dueDate: installment.dueDate
              ? new Date(installment.dueDate).toISOString().split("T")[0]
              : new Date().toISOString().split("T")[0],
          })
        );

        if (formattedInstallments) {
          setInstallments(formattedInstallments);
          if (
            customOrBatch.get(selectedBatchId) !==
            oldCustomOrBatch.get(selectedBatchId)
          ) {
            setAssignBatchInstallment((prevMap) => {
              const newMap = new Map(prevMap);
              newMap.set(selectedBatchId, formattedInstallments);
              return newMap;
            });
          } else {
            setAssignBatchInstallment((prevMap) => {
              const newMap = new Map(prevMap);
              newMap.set(
                selectedBatchId,
                assignedOldBatchInstallment.get(selectedBatchId)!
              );
              return newMap;
            });
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [customOrBatch, selectedBatchId]);

  useEffect(() => {
    if (active === 1 && props.batchList && props.batchList?.size <= 0) {
      showNotification({
        message: "Add batch first",
      });
    }
  }, [active]);

  const selectTheBatchId = (id: string) => {
    setSelectedBatchId(id);
  };

  const handleAddInstallment = () => {
    let index = installments.length + 1;
    setInstallments([
      ...installments,
      {
        name: "Installment " + index,
        dueDate: new Date().toISOString().split("T")[0],
        amount: 0,
      },
    ]);
  };

  const handleRemoveInstallment = (index: number) => {
    const newInstallments = installments.filter((_, i) => i !== index);
    setInstallments(newInstallments);
    setAssignBatchInstallment((prevState) => {
      const updatedMap = new Map(prevState);
      updatedMap.set(selectedBatchId, newInstallments);
      return updatedMap;
    });
  };

  const handleInstallmentChange = (index: any, field: any, value: any) => {
    const updatedInstallments = [...installments];
    updatedInstallments[index] = {
      ...updatedInstallments[index],
      [field]: value,
    };

    setInstallments(updatedInstallments);

    setAssignBatchInstallment((prevState) => {
      const updatedMap = new Map(prevState);
      updatedMap.set(selectedBatchId, updatedInstallments);
      return updatedMap;
    });
  };

  const handleInputChange = (field: string, value: any) => {
    if (field === "dateOfBirth") {
      setFormValues((p) => ({ ...p, dateOfBirth: value ? value : null }));
    } else if (field === "dateOfJoining") {
      setFormValues((p) => ({ ...p, dateOfJoining: value ? value : null }));
    } else {
      setFormValues((prevValues) => ({ ...prevValues, [field]: value }));
    }
  };
  const nextStep = () => {
    if (active === 0) {
      if (!formValues.name || !phoneNumber || phoneNumber === "+9") {
        setShowError(true);
      } else {
        // if (goBackAfterCreatingStudent) {
        if (!props.isEditableData && !goBackAfterCreatingStudent) {
          setisLoading(true);
          CreateStudent({
            name: formValues.name,
            parentName: formValues.parentName,
            phoneNumber: [...additionalPhoneNumbers, phoneNumber].filter(
              (number) => number !== ""
            ),
            dateOfBirth: formValues?.dateOfBirth
              ? new Date(formValues?.dateOfBirth?.toISOString().split("T")[0])
              : undefined,
            address: formValues.address,
            parentNumber: formValues.parentNumber
              ? formValues.parentNumber
              : parentPhoneNumber,
            gender: formValues.gender,
            standard: formValues.standard,
            source: formValues.source,
            instituteId: instituteDetails?._id || "",
          })
            .then((response: any) => {
              setShowError(false)
              setisLoading(false);
              const data = {
                name: response.name,
                _id: response._id,
                parentName: response.parentName,
                phone: response.phoneNumber[0],
                isInActive: response.isInActive,
                isDeleted: response.isDeleted,
                instituteBatches: response.instituteBatches,
                myCourses: response.myCourses,
                paymentRecords: response.paymentRecords,
              };
              if (props.setInstituteStudentData) {
                props.setInstituteStudentData((prev) => [...prev, data]);
              }
              setStudentId(response?._id);
              setActive((current) => (current < 3 ? current + 1 : current));
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          const adjustedDate = formValues.dateOfBirth
            ? new Date(formValues.dateOfBirth)
            : undefined;
          if (adjustedDate && isNaN(adjustedDate.getTime())) {
            console.error("Invalid dateOfBirth:", formValues.dateOfBirth);
            return;
          }

          // Adjust the date
          adjustedDate && adjustedDate.setDate(adjustedDate.getDate() + 1);
          const newBatchInstallment = new Map(batchInstallment);
          newBatchInstallment.set(selectedBatchId, installments);

          const uniqueNumberArray = [
            phoneNumber,
            ...additionalPhoneNumbers,
          ].filter(
            (value, index, self) => index === self.findIndex((t) => t === value)
          );
          setisLoading(true);
          UpdateStudent({
            id: studentId || "",
            formData: {
              name: formValues.name,
              parentName: formValues.parentName,
              phoneNumber: uniqueNumberArray,
              dateOfBirth: adjustedDate
                ? new Date(adjustedDate.toISOString().split("T")[0])
                : undefined,
              address: formValues.address,
              parentNumber: formValues.parentNumber
                ? formValues.parentNumber
                : parentPhoneNumber,
              gender: formValues.gender,
              standard: formValues.standard,
              source: formValues.source,
              instituteId: instituteDetails?._id || "",
              instituteBatches: [],
              dateOfJoining: formValues.dateOfJoining,
            },
            courseInfo: {
              batchIds: [],
              dateOfJoining: formValues.dateOfJoining,
            },
            feeRecords: {
              customOrBatch: new Map(),
              batchId: "",
              installments: new Map(),
            },
          })
            .then((response: any) => {
              setShowError(false)
              setisLoading(false);
              if (props.onCallBack) {
                props.onCallBack();
              }
              setActive((current) => (current < 3 ? current + 1 : current));
            })
            .catch((e) => {
              console.log(e);
            });
        }
      }
    } else if (active === 1) {
      if (collectBatchIds.length <= 0) {
        setOpenNextModel(true);
      } else {
        if (!props.isEditableData) {
          if (!formValues.dateOfJoining) {
            showNotification({
              message: "Select joining date please!!",
            });
            return;
          }
          AddBatchesIdsAndCourseIdsIntoStudent({
            studentId: studentId,
            batchIds: collectBatchIds || [],
            dateOfJoining: formValues.dateOfJoining.toString() || "",
          })
            .then((response: any) => {
              showNotification({
                message: "Student Added In Batches",
              });
              setActive((current) => (current < 3 ? current + 1 : current));
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          if (!formValues.dateOfJoining) {
            showNotification({
              message: "Select joining date please!!",
            });
            return;
          }
          setActive((current) => (current < 3 ? current + 1 : current));
        }
      }
    } else {
      if (!props.isEditableData) {
        if (!selectedBatchId) {
          showNotification({
            message: "Add student Fees!!",
          });
          return;
        }
        setisLoading(true);
        AddStudentFeeRecords({
          studentId: studentId,
          type: customOrBatch.get(selectedBatchId) || "",
          installments: assignBatchInstallment,
        })
          .then((response) => {
            setisLoading(false);
            showNotification({
              message: "Student created cuccessfully",
            });

            if (props.setAddMoreDetail) {
              props.setAddMoreDetail(false);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        const adjustedDate = formValues.dateOfBirth
          ? new Date(formValues.dateOfBirth)
          : undefined;

        if (adjustedDate && isNaN(adjustedDate.getTime())) {
          return;
        }

        if (!selectedBatchId) {
          showNotification({
            message: "Add student Fees!!",
          });
          return;
        }

        // Adjust the date
        adjustedDate && adjustedDate.setDate(adjustedDate.getDate() + 1);
        const newBatchInstallment = new Map(batchInstallment);
        newBatchInstallment.set(selectedBatchId, installments);

        const mergeInstallments = (
          map1: Map<string, Installment[]>,
          map2: Map<string, Installment[]>
        ): Map<string, Installment[]> => {
          const mergedMap = new Map<string, Installment[]>();

          Array.from(map1.entries()).forEach(([key1, installments1]) => {
            if (map2.has(key1)) {
              const installments2 = map2.get(key1) || [];

              const filteredInstallments2 = installments2.filter(
                (installment2) =>
                  !installments1.some(
                    (installment1) => installment1._id === installment2._id
                  )
              );
              mergedMap.set(key1, [...installments1, ...filteredInstallments2]);
            } else {
              mergedMap.set(key1, installments1);
            }
          });
          Array.from(map2.entries()).forEach(([key2, installments2]) => {
            if (!mergedMap.has(key2)) {
              mergedMap.set(key2, [...installments2]);
            }
          });

          return mergedMap;
        };
        setisLoading(true);
        const uniqueNumberArray = [
          phoneNumber,
          ...additionalPhoneNumbers,
        ].filter(
          (value, index, self) => index === self.findIndex((t) => t === value)
        );
        UpdateStudent({
          id: studentId || "",
          formData: {
            name: formValues.name,
            parentName: formValues.parentName,
            phoneNumber: uniqueNumberArray,
            dateOfBirth: adjustedDate
              ? new Date(adjustedDate.toISOString().split("T")[0])
              : undefined,
            address: formValues.address,
            parentNumber: formValues.parentNumber
              ? formValues.parentNumber
              : parentPhoneNumber,
            gender: formValues.gender,
            standard: formValues.standard,
            source: formValues.source,
            instituteId: instituteDetails?._id || "",
            instituteBatches: [],
          },
          courseInfo: {
            batchIds: collectBatchIds || [],
            dateOfJoining: formValues.dateOfJoining,
          },
          feeRecords: {
            customOrBatch,
            batchId: selectedBatchId,
            installments:
              customOrBatch.get(selectedBatchId) !==
              oldCustomOrBatch.get(selectedBatchId)
                ? mergeInstallments(
                    trackingOldBatchInstallment,
                    assignBatchInstallment
                  )
                : assignBatchInstallment,
          },
        })
          .then((response: any) => {
            setisLoading(false);
            showNotification({
              message: "Student updated successfully",
            });

            if (props.onCallBack) {
              props.onCallBack();
            }

            if (props.setAddMoreDetail) {
              props.setAddMoreDetail(false);
            }
            setFormValues({
              name: "",
              parentName: "",
              phoneNumber: [],
              dateOfBirth: undefined,
              address: "",
              parentNumber: "",
              gender: "",
              standard: "",
              source: "",
              dateOfJoining: new Date(),
            });
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  };
  const prevStep = () => {
    setActive((current) => (current >= 1 ? current - 1 : current));
    setGoBackAfterCreatingStudent(true);
  };

  const updateOnlyFirstSection = () => {
    const adjustedDate = formValues.dateOfBirth
      ? new Date(formValues.dateOfBirth)
      : undefined;

    if (adjustedDate && isNaN(adjustedDate.getTime())) {
      return;
    }

    // Adjust the date
    adjustedDate && adjustedDate.setDate(adjustedDate.getDate() + 2);
    const newBatchInstallment = new Map(batchInstallment);
    newBatchInstallment.set(selectedBatchId, installments);
    UpdateStudent({
      id: studentId || "",
      formData: {
        name: formValues.name,
        parentName: formValues.parentName,
        phoneNumber: [...formValues.phoneNumber, ...additionalPhoneNumbers],
        dateOfBirth: adjustedDate
          ? new Date(adjustedDate.toISOString().split("T")[0])
          : undefined,
        address: formValues.address,
        parentNumber: formValues.parentNumber,
        gender: formValues.gender,
        standard: formValues.standard,
        source: formValues.source,
        instituteId: instituteDetails?._id || "",
        instituteBatches: [],
      },
      courseInfo: {
        batchIds: [],
        dateOfJoining: formValues.dateOfJoining,
      },
      feeRecords: {
        customOrBatch,
        batchId: selectedBatchId,
        installments: new Map(),
      },
    })
      .then((response: any) => {
        showNotification({
          message: "Student updated successfully",
        });

        if (props.setAddMoreDetail) {
          props.setAddMoreDetail(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onClickCustomOrBatch = (type: string) => {
    setCustomOrBatch((prevMap) => {
      const updatedMap = new Map(prevMap);

      if (!updatedMap.has(selectedBatchId)) {
        updatedMap.set(selectedBatchId, type);
      } else {
        updatedMap.set(selectedBatchId, type);
      }

      return updatedMap;
    });
  };

  return (
    <>
      <LoadingOverlay visible={isLoading} />
      <Stack
        w={"100%"}
        mih={"100vh"}
        align="center"
        sx={{ backgroundColor: "#ffffff", paddingTop: "2rem" }}
      >
        <Stack w={isMobile ? "98%" : "80%"}>
          <Flex align={"center"} ml={"5px"}>
            <Image
              onClick={() =>
                props.setAddMoreDetail && props.setAddMoreDetail(false)
              }
              src={require("../../../../assets/arrow-left.png")}
              alt="message"
              style={{
                width: 15,
                marginRight: "4px",
                cursor: "pointer",
              }}
            />
            <Text fz={"xl"} fw={700} ml={"0.4rem"}>
              {props.isEditableData ? "Edit " : "New "}
              Student
            </Text>
          </Flex>

          <Text fz={"sm"} fw={500} color="#D3D3D3">
            Add new student and manage their details
          </Text>
          <Stepper
            iconSize={isMobile ? 24 : 40}
            p={2}
            active={active}
            onStepClick={setActive}
            size={isMobile ? "xs" : "md"}
            allowNextStepsSelect={false}
          >
            <Stepper.Step
              label={
                <span style={{ fontSize: isMobile ? "10.5px" : "16px" }}>
                  Student Information
                </span>
              }
            ></Stepper.Step>
            <Stepper.Step
              label={
                <span style={{ fontSize: isMobile ? "10.5px" : "16px" }}>
                  Course Information
                </span>
              }
            ></Stepper.Step>
            <Stepper.Step
              label={
                <span style={{ fontSize: isMobile ? "10.5px" : "16px" }}>
                  Set Fees
                </span>
              }
            ></Stepper.Step>
          </Stepper>
        </Stack>
        <Stack w={"100%"}>
          <Divider size={1} color="#BABABA" />
        </Stack>

        {active === 0 && (
          <Container w={isMobile ? "98%" : "100%"}>
            <Text fz={"sm"} fw={500} color="#D3D3D3" my={10}>
              Student Information
            </Text>
            <Grid>
              <Grid.Col span={isMobile ? 12 : 6}>
                <TextInput
                  label="Student Name"
                  placeholder="Enter student name here!"
                  required
                  error={showError && "Student name Required"}
                  radius={"md"}
                  value={formValues.name}
                  onChange={(event) =>
                    handleInputChange("name", event.currentTarget.value)
                  }
                  styles={{ input: { borderWidth: 2 } }}
                />
              </Grid.Col>
              <Grid.Col span={isMobile ? 12 : 6}>
                <TextInput
                  label="Parent Name"
                  placeholder="Enter parent name here!"
                  value={formValues.parentName}
                  radius={"md"}
                  onChange={(event) =>
                    handleInputChange("parentName", event.currentTarget.value)
                  }
                  styles={{ input: { borderWidth: 2 } }}
                />
              </Grid.Col>
              <Grid.Col span={isMobile ? 12 : 6}>
                <Text
                  fz={14}
                  style={{ display: "block", marginBottom: "0.5em" }}
                >
                  Phone Number
                </Text>
                <PhoneInput
                  country="in"
                  value={formValues?.phoneNumber[0]}
                  onChange={(value?: string | undefined) => {
                    if (value) {
                      var finalPhoneNum = value.toString();
                      if (finalPhoneNum[0] == "0") {
                        finalPhoneNum = finalPhoneNum.substring(1);
                      }
                      setPhoneNumber(`+${finalPhoneNum}`);
                    }
                  }}
                  containerStyle={{
                    height: "36px",
                  }}
                  inputStyle={{
                    width: "100%",
                    height: "100%",
                    border: "solid 0.5px #00000040",
                  }}
                />
                {
                  showError &&
                <Text
                  fz={14}
                  color="red"
                  style={{ display: "block", marginBottom: "0.5em" }}
                >
                  Phone number required
                </Text>
                }
                {additionalPhoneNumbers.map((phoneNumber, index) => {
                  return (
                    <Grid grow align="center" sx={{ paddingTop: "0.5px" }}>
                      <Grid.Col span={10}>
                        <PhoneInput
                          country="in"
                          placeholder="Enter phone number"
                          value={additionalPhoneNumbers[index]}
                          onChange={(value?: string | undefined) => {
                            if (value) {
                              var finalPhoneNum = value.toString();
                              if (finalPhoneNum[0] == "0") {
                                finalPhoneNum = finalPhoneNum.substring(1);
                              }

                              additionalPhoneNumbers[
                                index
                              ] = `+${finalPhoneNum}`;
                              setAdditionalPhoneNumbers([
                                ...additionalPhoneNumbers,
                              ]);
                            }
                          }}
                          containerStyle={{
                            height: "36px",
                          }}
                          inputStyle={{
                            width: "100%",
                            height: "100%",
                            border: "solid 0.5px #00000040",
                          }}
                        />
                      </Grid.Col>
                      <Grid.Col span={1}>
                        <IconX
                          cursor="pointer"
                          onClick={() => {
                            additionalPhoneNumbers.splice(index, 1);
                            setAdditionalPhoneNumbers([
                              ...additionalPhoneNumbers,
                            ]);
                          }}
                        ></IconX>
                      </Grid.Col>
                    </Grid>
                  );
                })}
                <Button
                  mt={8}
                  variant="subtle"
                  leftIcon={<IconPlus size={15} />}
                  onClick={() => {
                    setAdditionalPhoneNumbers([...additionalPhoneNumbers, ""]);
                  }}
                >
                  Add Phone Number
                </Button>
              </Grid.Col>
              <Grid.Col span={isMobile ? 12 : 6}>
                <Text
                  fz={14}
                  style={{ display: "block", marginBottom: "0.5em" }}
                >
                  Parent Phone Number
                </Text>
                <PhoneInput
                  country="in"
                  placeholder="Enter phone number"
                  value={formValues.parentNumber}
                  onChange={(value?: string | undefined) => {
                    if (value) {
                      var finalPhoneNum = value.toString();
                      if (finalPhoneNum[0] == "0") {
                        finalPhoneNum = finalPhoneNum.substring(1);
                      }
                      setParentPhoneNumber(`+${finalPhoneNum}`);
                    }
                  }}
                  containerStyle={{
                    height: "36px",
                  }}
                  inputStyle={{
                    width: "100%",
                    height: "100%",
                    border: "solid 0.5px #00000040",
                  }}
                />
              </Grid.Col>
              <Grid.Col span={isMobile ? 12 : 6}>
                <DatePicker
                  label="Date Of Birth"
                  placeholder="Select Date"
                  radius={"md"}
                  value={formValues.dateOfBirth}
                  onChange={(date) => {
                    handleInputChange("dateOfBirth", date);
                  }}
                />
              </Grid.Col>
              <Grid.Col span={isMobile ? 12 : 6}>
                <TextInput
                  label="Address"
                  placeholder="Enter address here!"
                  radius={"md"}
                  value={formValues.address}
                  onChange={(event) =>
                    handleInputChange("address", event.currentTarget.value)
                  }
                  styles={{ input: { borderWidth: 2 } }}
                />
              </Grid.Col>
              <Grid.Col span={isMobile ? 12 : 6}>
                <Select
                  label="Gender"
                  placeholder="Select"
                  data={["Male", "Female", "Other"]}
                  radius={"md"}
                  value={formValues.gender}
                  onChange={(value) => handleInputChange("gender", value)}
                />
              </Grid.Col>
              <Grid.Col span={isMobile ? 12 : 6}>
                <TextInput
                  label="Standard"
                  placeholder="Enter Standard"
                  value={formValues.standard}
                  radius={"md"}
                  onChange={(event) =>
                    handleInputChange("standard", event.currentTarget.value)
                  }
                  styles={{ input: { borderWidth: 2 } }}
                />
              </Grid.Col>
              <Grid.Col span={isMobile ? 12 : 6}>
                <TextInput
                  label="Source"
                  placeholder="Enter Source"
                  value={formValues.source}
                  radius={"md"}
                  onChange={(event) =>
                    handleInputChange("source", event.currentTarget.value)
                  }
                  styles={{ input: { borderWidth: 2 } }}
                />
              </Grid.Col>
            </Grid>
            <Space h="md" />
          </Container>
        )}
        {active === 1 && (
          <Stack
            w={"100%"}
            mih={"100%"}
            justify="start"
            align="start"
            sx={{ backgroundColor: "#ffffff", flex: 1 }}
          >
            <Divider color="#BABABA" />
            <Container w={isMobile ? "98%" : "80%"}>
              <Text fz={"sm"} fw={500} color="#D3D3D3" my={10}>
                Course Information
              </Text>
              <Grid>
                <Grid.Col span={isMobile ? 12 : 6}>
                  <MultiSelect
                    label="Assign Batches"
                    placeholder="Select"
                    defaultValue={collectBatchIds || []}
                    data={
                      (props.batchList &&
                        Array.from(props.batchList).map(([courseId, name]) => ({
                          label: name,
                          value: courseId,
                        }))) ||
                      []
                    }
                    onChange={(selectedValues: string[]) =>
                      onChangeAssigningBatch(selectedValues)
                    }
                  />
                </Grid.Col>
                <Grid.Col span={isMobile ? 12 : 6}>
                  <DatePicker
                    label="Date Of Joining"
                    placeholder="Select Date"
                    radius={"md"}
                    value={formValues.dateOfJoining}
                    disabled={!collectBatchIds}
                    onChange={(date) =>
                      handleInputChange("dateOfJoining", date)
                    }
                  />
                </Grid.Col>
              </Grid>
              <Space h="md" />
            </Container>
            <Modal
              opened={openNextModel}
              onClose={() => setOpenNextModel(false)}
              title="Confirm"
              centered
              zIndex={999}
              styles={{
                title: {
                  fontSize: 20,
                  fontWeight: 700,
                },
              }}
            >
              <Text>
                Do you want to {props.isEditableData ? "Update" : "join"}{" "}
                without adding batches or courses.{" "}
                {props.isEditableData
                  ? " All the previous fee records will be deleted on updating"
                  : ""}
              </Text>
              <Group position="center" sx={{ top: "0" }} mt="xl">
                <Button
                  variant="default"
                  onClick={() => setOpenNextModel(false)}
                >
                  No
                </Button>
                <Button variant="outline" onClick={updateOnlyFirstSection}>
                  Yes
                </Button>
              </Group>
            </Modal>
          </Stack>
        )}
        {active === 2 && (
          <Container w={"100%"} sx={{ flex: 1 }}>
            <Text fz={"sm"} fw={500} color="#D3D3D3" my={20}>
              Fee Information
            </Text>
            <Flex w={"100%"} justify={"start"} gap={20}>
              <Select
                w={"10rem"}
                label="Select Batch "
                placeholder="select"
                defaultValue={assignedBatch.get(selectedBatchId!)}
                data={
                  (assignedBatch &&
                    Array.from(assignedBatch).map(([batchId, name]) => ({
                      label: name,
                      value: batchId,
                    }))) ||
                  []
                }
                onChange={(value: string) => selectTheBatchId(value)}
              />
              <Select
                w={"10rem"}
                label="Fee Scheme"
                placeholder="select Fee"
                data={["Custom", "Batch"].map((i) => i)}
                value={customOrBatch.get(selectedBatchId)}
                disabled={!selectedBatchId}
                onChange={(i: string) => onClickCustomOrBatch(i)}
              />
            </Flex>
            {selectedBatchId && (
              <Box sx={{ width: "100%", overflowX: "auto" }}>
                <Table sx={{ marginTop: "2rem" }}>
                  <thead>
                    <tr
                      style={{ border: "0.5px solid #D3D3D3", padding: "8px" }}
                    >
                      <th
                        style={{
                          border: "0.5px solid #D3D3D3",
                          padding: "8px",
                        }}
                      >
                        S No.
                      </th>
                      <th
                        style={{
                          border: "0.5px solid #D3D3D3",
                          padding: "8px",
                        }}
                      >
                        Name
                      </th>
                      <th
                        style={{
                          border: "0.5px solid #D3D3D3",
                          padding: "8px",
                        }}
                      >
                        Due Date
                      </th>
                      <th
                        style={{
                          border: "0.5px solid #D3D3D3",
                          padding: "8px",
                        }}
                      >
                        Amount in ₹
                      </th>
                      {customOrBatch.get(selectedBatchId) !== "Batch" && (
                        <th
                          style={{
                            border: "0.5px solid #D3D3D3",
                            padding: "8px",
                          }}
                        >
                          Action
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody
                    style={{ border: "0.5px solid #D3D3D3", padding: "0.5px" }}
                  >
                    {installments?.map((installment, index) => (
                      <tr
                        style={{
                          border: "0.5px solid #D3D3D3",
                          padding: "8px",
                        }}
                        key={index}
                      >
                        <td
                          style={{
                            border: "0.5px solid #D3D3D3",
                            padding: "8px",
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{
                            border: "0.5px solid #D3D3D3",
                            padding: "8px",
                          }}
                        >
                          <TextInput
                            value={installment.name}
                            onChange={(event) =>
                              handleInstallmentChange(
                                index,
                                "name",
                                event.currentTarget.value
                              )
                            }
                            readOnly={
                              customOrBatch.get(selectedBatchId) === "Batch"
                            }
                          />
                        </td>
                        <td
                          style={{
                            border: "0.5px solid #D3D3D3",
                            padding: "8px",
                          }}
                        >
                          <TextInput
                            type="date"
                            value={installment.dueDate}
                            onChange={(event) =>
                              handleInstallmentChange(
                                index,
                                "dueDate",
                                event.currentTarget.value
                              )
                            }
                            readOnly={
                              customOrBatch.get(selectedBatchId) === "Batch"
                            }
                          />
                        </td>
                        <td
                          style={{
                            border: "0.5px solid #D3D3D3",
                            padding: "8px",
                          }}
                        >
                          <NumberInput
                            value={installment.amount}
                            onChange={(value: number) =>
                              handleInstallmentChange(index, "amount", value)
                            }
                            min={0}
                            max={1000000}
                            hideControls
                            readOnly={
                              customOrBatch.get(selectedBatchId) === "Batch"
                            }
                          />
                        </td>
                        {customOrBatch.get(selectedBatchId) !== "Batch" && (
                          <td
                            style={{
                              border: "0.5px solid #D3D3D3",
                              padding: "8px",
                            }}
                          >
                            {!(index === 0) && (
                              <ActionIcon
                                color="red"
                                onClick={() => {
                                  handleInstallmentChange(
                                    index,
                                    "isDeleted",
                                    true
                                  );
                                  handleRemoveInstallment(index);
                                }}
                              >
                                <IconTrash size={"30"} />
                              </ActionIcon>
                            )}
                          </td>
                        )}
                      </tr>
                    ))}
                    {customOrBatch.get(selectedBatchId) !== "Batch" && (
                      <Text
                        onClick={handleAddInstallment}
                        color="blue"
                        mt="md"
                        sx={{ whiteSpace: "nowrap" }}
                      >
                        + Add Installment
                      </Text>
                    )}
                  </tbody>
                </Table>
              </Box>
            )}
          </Container>
        )}

        <Stack w={"100%"}>
          <Divider size={1} color="#BABABA" />
        </Stack>
        <Group position="center" sx={{ top: "0" }} mb="xl">
          <Button disabled={active === 0} variant="default" onClick={prevStep}>
            <Group spacing="xs">
              <Image
                src={require("../../../../assets/arrow-left.png")}
                alt="message"
                style={{ width: 15 }}
              />
              <span>Back</span>
            </Group>
          </Button>
          <Button onClick={nextStep}>
            <Group spacing="xs">
              <span>{active < 2 ? "Next " : "Save & Add"}</span>
              <Image
                src={require("../../../../assets/arrow-right.png")}
                alt="message"
                style={{ width: 15 }}
              />
            </Group>
          </Button>
        </Group>
      </Stack>
    </>
  );
};

export default AddMoreDetails;
