
export const findFolderById = (
    folders: CourseFolder[],
    targetId: string
  ): CourseFolder | null => {
    for (let folder of folders) {
      if (folder._id === targetId) {
        return folder;
      }
      if (folder.folders.length > 0) {
        const foundFolder = findFolderById(folder.folders, targetId);
        if (foundFolder) {
          return foundFolder;
        }
      }
    }
    return null;
  };
  
  export const updateFileNameInFolder = (
    folder: CourseFolder,
    fileId: string,
    newFileName: string
  ): CourseFolder => {
    return {
      ...folder,
      files: folder.files.map((file) => {
        if (file._id === fileId) {
          return {
            ...file,
            name: newFileName,
          };
        }
        return file; 
      }),
    };
  };
  export const updateTestNameInFolder = (
    folder: CourseFolder,
    fileId: string,
    newFileName: string
  ): CourseFolder => {
    return {
      ...folder,
      tests: folder.tests.map((test) => {
        if (test._id === fileId) {
          return {
            ...test,
            name: newFileName,
          };
        }
        return test; 
      }),
    };
  };
  export const updateVideoNameInFolder = (
    folder: CourseFolder,
    fileId: string,
    newFileName: string
  ): CourseFolder => {
    
    return {
      ...folder,
      videos: folder.videos.map((video) => {
        if (video._id === fileId) {
          return {
            ...video,
            name: newFileName,
          };
        }
        return video; 
      }),
    };
  };
  
  export const removeFileFromCurrentFolder = (
    currentFolder: CourseFolder,
    fileId: string
  ): CourseFolder => {
    return {
      ...currentFolder,
      files: currentFolder.files.filter((file) => file._id !== fileId),
    };
  };
  
  export const updateFolderInTree = (
    folders: CourseFolder[],
    updatedFolder: CourseFolder
  ): CourseFolder[] => {
    return folders.map((folder) => {
      if (folder._id === updatedFolder._id) {
        return updatedFolder; 
      }
  
      if (folder.folders.length > 0) {
        return {
          ...folder,
          folders: updateFolderInTree(folder.folders, updatedFolder),
        };
      }
  
      return folder; 
    });
  };
  
  export const removeFolderFromTree = (
    folders: CourseFolder[],
    folderId: string
  ): CourseFolder[] => {
    return folders.reduce((result, folder) => {
      if (folder._id === folderId) {
        return result;
      }
  
      const updatedSubfolders = removeFolderFromTree(folder.folders, folderId);
  
      result.push({
        ...folder,
        folders: updatedSubfolders,
      });
  
      return result;
    }, [] as CourseFolder[]);
  };
  
  export  const removeCurrentFolderFromTree = (
    folders: CourseFolder[],
    currentFolderId: string
  ): CourseFolder[] => {
    return folders
      .map((folder) => {
        if (folder._id === currentFolderId) {
          return null;
        }
  
        if (folder.folders.length > 0) {
          return {
            ...folder,
            folders: removeCurrentFolderFromTree(folder.folders, currentFolderId),
          };
        }
  
        return folder;
      })
      .filter((folder) => folder !== null) as CourseFolder[];
  };
  
  export const removeSubfolder = (parentFolder: CourseFolder, folderId: string): CourseFolder => {
    const updatedFolders = parentFolder.folders.filter((item) => item._id !== folderId);
  
    return {
      ...parentFolder,
      folders: updatedFolders,
    };
  };
  export const removeSubfileFromFolder = (parentFolder: CourseFolder, fileId: string): CourseFolder => {
    const updatedFolders = parentFolder.files.filter((item) => item._id !== fileId);
  
    return {
      ...parentFolder,
      files: updatedFolders,
    };
  };
  export const removeTestFromFolder = (parentFolder: CourseFolder, fileId: string): CourseFolder => {
    const updatedFolders = parentFolder.tests.filter((item) => item._id !== fileId);
  
    return {
      ...parentFolder,
      tests: updatedFolders,
    };
  };
  export const removeVideoFromFolder = (parentFolder: CourseFolder, fileId: string): CourseFolder => {
    const updatedFolders = parentFolder.videos.filter((item) => item._id !== fileId);
  
    return {
      ...parentFolder,
      videos: updatedFolders,
    };
  };
  