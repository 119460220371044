import React, { act, useEffect, useRef, useState } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { GroupProps, ThreeEvent } from '@react-three/fiber';
import * as THREE from 'three';


import { Html, useProgress } from '@react-three/drei'



interface ModelProps extends GroupProps {
  modelPath: string;
  hideState?: boolean;
  isPlaying: boolean;
  animate: boolean;
  selectedStepDetails:any,
  setIsPlaying: (value: boolean) => void;
}

interface GLTFResult {
  nodes: { [key: string]: THREE.Mesh };
  materials: { [key: string]: THREE.Material };
  scene: THREE.Group;
  animations: THREE.AnimationClip[];
}

export function DynamicModel({ modelPath, hideState, isPlaying, setIsPlaying, animate,selectedStepDetails, ...props }: ModelProps) {
  const {scene, animations } = useGLTF(modelPath) as unknown as GLTFResult;
  const groupRef = useRef<THREE.Group>(null);
  const { mixer } = useAnimations(animations, groupRef);
  const [time,setTime]  =useState<number>(0);

  useEffect(() => {
    mixer.clipAction(animations[0]).paused =true
    setTime(selectedStepDetails.startTime)
    const action = mixer.clipAction(animations[0])
    if(isPlaying){
    action.paused =false
    action.play();
    action.time = selectedStepDetails.startTime;
    action.loop =THREE.LoopRepeat
    }
  }, [selectedStepDetails,isPlaying]);
  useEffect(()=>{
    let intervalId: any;
    if (time < selectedStepDetails.endTime && isPlaying) {
      intervalId = setInterval(() => setTime(time+1), 1000);
    }
    else{
      mixer.clipAction(animations[0]).paused =true
    }
    return () => clearInterval(intervalId);
  },[time,isPlaying])



  return (
    <>
      <directionalLight position={[0, 0, 5]} intensity={0.6} />
      <primitive
        ref={groupRef}
        {...props}
        object={scene}
              />
    </>
  );
}


export function ModelLoader({ modelPath, ...props }: ModelProps) {
  const [isPlaying, setIsPlaying] = useState(props.isPlaying);

  useEffect(() => {
    setIsPlaying(props.isPlaying);
  }, [props.isPlaying]);

  return (
    <DynamicModel
      modelPath={modelPath}
      {...props}
      isPlaying={isPlaying}
    />
  );
}
