import React from "react";
import { Box, Text, Image, Flex } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
interface CustomButtonProps {
  imageSrc: string;
  label: string;
  isActive: boolean;
  onClick: () => void;
}

const MethaneSimulationButton: React.FC<CustomButtonProps> = ({
  imageSrc,
  label,
  isActive,
  onClick,
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <Box>
      <Flex
        sx={{
          flexDirection: "column",
          alignItems: "center",
          cursor: "pointer",
          border: `3px solid ${isActive ? "#FFA41C" : "transparent"}`,
          borderRadius: "8px",
          justifyContent:"center",
          padding: "8px",
          boxShadow: '1px 4px 10px rgba(255, 255, 255, 0.6)',
          backgroundColor: "black",
          width: isMobile ? "50px" : "80px",
          height: isMobile ? "50px" : "80px",
          overflow: "hidden",
        }}
        onClick={onClick}
      >
        <Image
          src={imageSrc}
          alt={label}
          sx={{
            objectFit: "cover",
            width: "100%",
            height: "100%",
          }}

        />
      </Flex>
      <Text color="white" mt="xs" size={isMobile ? "xs" : "sm"} align="center">
        {label}
      </Text>
    </Box>
  );
};

export default MethaneSimulationButton;
