import { Button, Group, LoadingOverlay, Modal, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getBaseUrlWithInstituteNameAndId } from "../../utilities/HelperFunctions";
import { StudentLogin } from "./StudentLogin";
import { InstituteLoginPage } from "./InstituteLoginPage";
import { useSelector } from "react-redux";
import { RootState } from "../../store/ReduxStore";
import {
  AddExistingStudentsInBatch,
  GetStudentUserInfo,
} from "../features/instituteStudentSlice";

const AddToBatchViaLink = () => {
  const navigate = useNavigate();
  const url = new URL(window.location.href);

  const [studentId, setStudentId] = useState<string[]>([]);
  const [batchId, setBatchId] = useState<string>();
  const [isLogedIn, setIsLogedIn] = useState<boolean>(false);
  const [batchJoinUrl, setBatchJoinUrl] = useState<string>(window.location.href);
  const [baseUrl, setBaseUrl] = useState<string>("");

  const [isTeacherLogin, setIsTeacherLogin] = useState<boolean>(false);
  const [isLoading, setisLoading] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [copyText,setCopyText] = useState<boolean>(false)

  useEffect(() => {
    const TOKEN = localStorage.getItem("TOKEN");
    // setBatchJoinUrl(window.location.href)
    const pathParts = url.pathname.split("/");
    const basePath = pathParts.slice(1, 3).join("/");
    
    // Assuming the instituteId is always the third part in the path
    const dynamicPathIndex = 2;
    const instituteIdPart = pathParts[dynamicPathIndex];
    const searchParams = new URLSearchParams(url.search);
    const extractedBatchId = searchParams.get("batchId");
    
    if (extractedBatchId) {
      setBatchId(extractedBatchId);
    }
    setBaseUrl(basePath);

    if (TOKEN) {
      setIsLogedIn(true);
      fetchStudentInfo(instituteIdPart);
    } else {
      setIsLogedIn(false);
      if(batchJoinUrl){
        navigate(`/${basePath}/parent?batchJoinUrl=${batchJoinUrl}`);
      }
    }
  }, []);

  const fetchStudentInfo = (instituteId: string) => {
    GetStudentUserInfo({ instituteId })
      .then((x: any) => {
        setisLoading(false)
        setStudentId([x[0]?._id]);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    AddExistingStudentsInBatch({
      studentIds: studentId,
      batchId: batchId || "",
    })
      .then((x: any) => {
        setOpenModal(true);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [studentId]);

  const goToHome = () =>{
    setOpenModal(false)
    window.location.href = `${url.origin}/${baseUrl}/parent`
  }

  return (
    <>
    <LoadingOverlay visible={isLoading} zIndex={1002} pos={"fixed"} />
      {!isLogedIn && !isTeacherLogin && (
        <InstituteLoginPage batchJoinUrl={batchJoinUrl} />
      )}
      <Modal
        opened={openModal}
        onClose={() => setOpenModal(false)}
        withCloseButton={false}
        title="Added To Batch"
        centered
      >
        <Text fw={700}  >you are successfully added in batch, Go To Home</Text>
        <Group position={"center"}>
        <Button
          onClick={goToHome}
          variant="default"
          radius="xl"
          sx={{ marginTop:"2rem", border: "1px #808080 solid" }}
        >
          Home
        </Button>
        </Group>
      </Modal>
    </>
  );
};

export default AddToBatchViaLink;
