import React, { useState } from "react";
import { Checkbox, Slider, TextInput, Stack, Group, Text } from "@mantine/core";
import { getLanguageEnumByKeyForPendulumModel } from "../../../assets/LanguageEnums/SimulationEnumFunction";
import { useMediaQuery } from "@mantine/hooks";
interface pendulumControlprop {
  lengthRed: number;
  lengthBlue: number;
  setLengthRed: (lengthRed: number) => void;
  setLengthBlue: (lengthBlue: number) => void;
  massRed: number;
  massBlue: number;
  setMassRed: (massRed: number) => void;
  setMassBlue: (massBlue: number) => void;
  isTwoPendulum: boolean;
  setIsTwoPendulum: (isTwoPendulum: boolean) => void;
  userLanguage: string;
}
const PendulumControls: React.FC<pendulumControlprop> = ({
  lengthRed,
  lengthBlue,
  setLengthRed,
  setLengthBlue,
  massRed,
  massBlue,
  setMassRed,
  setMassBlue,
  isTwoPendulum,
  setIsTwoPendulum,
  userLanguage,
  // setUserLanguage
}) => {
  const isMediumScreen = useMediaQuery("(max-width: 1072px)");
  return (
    <div
      style={{
        border: "1px solid #ccc",
        padding: "20px",
        borderRadius: "8px",
        width: isMediumScreen ? "80vw" : "20vw",
      }}
    >
      <Stack spacing="sm">
        <Checkbox
          label={getLanguageEnumByKeyForPendulumModel({
            key: "Two_Pendulums_Toggle",
            LanguageId: userLanguage,
          })}
          checked={isTwoPendulum}
          onChange={(event) => setIsTwoPendulum(event.currentTarget.checked)}
        />

        {/* Controls for the Red Pendulum */}
        <Group spacing="xs" align="center">
          <Text>
            {getLanguageEnumByKeyForPendulumModel({
              key: "Length1",
              LanguageId: userLanguage,
            })}
          </Text>
          <TextInput
            value={`${lengthRed} cm`}
            readOnly
            style={{ width: "35%", textAlign: "center" }}
          />
        </Group>
        <Slider
          value={lengthRed}
          onChange={setLengthRed}
          min={200}
          max={400}
          color="violet"
          style={{ width: "100%" }}
        />
        <Group spacing="xs" align="center">
          <Text>
            {getLanguageEnumByKeyForPendulumModel({
              key: "Mass1",
              LanguageId: userLanguage,
            })}
          </Text>
          <TextInput
            value={`${massRed} kg`}
            readOnly
            style={{ width: "35%", textAlign: "center" }}
          />
        </Group>
        <Slider
          value={massRed}
          onChange={setMassRed}
          min={20}
          max={80}
          color="violet"
          style={{ width: "100%" }}
        />

        {/* Conditionally render controls for the Blue Pendulum */}
        {isTwoPendulum && (
          <>
            <hr />
            <Group spacing="xs" align="center">
              <Text>
                {" "}
                {getLanguageEnumByKeyForPendulumModel({
                  key: "Length2",
                  LanguageId: userLanguage,
                })}
              </Text>
              <TextInput
                value={`${lengthBlue} cm`}
                readOnly
                style={{ width: "35%" }}
              />
            </Group>
            <Slider
              value={lengthBlue}
              onChange={setLengthBlue}
              min={200}
              max={400}
              color="violet"
              style={{ width: "100%" }}
            />
            <Group spacing="xs" align="center">
              <Text>
                {" "}
                {getLanguageEnumByKeyForPendulumModel({
                  key: "Mass2",
                  LanguageId: userLanguage,
                })}
              </Text>
              <TextInput
                value={`${massBlue} kg`}
                readOnly
                style={{ width: "35%", textAlign: "center" }}
              />
            </Group>
            <Slider
              value={massBlue}
              onChange={setMassBlue}
              min={20}
              max={80}
              color="violet"
              style={{ width: "100%" }}
            />
          </>
        )}
      </Stack>
    </div>
  );
};

export default PendulumControls;
