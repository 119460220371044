import {
  Badge,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Group,
  LoadingOverlay,
  Menu,
  Modal,
  ScrollArea,
  Stack,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  IconBrandFacebook,
  IconBrandWhatsapp,
  IconMail,
  IconMessage2,
  IconShare,
} from "@tabler/icons";
import { Dispatch, SetStateAction, useState } from "react";
import {
  getBaseUrlWithInstituteNameAndId,
  sendMessage,
} from "../../utilities/HelperFunctions";
import { Icon } from "../../pages/_New/Teach";

interface EmptyListViewProps {
  emptyImage: string;
  emptyMessage: string;
  showButton: boolean;
  btnText?: string;
  onAddButtonClick?: () => void;
  batchId?: string;
}

export function EmptyListView(props: EmptyListViewProps) {
  const [openShareModal, setOpenShareModal] = useState<boolean>(false);
  const [copyText, setCopyText] = useState<boolean>(false);
  const [modifiedURL, setModifiedUrl] = useState<string>("");

  const createBatchLinkForShare = () => {
    const batchId = props.batchId;
    const url = new URL(window.location.href);
    const basePath = getBaseUrlWithInstituteNameAndId(url);
    const modifiedPath = `${basePath}/addToBatch?batchId=${batchId}`;

    setModifiedUrl(modifiedPath);
    setOpenShareModal(true);
  };
  return (
    <Center h="50vh" w="100%">
      <Stack align="center" justify="center">
        <Box
          style={{
            backgroundColor: "#EEF4FF",
            borderRadius: "50%",
            height: 148,
            width: 148,
          }}
        >
          <Center h="100%" w="100%">
            <img src={props.emptyImage} width="50%" height="50%" />
          </Center>
        </Box>
        <Text color="#A4A4A4" fw={500}>
          {props.emptyMessage}
        </Text>
        {props.showButton && (
          <Button
            bga="#3174F3"
            style={{
              borderRadius: "20px",
            }}
            color="white"
            size="md"
            onClick={() => {
              if(props.onAddButtonClick){
                props.onAddButtonClick();
              }
            }}
          >
            {props.btnText}
          </Button>
        )}
        <Button
          variant="default"
          radius="xl"
          style={{ border: "1px #808080 solid" }}
          onClick={createBatchLinkForShare}
        >
          <IconShare color="black" width={15} style={{ marginRight: "4px" }} />
          Share Batch Link
        </Button>
        <Modal
          opened={openShareModal}
          onClose={() => {
            setOpenShareModal(false);
          }}
          title="Share batch joining link with students!"
          centered
        >
          <Stack>
            <Flex>
              <FacebookShareButton url={modifiedURL}>
                <Icon
                  name="Facebook"
                  icon={
                    <IconBrandFacebook height={24} width={24} color="white" />
                  }
                  onClick={() => {}}
                  color="#1776F1"
                />
              </FacebookShareButton>

              <WhatsappShareButton url={modifiedURL}>
                <Icon
                  name="Whatsapp"
                  icon={<IconBrandWhatsapp color="white" />}
                  onClick={() => {}}
                  color="#43C553"
                />
              </WhatsappShareButton>

              <EmailShareButton url={modifiedURL}>
                <Icon
                  name="Email"
                  icon={<IconMail color="white" />}
                  onClick={() => {}}
                  color="#E0534A"
                />
              </EmailShareButton>
              <Icon
                name="Message"
                icon={<IconMessage2 color="white" />}
                onClick={() => {
                  sendMessage(modifiedURL);
                }}
                color="#0859C5"
              />
            </Flex>
            <Flex justify="space-between" align="center">
              <TextInput
                style={{
                  marginRight: "5px",
                  height: "40px",
                  width: "95%",
                }}
                value={modifiedURL}
              ></TextInput>
              <CopyToClipboard text={modifiedURL}>
                <Button
                  bg="#3174F3"
                  style={{
                    borderRadius: "20px",
                  }}
                  onClick={() => {
                    setCopyText(true);
                  }}
                >
                  {copyText ? "Copied" : "Copy"}
                </Button>
              </CopyToClipboard>
            </Flex>
          </Stack>
        </Modal>
      </Stack>
    </Center>
  );
}
