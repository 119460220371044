import React, { useState,useEffect } from "react";
import {
  Button,
  Stack,
  Box,
  Text,
  Overlay,
  Flex,
  Image,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import MethaneSimulationButton from "./components/FooterButton";
import MoleculeDropdown from "./components/DropdownMenu";
import { OrbitControls, useGLTF } from '@react-three/drei';
import { Canvas  } from '@react-three/fiber';
import { getSimulationById } from "../../features/Simulations/getSimulationSlice";
const methane3D="https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-11-20T12-44-00-818Z.png";
const methaneDescription="https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-11-20T12-45-07-522Z.png";
const closeImg="https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-11-20T12-45-57-588Z.png"
enum MoleculeType {
  Condensed = "condensed",
  Lewis = "lewis",
  BondLine = "bond line",
  Structure3D = "3d structure",
  Description = "description"
}

const modelUrlRoute=process.env.REACT_APP_AWS_MODEL_LINK;

interface ButtonData {
  imageSrc: string;
  label: string;
}

interface MoleculeData {
  name: string; 
  condensed: string; 
  bond_line: string; 
  lewis: string; 
  model_link: string; 
  description: string;
  shape:string;
  uses?:string;
}

interface GLTFModelProps {
  model_link: string;
}

const maincloseImg =
  "https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-07-19T08-08-49-765Z.png";
  const GLTFModel: React.FC<GLTFModelProps> = ({ model_link }) => {
    const { scene } = useGLTF(model_link); // Use `structure_3d` prop here
  
    return <primitive object={scene} scale={0.5} />;
  };
const MethaneSimulationWrapper: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 833px)");
  const [activeButton, setActiveButton] = useState<MoleculeType>(MoleculeType.Condensed);

  // <...........API FETCH STATE...................................................>
  const [SimulationData, setSimulationData] = useState<MoleculeData[]>();
  const [MoleculeData, setMoleculeData] = useState<MoleculeData | null>();
// <.....................................................................................>

  // <.....................API FETCH LOGIC................>
  const path = window.location.pathname;
  const currentPath = window.location.pathname;
  const segments = currentPath.split("/").filter(Boolean);
  const lastSegment = segments[segments.length - 1];
  useEffect(() => {
    getSimulationById({id:lastSegment})
      .then((simulation: any) => {
        setSimulationData(simulation.data);
        setMoleculeData(simulation.data[0])
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
// <.......................................................................>

  const buttonData: ButtonData[] = [
    { imageSrc: `${MoleculeData?.condensed}`, label: "Condensed" },
    { imageSrc: `${MoleculeData?.lewis}`, label: "Lewis" },
    { imageSrc: `${MoleculeData?.bond_line}`, label: "Bond Line" },
    { imageSrc: `${methane3D}`, label: "3D Structure" },
    { imageSrc: `${methaneDescription}`, label: "Description" },
  ];

  const handleclosebutton = () => {
    setActiveButton(MoleculeType.Condensed);
  };

  const handleDropdownChange = (name: string) => {
    const selectedMolecule = SimulationData?.find(
      (molecule) => molecule.name === name
    );
    if (selectedMolecule) {
      setMoleculeData(selectedMolecule); // Update moleculeData state with selected molecule data
    }
  };

  // Logic behind the mobile responsive footer button display
  const displayedButtons = isMobile ? buttonData : buttonData.slice(0, 4); 


  return (
    <Flex
      style={{
        height: "100vh",
        width: "100vw",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        backgroundColor: "black",
      }}
    >
      {/* Description Component */}

      {!isMobile && (
        <Box
          style={{
            position: "absolute",
            top: isMobile ? "5%" : "2%",
            left: isMobile ? "5%" : "2%",
            maxWidth: isMobile ? "90%" : "30%",
          }}
        >
          <Box
            style={{
              border: "0.1875rem solid #BABABA", 
              padding: "1rem",
              maxWidth: "250px",
              maxHeight: "auto",
              borderRadius:'1rem'
            }}
          >
            <Text
              style={{
                fontFamily: "Gloria Hallelujah, sans-serif",
                fontWeight: 400,
                textAlign: "left",
                textUnderlinePosition: "from-font",
                textDecorationSkipInk: "none",
                color: "white",
              }}
            >
              <strong>Description:</strong>
              <br />
              {MoleculeData?.description}
              <br />
              <br />
              <strong>Uses:</strong>
              <br />
             {MoleculeData?.uses}
            </Text>
          </Box>
        </Box>
      )}

      {/* Dropdown  */}
      <Box
        style={{
          padding: "20px",
          backgroundColor: "#333",
          color: "#fff",
          borderRadius:'1rem',
          position: "absolute",
          top: isMobile ? "1%" : "9%",
          right: isMobile ? "23%" : "2%",
          margin: 0,
        }}
      >
        <MoleculeDropdown
          options={SimulationData?.map((molecule) => molecule.name)}
          selected={MoleculeData?.name || ""}
          onChange={handleDropdownChange}
        />
      </Box>
      {/* Close Button */}
      <Button
        color="red"
        style={{
          position: "absolute",
          top: isMobile ? "1%" : "2%",
          right: isMobile ? "5%" : "2%",
          margin: 0,
          padding: 0,
        }}
        onClick={() => navigate(-2)}
      >
        <img
          src={maincloseImg}
          alt="close"
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </Button>

      {/* Image Display */}
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "60vh",
          width: "80vw",
          color: "#fff",
          fontSize: isMobile ? "1.5rem" : "2rem",
        }}
      >
        {activeButton === MoleculeType.Condensed && (
        
          <Image
            src={MoleculeData?.condensed}
            alt="ch4"
            fit="contain" 
            width="100%"
            height="50px" 
            sx={{
              objectFit: "cover",
              
            }}
          />
        )}
        {activeButton === MoleculeType.Lewis && (
          <Image
            src={MoleculeData?.lewis}
            alt="ch4"
            fit="contain" 
            width="100%" 
            height="150px" 
            sx={{
              objectFit: "contain", 
            }}
          />
        )}
        {activeButton === MoleculeType.BondLine && (
          <Image
            src={MoleculeData?.bond_line}
            alt="ch4"
            fit="contain" 
            width="100%" 
            height="150px" 
            sx={{
              objectFit: "contain", 
            }}
          />
        )}
        {activeButton === MoleculeType.Structure3D && MoleculeData?.model_link &&(
          <Canvas>
          <OrbitControls  />
          <ambientLight intensity={1.5} />
          <directionalLight position={[10, 10, 10]} intensity={2} />
          <mesh>
            <GLTFModel model_link={`${modelUrlRoute}${MoleculeData.model_link}`} />
            <meshStandardMaterial roughness={0.1} metalness={0.5} />
          </mesh>
        </Canvas>
        )}

        
      </Box>

      {/* Footer with buttons */}
      <Stack
        spacing="xs"
        style={{
          position: "absolute",
          bottom: isMobile ? "5%" : "2%",
          display: "flex",
          flexDirection: "row",
          gap: "10px",
        }}
      >
        {displayedButtons.map((button, index) => (
          <MethaneSimulationButton
            key={index}
            imageSrc={button.imageSrc}
            label={button.label}
            isActive={
              activeButton === (button.label.toLowerCase() as MoleculeType)
            }
            onClick={() =>
              setActiveButton(button.label.toLowerCase() as MoleculeType)
            }
          />
        ))}
    
      </Stack>

      {activeButton === MoleculeType.Description && (
        <>
          <Overlay color="#000" opacity={0.85} />
          <Flex
            justify="center"
            align="center"
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              border: "2px solid white",
              borderRadius: "10px",
              backgroundColor: "white",
              padding: "5px",
              transform: "translateX(-50%) translateY(-50%)",
              zIndex: 1001,
            }}
          >

            {/* close button  */}
            <Box
              style={{
                cursor: "pointer",
                position: "absolute",
                top: "20px",
                right: "20px",
              }}
            >
              <Image
                style={{ cursor: "pointer" }}
                onClick={handleclosebutton}
                src={closeImg}
                width={10}
                height={10}
                alt="close"
              />
            </Box>

                {/* Description  */}
            <Box
              style={{
                border: "0.1875rem solid #BABABA", 
                padding: "1rem",
                maxWidth: "80vw", 
                maxHeight: "70vh",
                borderRadius:'1rem'
              }}
            >
              <Text
                style={{
                  fontFamily: "Gloria Hallelujah, sans-serif",
                  fontSize: '10px',
                  fontWeight: 400,
                  textAlign: "left",
                  textUnderlinePosition: "from-font",
                  textDecorationSkipInk: "none",
                  color: "black",
                }}
              >
                <strong>Description:</strong>
                <br />
                {MoleculeData?.description}
                <br />
                <br />
                <strong>Uses:</strong>
                <br />
                Chloroform is used as a solvent, in the production of
                refrigerants, and in laboratory applications.
              </Text>
            </Box>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default MethaneSimulationWrapper;
