import React, { useState } from "react";
import { Checkbox, Stack, Text } from "@mantine/core";
import { getLanguageEnumByKeyForPendulumModel } from "../../../assets/LanguageEnums/SimulationEnumFunction";
interface OptionProps {
  showFBD: boolean;
  showValues: boolean;
  setShowFBD: (showFBD: boolean) => void;
  setShowValues: (showValues: boolean) => void;
  userLanguage: string;
}
const Options: React.FC<OptionProps> = ({
  showFBD,
  showValues,
  setShowValues,
  setShowFBD,
  userLanguage,
}) => {
  return (
    <div
      style={{
        border: "1px solid #ccc",
        padding: "20px",
        borderRadius: "8px",
        width: "200px",
      }}
    >
      <Stack spacing="sm">
        <Text align="center" weight={500}>
          {getLanguageEnumByKeyForPendulumModel({
            key: "Options",
            LanguageId: userLanguage,
          })}
        </Text>

        {/* <Checkbox
          label="Air Resistance"
          checked={airResistance}
          onChange={(event) => setAirResistance(event.currentTarget.checked)}
        /> */}

        <Checkbox
          label={getLanguageEnumByKeyForPendulumModel({
            key: "Free_Body_Diagram",
            LanguageId: userLanguage,
          })}
          checked={showFBD}
          onChange={(event) => setShowFBD(event.currentTarget.checked)}
          styles={{
            input: { backgroundColor: showFBD ? "#00e0ff" : undefined },
          }}
        />

        <Checkbox
          label={getLanguageEnumByKeyForPendulumModel({
            key: "Values",
            LanguageId: userLanguage,
          })}
          checked={showValues}
          onChange={(event) => setShowValues(event.currentTarget.checked)}
          styles={{
            input: { backgroundColor: showValues ? "#00e0ff" : undefined },
          }}
        />
      </Stack>
    </div>
  );
};

export default Options;
