import {
  Badge,
  Button,
  Card,
  Divider,
  Grid,
  Group,
  SimpleGrid,
  Stack,
  Table,
  Text,
  Image,
  Flex,
  Box,
  Modal,
  NumberInput,
  Select,
  Container,
  LoadingOverlay,
} from "@mantine/core";
import React, { useState } from "react";
import { StudentFeesCards } from "../../components/AdminPage/DashBoard/DashBoardCards";
import { useMediaQuery } from "@mantine/hooks";
import { DatePicker } from "@mantine/dates";
import { IconCalendar } from "@tabler/icons";
import { GetStudentInfoById } from "../features/instituteStudentSlice";
import { downloadreceipt } from "../../components/FeeReceiptPdf/FeeReceiptPdf";
import useParentCommunication from "../../hooks/useParentCommunication";

const FeeRecordTable = (props: {
  dateOfJoining: Date;
  data: FeeRecord[];
  studentId: string;
  userType: string;
}) => {
  const batchTotalFees = props.data.reduce(
    (sum: number, record: FeeRecord) => sum + record.totalAmount,
    0
  );
  const batchTotalPaidFees = props.data.reduce(
    (sum: number, record: FeeRecord) => sum + record.amountPaid,
    0
  );
  const batchTotalPandingFees = batchTotalFees - batchTotalPaidFees;

  const [collapse, setCollapse] = useState<boolean>(false);
  const isMd = useMediaQuery(`(max-width: 968px)`);
  const [isLoading, setisLoading] = useState<boolean>(false);
  const { sendDataToReactnative, isReactNativeActive } =
    useParentCommunication();
  const [className, setClassName] = useState<string>("");
  const [selectedFeeRecord, setSelectedFeeRecord] = useState<FeeRecord | null>(
    null
  );

  function downloadFeeReceiptHandler(
    totalPricePaid: number,
    totalPrice: number,
    paidDate: string,
    className: string,
    paymentRecords: {
      receiptNumber: number;
      amount: number;
      paymentDate: Date;
    }[] 
   ) {
    setisLoading(true);
    GetStudentInfoById({ id: props.studentId })
      .then((student1: any) => {
        setisLoading(false);
        console.log(student1);
        downloadreceipt(
          isLoading,
          setisLoading,
          `C-${student1.instituteId.receiptCount}`,
          student1.name,
          new Date(paidDate),
          student1.parentName,
          totalPricePaid,
          paymentRecords,
          totalPrice - totalPricePaid,
          student1.instituteId._id,
          className,
          isReactNativeActive(),
          sendDataToReactnative
        );
      })
      .catch((e) => {
        setisLoading(false);
        console.log(e);
      });
  }

  const onClickDownloadButton = (
    name: string,
    totalPricePaid: number,
    totalPrice: number,
    paidDate: string,
    paymentRecords: {
      receiptNumber: number;
      amount: number;
      paymentDate: Date;
    }[],
  ) => {
    setClassName(name);
    downloadFeeReceiptHandler(totalPricePaid, totalPrice, paidDate, name,paymentRecords);
  };

  const renderRows = props.data.map((row, index) => (
    <tr
      style={{
        border: "2px solid #F8F8F8",
        backgroundColor: "#F8F8F8",
        borderRadius: "1rem",
      }}
      key={index}
    >
      <td>{index + 1}</td>
      <td>{`${new Date(row.dueDate).toLocaleDateString()}`}</td>
      <td>
        <Badge
          color={
            row.status == "not paid"
              ? "red"
              : row.status == "partial paid"
              ? "#93A3FA"
              : "green"
          }
        >
          {row.status}
        </Badge>
      </td>
      <td>{row.totalAmount}</td>
      <td>{row.totalAmount - row.amountPaid}</td>
      <td>
        {row.amountPaid > 0
          ? `${new Date(row.updatedAt).toLocaleDateString()}`
          : "N/A"}
      </td>
      {props.userType === "teacher" && (
        <>
          <td>
            {row.amountPaid > 0 ? (
              <Image
                onClick={() =>
                  onClickDownloadButton(
                    row?.batch?.name,
                    row.amountPaid,
                    row.totalAmount,
                    new Date(row.updatedAt).toISOString(),
                    row?.payments
                  )
                }
                src={require("../../assets/download.png")}
                alt="No"
                width={15}
              />
            ) : (
              ""
            )}
          </td>
          <td>
            {row.amountPaid > 0 ? (
              <Image
                onClick={() => setSelectedFeeRecord(row)}
                src={require("../../assets/options.png")}
                alt="No"
                width={15}
              />
            ) : (
              ""
            )}
          </td>
        </>
      )}
    </tr>
  ));

  return (
    <>
      <LoadingOverlay visible={isLoading} />
      <Stack mb={"1rem"}>
        <Flex w={"100%"} justify="space-between" align="center">
          <Stack w={"50%"}>
            <Text weight={500}>{props.data[0]?.batch?.name}</Text>
            <Text size="sm" color="dimmed">
              {`${new Date(props.dateOfJoining).toLocaleDateString()}`}
            </Text>
          </Stack>
          <Flex w={"50%"} justify="space-between" align="center">
            <Text size="sm" color="green">
              {batchTotalFees}
            </Text>
            <Text size="sm" color="red">
              {batchTotalPandingFees}
            </Text>
            {collapse ? (
              <Image
                onClick={() => setCollapse(false)}
                src={require("../../assets/collapse.png")}
                alt="No"
                width={10}
                sx={{ cursor: "pointer" }}
              />
            ) : (
              <Image
                onClick={() => setCollapse(true)}
                src={require("../../assets/arrow-down.png")}
                alt="No"
                width={25}
                sx={{ cursor: "pointer" }}
              />
            )}
          </Flex>
        </Flex>
        <Divider my="sm" />
        <Box
          w={"100%"}
          sx={{
            display: collapse ? "block" : "none",
            width: "100%",
            overflowX: "auto",
          }}
        >
          <Table>
            <thead>
              <tr style={{ border: "none" }}>
                <th>S No.</th>
                <th>Due Date</th>
                <th>Status</th>
                <th>Amount</th>
                <th>Due</th>
                <th>Paid On</th>
                {props.userType === "teacher" && <th>Download Receipt</th>}
                {props.userType === "teacher" && <th>More</th>}
              </tr>
            </thead>
            <tbody>{renderRows}</tbody>
          </Table>
        </Box>
      </Stack>
      <Modal
        opened={selectedFeeRecord != null}
        onClose={() => {
          setSelectedFeeRecord(null);
        }}
      >
        <Table>
          <thead>
            <tr>
              <th>S No.</th>
              <th>Payment Date</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {selectedFeeRecord &&
              selectedFeeRecord?.payments?.map((singlePaymentRecord, index) => {
                return (
                  <tr>
                    <td>Payment {index + 1}</td>
                    <td>
                      {
                        new Date(singlePaymentRecord.paymentDate.toString())
                          ?.toISOString()
                          .split("T")[0]
                      }
                    </td>
                    <td>{singlePaymentRecord.amount}</td>
                    {singlePaymentRecord.amount > 0 ? (
                      <Image
                        onClick={() =>
                          onClickDownloadButton(
                            selectedFeeRecord?.batch?.name,
                            singlePaymentRecord.amount,
                            singlePaymentRecord.amount,
                            new Date(
                              singlePaymentRecord.paymentDate.toString()
                            ).toISOString(),
                          [singlePaymentRecord]
                          )
                        }
                        src={require("../../assets/download.png")}
                        alt="No"
                        width={15}
                      />
                    ) : (
                      ""
                    )}
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Modal>
    </>
  );
};

export default FeeRecordTable;
