import React, { useEffect } from 'react';
import { Overlay, Box, Loader, Text } from '@mantine/core';
import { Html, useProgress } from '@react-three/drei';

 function LoaderComponent() {
  const { progress } = useProgress();
  useEffect(()=>{
    console.log(progress)
  },[progress])

  return (
    <Html center>
      <Overlay
        color="black"
        opacity={0.5}
        zIndex={1000}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.white,
            padding: theme.spacing.lg,
            borderRadius: theme.radius.md,
            textAlign: 'center',
            boxShadow: theme.shadows.md,
          })}
        >
          <Loader size="lg" color="teal" />
          <Text
            size="md"
            mt="md"
            sx={{
              fontWeight: 500,
              animation: 'fadeInOut 2s infinite',
            }}
          >
            Model Loading...
          </Text>
        </Box>
      </Overlay>
    </Html>
  );
}

export default LoaderComponent;
