enum ReactionEnglishLanguageEnums{
Th_Title="THEOREMS",
Preferences='Preferences',    
Overview="Overview",
Languages="Languages",
Preference_Content="Preferences allow you to change the language of this simulation. Select your preferred language to use the simulation accordingly.",
Description="Description",
Options="Options",
Close_navigation="Close navigation",
Open_navigation="Open navigation",
}

enum ReactionHindiLanguageEnums{
Th_Title="प्रमेयों",
Preferences='प्राथमिकता',
Overview="अवलोकन",
Languages="भाषाएं",
Preference_Content="प्राथमिकताएँ आपको इस सिमुलेशन की भाषा बदलने की अनुमति देती हैं। तदनुसार सिमुलेशन का उपयोग करने के लिए अपनी पसंदीदा भाषा चुनें।",
Description="विवरण",
Options="विकल्प",
Close_navigation="नेविगेशन बंद करें",
Open_navigation="नेविगेशन खोलें",
}

interface LanguageParams {
    key: string;
    LanguageId: string;
}


export const getLanguageEnumByKeyForReactionModel = ({ key, LanguageId }: LanguageParams): string => {
    if (LanguageId === 'hi') {
        return (ReactionHindiLanguageEnums as any)[key] || key;
    } else {
        return (ReactionEnglishLanguageEnums as any)[key] || key;
    }
};